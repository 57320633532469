import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import styles from './Header.module.scss';
import LogoSvg from '../../public/media/logo.svg';
import { CustomLogoStoreType, PreviewLogoType } from '../../store/clientCI';

type LogoProps = {
  clientPreviewId?: number | null,
  previewLogo?: PreviewLogoType | null,
  customLogo?: CustomLogoStoreType | null,
  currentClient?: Record<string, any>,
  visibleOnMobile?: boolean;
};

const Logo = ({
  clientPreviewId, previewLogo, customLogo, currentClient, visibleOnMobile,
}: LogoProps) => (
  <Link
    to='/'
    className={classNames(styles.zeemlessLogo, { [styles.visibleOnMobile]: visibleOnMobile })}
  >
    <div className={styles.header__logo}>
      <div className={styles.header__logo__icon}>
        {clientPreviewId && previewLogo?.logo.preview ? (
          <img
            alt=''
            src={previewLogo.logo.preview}
          />
        ) : customLogo?.logoUrl ? (
          <img
            alt=''
            src={customLogo.logoUrl}
          />
        ) : (
          <svg>
            <use
              xlinkHref={`${LogoSvg}#logoSVG`}
              href={`${LogoSvg}#logoSVG`}
            />
          </svg>
        )}
      </div>
      <p className={styles.header__logo__title}>{currentClient?.name?.toUpperCase() ?? 'ZEEMLESS'}</p>
    </div>
  </Link>
);

export default Logo;
