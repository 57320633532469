// @ts-nocheck
import React, { useState } from 'react';
import Header from '../../Header/Header.tsx';
import Button, { ButtonVariants } from '../../UIKit/Button/Button.tsx';
import CheckboxItem from '../../UIKit/CheckboxItem/CheckboxItem.tsx';
import ColorPalette from '../../UIKit/ColorPalette/ColorPalette.tsx';
import Drawer from '../../UIKit/Drawer/Drawer.tsx';
import Input from '../../UIKit/Input/Input.tsx';
import Modal from '../../UIKit/Modal/Modal.tsx';
import OptionsGroup from '../../UIKit/OptionsGroup/OptionsGroup.tsx';
import Popover, { PopoverPlacement } from '../../UIKit/Popover/Popover.tsx';
import PopoverOptions from '../../UIKit/PopoverOptions/PopoverOptions.tsx';
import RadioGroup from '../../UIKit/RadioGroup/RadioGroup.tsx';
import Select from '../../UIKit/Select/Select.tsx';
import Tabs from '../../UIKit/Tabs/Tabs.tsx';
import VoteGroup from '../../UIKit/VoteGroup/VoteGroup.tsx';
import styles from './UIKit.module.scss';
import DatePicker from '../../UIKit/DatePicker/DatePicker.tsx';
import TextEditor from '../../UIKit/TextEditor/TextEditor.tsx';
import Autocomplete from '../../UIKit/Autocomplete/Autocomplete.tsx';

const radioOptions = [
  {
    id: 0,
    caption: 'First value',
  },
  {
    id: 1,
    caption: 'Second value',
  },
];

const selectOptions = [
  { caption: 'English', value: '1' },
  { caption: 'German', value: '2' },
];

const multiSelectOptions = [
  { caption: 'Oliver Hansen', value: '1' },
  { caption: 'Van Henry', value: '2' },
  { caption: 'April Tucker', value: '3' },
  { caption: 'Ralph Hubbard', value: '4' },
  { caption: 'Omar Alexander', value: '5' },
];

const UIKit = () => {
  const [firstInputValue, setFirstInputValue] = useState('');
  const [secondInputValue, setSecondInputValue] = useState('');
  const [selectValue, setSelectValue] = useState('');
  const [multiSelectValues, setMultiSelectValues] = useState([]);

  const [checkboxValue, setCheckboxValue] = useState<boolean>(false);
  const [radioValue, setRadioValue] = useState(radioOptions[0].id);
  const [html, setHtml] = useState('');
  const [selectedAutocomplemete, setSelectedAutocomplete] = useState();

  const tabs = [
    {
      id: 0,
      button: {
        title: 'First tab',
        callback: () => {
          console.info('Custom callback for the First tab is working!');
        },
      },
      content: (<p>First section content</p>),
    },
    {
      id: 1,
      button: {
        title: 'Second tab',
      },
      content: (<p>Second section content</p>),
    },
    {
      id: 2,
      button: {
        title: 'Third tab',
      },
      content: (<p>Third section content</p>),
    },
  ];

  const testContextMenu = () => {
    console.info('Click');
  };

  const contextMenuOptions = [
    {
      id: 0,
      title: 'Edit participant',
      handler: testContextMenu,
    },
    {
      id: 1,
      title: 'Delete',
      handler: testContextMenu,
    },
  ];

  const [openedTab, setOpenedTab] = useState<number>(0);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  return (
    <>
      <Header />
      <main className={styles.main}>
        <div>
          <div className={styles.block}>
            <h1>Modals</h1>
            <Drawer
              isOpen={isDrawerOpen}
              setIsOpen={setIsDrawerOpen}
              trigger={<Button onClick={() => setIsDrawerOpen(true)}>Open Drawer</Button>}
              title='Demonstration title'
            >
              <div className={styles.drawerContent}><span>123</span></div>
            </Drawer>
            <div>
              <Button onClick={() => setIsModalOpen(true)}>Open Modal</Button>
              {isModalOpen && <Modal closeModal={() => setIsModalOpen(false)}>Modal content</Modal>}
            </div>
          </div>
          <div className={styles.block}>
            <h1>Tabs</h1>
            <Tabs
              value={openedTab}
              setValue={setOpenedTab}
              tabs={tabs}
            />
          </div>
          <div className={styles.block}>
            <h1>Context menu</h1>
            <div style={{ textAlign: 'right' }}><PopoverOptions options={contextMenuOptions} /></div>
          </div>
          <div className={styles.block}>
            <h1>Checkboxes and radio</h1>
            <VoteGroup />
            <OptionsGroup options={['Yes', 'No', 'N/A']} />
            <CheckboxItem
              label='Checkbox label'
              value={checkboxValue}
              onChange={e => setCheckboxValue(e.target.checked)}
            />
            <RadioGroup
              value={radioValue}
              setValue={setRadioValue}
              options={radioOptions}
              groupId='test-radio-group'
            />
          </div>
          <div className={styles.block}>
            <h1>Buttons</h1>
            <a
              href='/'
              className='link'
            >
              Link example
            </a>
            <Button>Primary Button</Button>
            <Button
              disabled
            >
              Disabled Primary Button
            </Button>
            <Button variant={ButtonVariants.SECONDARY}>Secondary Button</Button>
            <Button
              variant={ButtonVariants.SECONDARY}
              disabled
            >
              Disabled Secondary Button
            </Button>
          </div>
          <div className={styles.block}>
            <h1>Popover</h1>
            <Popover
              paperClassName={styles.popoverPaper}
              placement={PopoverPlacement.RIGHT}
              triggerButton={<Button>Right Popover</Button>}
            >
              Popover content
            </Popover>
            <Popover
              paperClassName={styles.popoverPaper}
              placement={PopoverPlacement.LEFT}
              triggerButton={<Button>Left Popover</Button>}
            >
              Popover content
            </Popover>
            <Popover
              paperClassName={styles.popoverPaper}
              placement={PopoverPlacement.TOP}
              triggerButton={<Button>Top Popover</Button>}
            >
              Popover content
            </Popover>
            <Popover
              paperClassName={styles.popoverPaper}
              placement={PopoverPlacement.BOTTOM}
              triggerButton={<Button>Bottom Popover</Button>}
            >
              Popover content
            </Popover>
          </div>
        </div>
        <div>
          <div className={styles.block}>
            <h1>Inputs</h1>
            <Input
              value={firstInputValue}
              setValue={(e) => setFirstInputValue(e.target.value)}
              label='Default input'
              id='First input'
              required
            />
            <Input
              value={secondInputValue}
              setValue={(e) => setSecondInputValue(e.target.value)}
              label='Error input'
              id='Second input'
              error
              required
            />
            <Input
              value={secondInputValue}
              setValue={(e) => setSecondInputValue(e.target.value)}
              label='Disabled input'
              id='Third input'
              disabled
            />
            <Select
              label='System language'
              options={selectOptions}
              value={selectValue}
              setValue={(e) => setSelectValue(e.target.value)}
              labelId='Select label'
              name='systemLanguage'
            />
            <Select
              label='Names'
              options={multiSelectOptions}
              value={multiSelectValues}
              setValue={(values) => setMultiSelectValues(values)}
              labelId='Names'
              name='names'
              multiple
            />
            <DatePicker
              label='Basic Picker'
              setValue={() => {}}
              value={null}
            />
            <DatePicker
              label='Basic Picker'
              error
              errorMessage='Some placeholder error'
              setValue={() => {}}
              value={null}
            />
            <Autocomplete
              label='Customer'
              ctaButton={<Button className={styles.button}>Add customer</Button>}
              selected={selectedAutocomplemete}
              onChange={setSelectedAutocomplete}
              options={[
                { icon: <div className={styles.autocompleteIcon}>S</div>, title: 'ID #10252', value: 'Sapient' },
                { icon: <div className={styles.autocompleteIcon}>Z</div>, title: 'ID #10253', value: 'ZEEMLESS' },
                { icon: <div className={styles.autocompleteIcon}>F</div>, title: 'ID #10254', value: 'Facebook' },
                { icon: <div className={styles.autocompleteIcon}>G</div>, title: 'ID #10255', value: 'Google' },
                { icon: <div className={styles.autocompleteIcon}>S</div>, title: 'ID #10256' },
              ]}
            />
          </div>
          <div className={styles.block}>
            <h1>WYSIWYG</h1>
            <TextEditor
              value={'<p>hello&nbsp;world</p>'}
              setValue={(value) => setHtml(value)}
            />
            <div style={{ marginTop: '15px' }}>
              {html}
            </div>
          </div>
          <div className={styles.block}>
            <h1>Typography</h1>
            <h1 className={styles.h1}>H1 title</h1>
            <h2 className={styles.h2}>H2 title</h2>
            <h3 className={styles.h3}>H3 title</h3>
            <h4 className={styles.h4}>H4 title</h4>
            <h5 className={styles.h5}>H5 title</h5>
            <p className={styles.subtitle}>Subtitle</p>
            <p className={styles.subtitleSmall}>Subtitle Small</p>
            <p className={styles.bodyLarge}>Body Large</p>
            <p className={styles.bodyMedium}>Body Medium</p>
            <p className={styles.bodySmall}>Body Small</p>
            <p className={styles.bodyExtraSmall}>Body Extra Small</p>
            <p className={styles.caption}>Caption</p>
          </div>
          <div className={styles.block}>
            <h1>Color palette</h1>
            <ColorPalette />
          </div>
        </div>
      </main>
    </>
  );
};

export default UIKit;
