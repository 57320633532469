export enum DeliverableStatus {
  DISABLED = 1,
  INTERNAL = 2,
  EXTERNAL = 3,
}

export type Task = {
  id: number,
  finish_date: string,
  task_at_risk: boolean,
  domain: Domain
};

export type DeliverableLink = {
  id: number,
  caption: string,
  link: string
};

export type Deliverable = {
  id: number,
  caption: string,
  description: string,
  links_count?: number,
  domain: {
    caption: string,
    icon: string,
    id: number,
  },
  scores: Array<{
    id: number,
    value: number,
    caption: string,
    icon: string,
  }>,
  status: {
    value: DeliverableStatus,
    caption: string,
  },
  task: Task,
  done: boolean,
  links?: DeliverableLink[],
  due_date: string,
  templates: Array<{
    id: number,
    link: string | null,
    filename: string
  }>,
  order?: number,
};

export type Domain = {
  id: number,
  caption: string,
  icon: string,
};

export type ScoreResponse = {
  id: number,
  caption: string,
  icon: string,
  values: {
    total: number,
    selected: number,
    scale: number,
  },
};

export type Scores = {
  [key: string]: {
    id: number,
    caption: string,
    icon: string,
    values: {
      total: number,
      selected: number,
      scale: number,
    },
  };
};
