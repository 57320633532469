import React, { Suspense } from 'react';
import Loader from '../../../../Loader/Loader.tsx';
import styles from './Schedule.module.scss';

const ScheduleGanttContent = React.lazy(() => import('./ScheduleGanttContent.tsx'));

const ScheduleGantt = () => (
  <Suspense fallback={<div className={styles.preloader}><Loader /></div>}>
    <ScheduleGanttContent />
  </Suspense>
);

export default ScheduleGantt;
