export type Questions = {
  id: number;
  caption: string,
  name?: string,
  category?: string,
  flags: number;
  answers: {
    id: number;
    caption: string,
    name: string | null,
    group_id: number | null,
  }[],
  depends: {
    question: number,
    answer: number
  }[],
  required?: number,
  max?: number,
};

export enum QuestionFlags {
  YES_NO = 4,
}
