import {
  FormControl, FormControlLabel, Radio, RadioGroup as MuiRadioGroup,
} from '@mui/material';
import { ReactComponent as RadioCheckedSVG } from '../../../public/media/radio-checked.svg';
import { ReactComponent as RadioEmptySVG } from '../../../public/media/radio-empty.svg';
import styles from './RadioGroup.module.scss';

type RadioGroupProps = {
  options: Array<
  {
    id: number,
    caption: string,
  }>,
  groupId: string,
  iconSize?: number,
  fontSize?: number,
  value: number | null,
  setValue: (value: string | number) => void,
  className?: string,
};
const RadioGroup = ({
  value, setValue, options, groupId, iconSize = 16, fontSize = 14, className,
}: RadioGroupProps) => (
  <FormControl>
    <MuiRadioGroup
      aria-labelledby={groupId}
      defaultValue={options[0]}
      name={groupId}
      value={value}
      onChange={e => {
        setValue(e.target.value);
      }}
      classes={{
        root: className,
      }}
    >
      {options.map(option => (
        <FormControlLabel
          key={option.caption}
          value={option.id}
          className={styles.radio}
          classes={{
            root: styles.item,
          }}
          sx={{
            fontSize,
          }}
          control={(
            <Radio
              icon={(
                <RadioEmptySVG
                  width={iconSize}
                  height={iconSize}
                  className={styles.icon}
                />
              )}
              checkedIcon={(
                <RadioCheckedSVG
                  width={iconSize}
                  height={iconSize}
                  className={styles.icon}
                />
              )}
            />
          )}
          label={option.caption}
        />
      ))}
    </MuiRadioGroup>
  </FormControl>
);

export default RadioGroup;
