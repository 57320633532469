import classNames from 'classnames';
import {
  NavLink, Outlet, useLoaderData, useNavigate, useParams,
} from 'react-router-dom';
import useSWR from 'swr';
import React, {
  useEffect, useState, startTransition, Suspense,
} from 'react';
import { useSetAtom } from 'jotai';
import AdminSidebar from '../../AdminSidebar/AdminSidebar';
import { ClientProps } from '../../AdminSidebar/client.props.ts';
import Header from '../../Header/Header';
import ClientsHeader from './ClientsHeader/ClientsHeader';
import apiClient from '../../../apiClient.ts';
import { clientPermissionsAtom } from '../../../store/auth.ts';

import { usePermissions } from '../../../usePermission.ts';
import { Permissions } from '../Login/user.props.ts';

import styles from './Clients.module.scss';
import MobileTabDropdown, { tabRoutes } from './MobileTabDropdown/MobileTabDropdown.tsx';
import { useCustomTranslation } from '../../../useAppTranslate.tsx';

const Clients = () => {
  const { clients } = useLoaderData() as { isGlobalAdmin: boolean, clients: ClientProps[] };
  const { id } = useParams();
  const { t } = useCustomTranslation('global');
  const navigate = useNavigate();

  const setClientPermissions = useSetAtom(clientPermissionsAtom);
  const { hasPermission, hasGlobalPermission } = usePermissions();

  const isGlobalAdmin = hasGlobalPermission(Permissions.ADMIN);
  const [activeClient, setActiveClient] = useState<ClientProps>();

  const { data } = useSWR([`clients/${id || clients[0].id}`, id], ([url]) => apiClient
    .get<{ data: ClientProps, permissions: string[] }>(url).then(({ response }) => response), {
    keepPreviousData: true,
    onSuccess: ({ data: client, permissions }) => {
      if (client) {
        setActiveClient(client);
        setClientPermissions(permissions);
        return client;
      }
    },
  });

  useEffect(() => {
    if (data && !id) {
      navigate(`${data?.data.id}/users`);
    }
  }, [data]);

  const hasAccess = (permission?: Permissions) => !permission || hasPermission(permission);

  const handleClick = (path: string) => {
    startTransition(() => {
      navigate(path);
    });
  };

  const renderTabElement = (route: string) => (
    <NavLink
      key={route}
      to={`${id}/${route}`}
      className={({ isActive }) => classNames(styles.tab, {
        [styles.active]: isActive,
      })}
      onClick={(e) => {
        e.preventDefault();
        handleClick(`${id}/${route}`);
      }}
    >
      <svg className={styles.tab__icon}>
        <use
          xlinkHref={`${tabRoutes[route].icon.path}#${tabRoutes[route].icon.id}`}
          href={`${tabRoutes[route].icon.path}#${tabRoutes[route].icon.id}`}
        />
      </svg>
      {t(tabRoutes[route].name)}
    </NavLink>
  );

  const getAvailableRoutes = () => Object.keys(tabRoutes).filter(route => hasAccess(tabRoutes[route].permission));

  const renderTabs = () => getAvailableRoutes().map(route => renderTabElement(route));

  return (
    <div className={classNames(styles.page, {
      [styles.page_withSidebar]: isGlobalAdmin,
    })}
    >
      {isGlobalAdmin && (
      <AdminSidebar clients={clients} />
      )}
      <main className={styles.wrapper}>
        <Header
          showLogo={!isGlobalAdmin}
          showAdminSidebar={isGlobalAdmin}
        />
        <div className={styles.inner}>
          <ClientsHeader
            isGlobalAdmin={isGlobalAdmin}
            activeClient={activeClient}
          />
          <div className={styles.block}>
            <div className={styles.tabs}>
              {renderTabs()}
            </div>
            <MobileTabDropdown
              routes={getAvailableRoutes()}
              clientId={id}
            />
            <div className={styles.content}>
              <Suspense>
                <Outlet />
              </Suspense>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Clients;
