import React from 'react';
import { Link } from 'react-router-dom';
import { TFunction } from 'i18next';
import Popover, { PopoverPlacement } from '../../UIKit/Popover/Popover';
import AvatarPlaceholderSVG from '../../../public/media/avatar-placeholder.svg';
import { ReactComponent as SettingsSVG } from '../../../public/media/settings.svg';
import { UserProps } from '../../pages/Login/user.props.ts';
import UserSVG from '../../../public/media/user.svg';
import styles from './UserDropdown.module.scss';

const UserDropdown = ({ user, t }: { user: UserProps['user'], t: TFunction<'translation', undefined> }) => (
  <Popover
    className={styles.userBlock}
    paperClassName={styles.dropdown}
    placement={PopoverPlacement.CONTEXT_MENU}
    triggerButton={(
      <svg className={styles.userButton}>
        <use
          xlinkHref={`${UserSVG}#userSVG`}
          href={`${UserSVG}#userSVG`}
        />
      </svg>
    )}
  >
    <div className={styles.dropdown__user}>
      <svg className={styles.userPlaceholder}>
        <use
          xlinkHref={`${AvatarPlaceholderSVG}#avatarPlaceholderSVG`}
          href={`${AvatarPlaceholderSVG}#avatarPlaceholderSVG`}
        />
      </svg>
      <div>
        {user?.name && (<h5>{user?.name}</h5>)}
        <p>{user?.email}</p>
      </div>
    </div>
    <Link
      to='/d/account-settings'
    >
      <div className={styles.dropdown__item}>
        <SettingsSVG />
        <p>{t('Account settings')}</p>
      </div>
    </Link>
  </Popover>
);

export default UserDropdown;
