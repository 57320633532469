import { atomWithStorage } from 'jotai/utils';
import { atom } from 'jotai';
import { ILatestRelease, UserResource } from '../components/pages/Users/UsersList/types.ts';

enum AuthStorageKey {
  USER_DATA = 'user-data',
  USER_EMAIL = 'user-email',
  GLOBAL_PERMISSIONS = 'global-permissions',
  CLIENT_PERMISSIONS = 'client-permissions',
  USER_CLIENT = 'user-client',
  LATEST_RELEASE = 'latest-release',
  RELEASE_BANNER = 'release-banner',
}

const userAtom = atomWithStorage<{ user: UserResource, token: string } | null>(AuthStorageKey.USER_DATA, null);
const userEmailAtom = atomWithStorage<string | null>(AuthStorageKey.USER_EMAIL, null);
const latestReleaseAtom = atomWithStorage<ILatestRelease | null>(AuthStorageKey.LATEST_RELEASE, null);
const globalPermissionsAtom = atomWithStorage<string[]>(AuthStorageKey.GLOBAL_PERMISSIONS, []);
const clientPermissionsAtom = atomWithStorage<string[]>(AuthStorageKey.CLIENT_PERMISSIONS, []);
const userClientAtom = atomWithStorage<Record<string, any>>(AuthStorageKey.USER_CLIENT, {});
const releaseBannerAtom = atomWithStorage(AuthStorageKey.RELEASE_BANNER, { isBannerShown: true, isBannerRead: false });
const twoFARecoveryCode = atom<string[]>([]);

export {
  userAtom,
  userEmailAtom,
  globalPermissionsAtom,
  clientPermissionsAtom, AuthStorageKey,
  userClientAtom,
  twoFARecoveryCode,
  latestReleaseAtom,
  releaseBannerAtom,
};
