import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import { getProjectDetails } from './new-project.loader.ts';

export const projectSummaryLoader = async ({ params }: LoaderFunctionArgs<Record<string, string>>) => {
  let project = null;

  if (params.clientId && params.projectId) {
    project = await getProjectDetails(params.clientId, params.projectId);
  }

  if (project && project.step !== 3) {
    return redirect(`/d/client/${params.clientId}/project/${params.projectId}`);
  } else if (!project) {
    return redirect('/not-found');
  } else {
    return { project };
  }
};
