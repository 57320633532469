import { GridFilterModel } from '@mui/x-data-grid';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

const TABLE_FILTERS_OPTIONS = 'table-filters-options';

const tableFiltersOptions = atomWithStorage<Record<string, any>>(TABLE_FILTERS_OPTIONS, {});
const tablesSortConfig = atomWithStorage<Record<string, any>>('table-sort-config', {});
const filterPositionAnchorEl = atom<Element | null>(null);
const filters = atom<GridFilterModel | null>(null);

export {
  tableFiltersOptions, tablesSortConfig, TABLE_FILTERS_OPTIONS, filterPositionAnchorEl, filters,
};
