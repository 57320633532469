import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';
import { ParticipantResource } from '../components/pages/QualityGate/types';
import { PollingSettings, ProjectEditAccess } from '../components/pages/Projects/types';

const projectIdAtom = atom<number | null>(null);
const projectEditAccess = atom<ProjectEditAccess>({
  isViewMode: false,
  isEditingAvailable: false,
  isEditingAvailableShowOnUI: false,
  isEditingUnavailableModalVisible: false,
  userThatCurrentlyEditing: {
    userId: null,
    name: null,
    email: null,
  },
  requestedAt: null,
  projectId: null,
});
const pollingSettingsAtom = atom<PollingSettings>({ callback: null, isTriggered: false });
const projectGateParticipantsAtom = atomWithReset<Array<ParticipantResource> | null>(null);
const isVotingEditModeActive = atomWithReset<boolean>(false);

export {
  projectIdAtom, projectEditAccess, pollingSettingsAtom, projectGateParticipantsAtom, isVotingEditModeActive,
};
