import tinycolor from 'tinycolor2';

type RgbProps = {
  r: number,
  g: number,
  b: number,
  a: number,
};

const multiply = (color1: RgbProps, color2: RgbProps) => {
  color1.b = Math.floor((color1.b * color2.b) / 255);
  color1.g = Math.floor((color1.g * color2.g) / 255);
  color1.r = Math.floor((color1.r * color2.r) / 255);
  return tinycolor(`rgb ${color1.r} ${color1.g} ${color1.b}`);
};

export const generatePalette = (base: string, prefix: string) => {
  const baseLight = tinycolor('#ffffff');
  const baseDark = multiply(tinycolor(base).toRgb(), tinycolor(base).toRgb());

  return {
    [`--${prefix}-50`]: tinycolor.mix(baseLight, base, 12).toHexString(),
    [`--${prefix}-100`]: tinycolor.mix(baseLight, base, 30).toHexString(),
    [`--${prefix}-200`]: tinycolor.mix(baseLight, base, 50).toHexString(),
    [`--${prefix}-300`]: tinycolor.mix(baseLight, base, 70).toHexString(),
    [`--${prefix}-400`]: tinycolor.mix(baseLight, base, 85).toHexString(),
    [`--${prefix}-500`]: tinycolor.mix(baseLight, base, 100).toHexString(),
    [`--${prefix}-600`]: tinycolor.mix(baseDark, base, 87).toHexString(),
    [`--${prefix}-700`]: tinycolor.mix(baseDark, base, 70).toHexString(),
    [`--${prefix}-800`]: tinycolor.mix(baseDark, base, 54).toHexString(),
    [`--${prefix}-900`]: tinycolor.mix(baseDark, base, 25).toHexString(),
  };
};
