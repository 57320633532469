import React from 'react';
import Modal from '../../../../UIKit/Modal/Modal.tsx';
import styles from './DeleteDeliverableModal.module.scss';
import DeleteIllustrationSVG from '../../../../../public/media/delete-illustration.svg';
import Button, { ButtonVariants } from '../../../../UIKit/Button/Button.tsx';
import { useCustomTranslation } from '../../../../../useAppTranslate.tsx';

type DeleteDeliverableModalProps = {
  closeModal: () => void,
  deleteDeliverable: () => void,
};

const DeleteDeliverableModal = ({ closeModal, deleteDeliverable }: DeleteDeliverableModalProps) => {
  const { t } = useCustomTranslation();

  return (
    <Modal
      closeModal={closeModal}
      rootClassName={styles.modal}
    >
      <div className={styles.modal__content}>
        <svg className={styles.modal__illustration}>
          <use
            xlinkHref={`${DeleteIllustrationSVG}#deleteIllustrationSVG`}
            href={`${DeleteIllustrationSVG}#deleteIllustrationSVG`}
          />
        </svg>
        <div>
          <h4>
            {t('Are you sure you want to remove the deliverable from this quality gate?')}
          </h4>
        </div>
        <div className={styles.modal__buttons}>
          <Button
            type='button'
            onClick={closeModal}
            variant={ButtonVariants.SECONDARY}
          >
            {t('Cancel')}
          </Button>
          <Button
            type='button'
            onClick={deleteDeliverable}
          >
            {t('Yes, delete')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteDeliverableModal;
