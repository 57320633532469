import * as Yup from 'yup';
import { useFormik } from 'formik';

import Input from '../../../UIKit/Input/Input';
import Button from '../../../UIKit/Button/Button';

import { ParticipantResource } from '../types';
import { useCustomTranslation } from '../../../../useAppTranslate';

import styles from './Voting.module.scss';

type ParticipantFormProps = {
  submitForm: (values: Record<string, any>) => void,
  participant: ParticipantResource | null,
  submitButtonText: string,
};

const ParticipantForm = ({ submitForm, participant, submitButtonText }: ParticipantFormProps) => {
  const { t } = useCustomTranslation();

  const {
    handleSubmit, values, handleChange, handleBlur, touched, errors, isSubmitting,
  } = useFormik({
    initialValues: {
      role: participant?.role ?? '',
      name: participant?.name ?? '',
      deputy: participant?.deputy ?? '',
    },
    validationSchema: Yup.object({
      role: Yup.string().trim().required(t('Role is required')).max(100, t('Must be 100 characters at most')),
      name: Yup.string().trim().required(t('Name is required')).max(100, t('Must be 100 characters at most')),
      deputy: Yup.string().trim().required(t('Deputy is required')).max(100, t('Must be 100 characters at most')),
    }),
    onSubmit: submitForm,
  });
  return (
    <form
      className={styles.participantForm}
      onSubmit={handleSubmit}
    >
      <Input
        value={values.role}
        setValue={handleChange}
        onBlur={handleBlur}
        id='role'
        label={t('Role')}
        name='role'
        error={!!(touched.role && errors.role)}
        errorMessage={errors?.role}
      />
      <Input
        value={values.name}
        setValue={handleChange}
        onBlur={handleBlur}
        id='name'
        label={t('Name of participant')}
        name='name'
        error={!!(touched.name && errors.name)}
        errorMessage={errors.name}
      />
      <Input
        value={values.deputy}
        setValue={handleChange}
        onBlur={handleBlur}
        id='deputy'
        label={t('Deputy of participant')}
        name='deputy'
        error={!!(touched.deputy && errors.deputy)}
        errorMessage={errors.deputy}
      />
      <div className={styles.participantForm__footer}>
        <Button
          className={styles.participantForm__submitBtn}
          type='submit'
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          {submitButtonText}
        </Button>
      </div>
    </form>
  );
};

export default ParticipantForm;
