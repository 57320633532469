import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './SubHeader.module.scss';
import Button, { ButtonIconPosition, ButtonVariants } from '../UIKit/Button/Button';
import { ReactComponent as AngleDownSVG } from '../../public/media/angle-down.svg';

const SubHeader = ({
  title, fallbackLink, children, className,
}: { title: string, fallbackLink: string, children?: React.ReactNode, className?: string }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className={classNames(styles.subHeader, className)}>
      <div className={styles.subHeader__base}>
        <Button
          type='button'
          variant={ButtonVariants.SECONDARY}
          className={styles.subHeader__back}
          onClick={(e) => {
            if (location.key !== 'default' || !fallbackLink) {
              e.preventDefault();
              navigate(-1);
            } else {
              navigate(fallbackLink);
            }
          }}
          icon={(
            <AngleDownSVG />
          )}
          iconSize={{ width: 20, height: 20 }}
          iconPosition={ButtonIconPosition.CENTER}
        />
        <h2 className={styles.subHeader__title}>{title}</h2>
      </div>
      {children}
    </div>
  );
};

export default SubHeader;
