import { atom } from 'jotai';
import { DeliverableLink } from '../pages/NewProject/NewProjectDeliverables/types';

export const editLinkAtom = atom<{
  link: DeliverableLink | null, task: null | {
    id: number,
    title: string,
  }
}>({
  link: null,
  task: null,
});
