import { FormikValues, useFormik } from 'formik';
import * as Yup from 'yup';
import useSWR from 'swr';
import { useState } from 'react';
import { TFunction } from 'i18next';
import Autocomplete from '../../UIKit/Autocomplete/Autocomplete';
import { ClientMemberResource, RoleResource } from '../Users/UsersList/types';
import apiClient from '../../../apiClient';
import { useDebounce } from '../../../useDebounce';
import styles from './ProjectTeamManagment.module.scss';

import Select from '../../UIKit/Select/Select';
import Button from '../../UIKit/Button/Button';

export type AddTeamMemberFormType = {
  user: ClientMemberResource | null,
  manageRole: string | null,
};

const formInitialValues: AddTeamMemberFormType = {
  user: null,
  manageRole: null,
};

type AddTeamMemberFormProps = {
  onSubmit: (values: AddTeamMemberFormType) => void;
  t: TFunction<string, undefined>;
  roles: RoleResource[];
  projectId: string;
  i18nLanguage: string;
};

const AddTeamMemberForm = ({
  onSubmit, t, roles, projectId, i18nLanguage,
}: AddTeamMemberFormProps) => {
  const {
    handleSubmit,
    values,
    handleChange,
    setFieldValue,
    isSubmitting,
    handleBlur,
    touched,
    errors,
  } = useFormik<FormikValues>({
    initialValues: formInitialValues,
    validationSchema: Yup.object({
      user: Yup.object<ClientMemberResource>().required(t('User is required')),
      manageRole: Yup.string().required(t('Role is required')),
    }),
    onSubmit: (formikValues) => onSubmit(formikValues as AddTeamMemberFormType),
  });
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce<string>(search, 300);

  const {
    data: users,
  } = useSWR([`project/${projectId}/available-members`, debouncedSearch, i18nLanguage], async ([url, searchValue]) => {
    const params = searchValue ? new URLSearchParams({ search: searchValue }).toString() : '';

    const { response } = await apiClient
      .get<{ data: ClientMemberResource[] }>(url + (params ? `?${params}` : ''));

    return response.data;
  }, { keepPreviousData: true });

  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit}
    >
      <div
        className={styles.form__section}

      >
        <Autocomplete
          label={t('Select user')}
          selected={values.user}
          classNames={{
            input: styles.form__autocomplete,
            label: styles.form__autocomplete__label,
            label_active: styles.form__autocomplete__label_active,
          }}
          onChange={(user) => setFieldValue('user', user)}
          options={users?.length ? users.map(({ name, id }) => (
            {
              icon: <div className={styles.form__autocomplete__icon}>{name[0]}</div>, id, value: name, title: `ID #${id}`,
            }
          )) : []}
          customSearch={setSearch}
          error={!!(touched.user && errors.user)}
          errorMessage={typeof errors.user === 'string' ? errors.user : undefined}
        />
        <Select
          label={t('Select user role')}
          options={roles?.map(role => ({ caption: role.caption, value: role.id.toString() })) ?? []}
          value={values.manageRole}
          setValue={handleChange}
          onBlur={handleBlur}
          labelId='manageRole'
          name='manageRole'
          className={styles.form__select}
          error={!!(touched.manageRole && errors.manageRole)}
          errorMessage={typeof errors.manageRole === 'string' ? errors.manageRole : undefined}
        />
      </div>
      <footer className={styles.form__footer}>
        <Button
          type='submit'
          className={styles.form__footer__button}
          loading={isSubmitting}
        >
          {t('Add new user')}
        </Button>
      </footer>
    </form>
  );
};

export default AddTeamMemberForm;
