import { useTranslation, Trans, TransProps } from 'react-i18next';

export const useCustomTranslation = (scope?: string) => {
  const pageScope = window.location.pathname.replace(/\/[dm]\/|\/\d+/g, '');
  const i18n = useTranslation(scope || pageScope);
  return i18n;
};

export const CustomTrans = (props: TransProps<string>) => {
  const pageScope = window.location.pathname.replace(/\/[dm]\/|\/\d+/g, '');

  return (
    <Trans
      ns={pageScope}
      {...props}
    />
  );
};
