import classNames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { useAtomValue } from 'jotai/index';
import React, { useEffect, useRef, useState } from 'react';

import { Tooltip } from '@mui/material';
import { DEFAULT_DATE_FORMAT } from '../../../../../../constants.ts';

import DeleteSVG from '../../../../../../public/media/delete-icon.svg';
import EditSVG from '../../../../../../public/media/edit-icon.svg';
import { ReactComponent as InfoSVG } from '../../../../../../public/media/info.svg';
import { userAtom } from '../../../../../../store/auth.ts';
import DatePicker from '../../../../../UIKit/DatePicker/DatePicker.tsx';
import { PopoverPlacement } from '../../../../../UIKit/Popover/Popover';
import PopoverOptions from '../../../../../UIKit/PopoverOptions/PopoverOptions';
import Switch from '../../../../../UIKit/Switch/Switch';
import { IProjectRisk, ProjectRisksActionType } from '../types';
import styles from './ProjectRisksTable.module.scss';
import { useCustomTranslation } from '../../../../../../useAppTranslate.tsx';

enum FieldErrors {
  OWNER,
  DUE_DATE,
}

export type RiskItem = {
  id: IProjectRisk['id'],
  owner: string,
  due_date: Dayjs | string,
};

interface IProjectRisksTableRowProps {
  index: number
  t: (key: string) => string;
  risk: IProjectRisk;
  isEditMode?: boolean;
  onRiskActionHandler?: (action: ProjectRisksActionType, risk: IProjectRisk) => void;
  risksForSave: RiskItem[];
  saveRisk?: (risk: RiskItem) => void;
  removeRiskFromSaved?: (riskId: number) => void;
}

const ProjectRisksTableRow = ({
  index, t, risk, isEditMode, onRiskActionHandler, risksForSave, saveRisk, removeRiskFromSaved,
}: IProjectRisksTableRowProps) => {
  const nameTextRef = useRef<HTMLParagraphElement>(null);
  const explanationTextRef = useRef<HTMLParagraphElement>(null);
  const mitigationContainerRef = useRef<HTMLParagraphElement>(null);
  const ownerContainerRef = useRef<HTMLParagraphElement>(null);
  const dueDateContainerRef = useRef<HTMLParagraphElement>(null);

  const [isOverflowed, setIsOverflowed] = useState<{
    explanation: boolean;
    mitigation: boolean;
    name: boolean;
    owner: boolean;
    dueDate: boolean;
  }>({
    explanation: false,
    mitigation: false,
    name: false,
    owner: false,
    dueDate: false,
  });

  const checkOverflow = () => {
    const nameContainer = nameTextRef.current;
    const explanationContainer = explanationTextRef.current;
    const mitigationContainer = mitigationContainerRef.current;
    const ownerContainer = ownerContainerRef.current;
    const dueDateContainer = dueDateContainerRef.current;

    setIsOverflowed({
      explanation: !!explanationContainer && explanationContainer.scrollWidth > explanationContainer.clientWidth,
      mitigation: !!mitigationContainer && mitigationContainer.scrollWidth > mitigationContainer.clientWidth,
      name: !!nameContainer && nameContainer.scrollWidth > nameContainer.clientWidth,
      owner: !!ownerContainer && ownerContainer.scrollWidth > ownerContainer.clientWidth,
      dueDate: !!dueDateContainer && dueDateContainer.scrollWidth > dueDateContainer.clientWidth,
    });
  };

  useEffect(() => {
    checkOverflow();
  }, [risk]);

  useEffect(() => {
    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, []);

  const [ownerName, setOwnerName] = useState('');
  const userData = useAtomValue(userAtom);
  const [dueDate, setDueDate] = useState<null | Dayjs>(null);
  const [fieldErrors, setFieldErrors] = useState<FieldErrors[]>([]);
  const [isDateErrorEnabled, setIsDateErrorEnabled] = useState(false);

  const isDateValid = dueDate && dayjs(dueDate).isValid() && dayjs().startOf('day').isBefore(dayjs(dueDate));

  useEffect(() => {
    if (isDateErrorEnabled && !isDateValid && !fieldErrors.includes(FieldErrors.DUE_DATE)) {
      removeRiskFromSaved?.(risk.id);
      setFieldErrors(prev => [...prev, FieldErrors.DUE_DATE]);
    }

    isDateValid && fieldErrors.includes(FieldErrors.DUE_DATE)
    && setFieldErrors(prev => prev.filter(error => error !== FieldErrors.DUE_DATE));
  }, [dueDate]);

  useEffect(() => {
    ownerName.length > 0 && setFieldErrors(prev => prev.filter(error => error !== FieldErrors.OWNER));
    ownerName.length === 0 && risksForSave.find(riskItem => riskItem.id === risk.id) && removeRiskFromSaved?.(risk.id);
  }, [ownerName]);

  return (
    <div className={styles.row}>
      <p className={styles.item}>{index}</p>
      <div className={styles.item}>
        <p
          className={styles.text}
          ref={nameTextRef}
        >
          {risk.caption}
        </p>

        {isOverflowed.name && (
          <Tooltip
            arrow
            title={(
              <div className={styles.tooltipContent}>
                <p className={styles.tooltipContent__title}>{t('Risk Name')}</p>
                <p className={styles.tooltipContent__text}>{risk.caption}</p>
              </div>
            )}
            placement='bottom-start'
          >
            <span className={styles.hoverElement}>...</span>
          </Tooltip>
        )}
      </div>
      <div className={styles.item}>
        {isEditMode && (
          <div className={styles.item__mobileTitle}>
            <p className={styles.item}>{t('Risk Explanation')}</p>
          </div>
        )}
        <p
          className={styles.text}
          ref={explanationTextRef}
        >
          {risk.explanation || '-'}
        </p>
        {isOverflowed.explanation && (
          <Tooltip
            arrow
            title={(
              <div className={styles.tooltipContent}>
                <p className={styles.tooltipContent__title}>{t('Risk Explanation')}</p>
                <p className={styles.tooltipContent__text}>{risk.explanation}</p>
              </div>
            )}
            placement='bottom-start'
          >
            <span className={styles.hoverElement}>...</span>
          </Tooltip>
        )}
      </div>
      <div className={styles.item}>
        {isEditMode && (
          <div className={styles.item__mobileTitle}>
            <p className={styles.item}>{t('Risk Mitigation')}</p>
          </div>
        )}
        <p
          className={styles.text}
          ref={mitigationContainerRef}
        >
          {risk.mitigation || '-'}
        </p>
        {isOverflowed.mitigation && (
          <Tooltip
            arrow
            title={(
              <div className={styles.tooltipContent}>
                <p className={styles.tooltipContent__title}>{t('Risk Mitigation')}</p>
                <p className={styles.tooltipContent__text}>{risk.mitigation}</p>
              </div>
            )}
            placement='bottom-end'
          >
            <span className={styles.hoverElement}>...</span>
          </Tooltip>
        )}
      </div>
      {isEditMode ? (
        <div className={classNames(styles.item_input, styles.item_input__owner)}>
          <div className={styles.item__mobileTitle}>
            <p className={styles.item}>{t('Owner')}</p>
          </div>
          <input
            id={`owner-${risk.id}`}
            value={ownerName}
            onChange={e => setOwnerName(e.target.value)}
            type='text'
            placeholder={t('Add owner name')}
            className={classNames(styles.input, {
              [styles.error]: fieldErrors.includes(FieldErrors.OWNER),
            })}
          />
        </div>
      ) : (
        <div className={styles.item}>
          <p
            className={styles.text}
            ref={ownerContainerRef}
          >
            {risk.owner}
          </p>
          {isOverflowed.owner && (
            <Tooltip
              arrow
              title={(
                <div className={styles.tooltipContent}>
                  <p className={styles.tooltipContent__title}>{t('Risk Owner')}</p>
                  <p className={styles.tooltipContent__text}>{risk.owner}</p>
                </div>
              )}
              placement='bottom-end'
            >
              <span className={styles.hoverElement}>...</span>
            </Tooltip>
          )}
        </div>

      )}
      {isEditMode ? (
        <div className={classNames(styles.item_input, styles.item_input__dueDate)}>
          <div className={styles.item__mobileTitle}>
            <p className={styles.item}>{t('Due date')}</p>
          </div>
          <DatePicker
            className={classNames(styles.datepicker, {
              [styles.error]: fieldErrors.includes(FieldErrors.DUE_DATE),
            })}
            label={t('Add due date')}
            format={userData?.user.dateFormat ?? DEFAULT_DATE_FORMAT}
            value={dueDate}
            setValue={date => setDueDate(date)}
            error={fieldErrors.includes(FieldErrors.DUE_DATE)}
            disablePast
          />
        </div>
      ) : (
        <div className={styles.item}>
          <p
            className={styles.text}
            ref={dueDateContainerRef}
          >
            {dayjs(risk.due_date).format(userData?.user?.dateFormat || 'DD.MM.YYYY')}
          </p>
        </div>

      )}
      {isEditMode ? (
        <div className={styles.item}>
          <div className={styles.switchWrapper}>
            <div className={styles.saveMobileHint}>
              <Tooltip
                arrow
                title={(
                  <div className={styles.tooltipContent}>
                    <p className={styles.tooltipContent__title}>{t('Save risk')}</p>
                    <p className={styles.tooltipContent__text}>{t('Save AI content to project risk list')}</p>
                  </div>
                )}
                placement='bottom-start'
              >
                <InfoSVG />
              </Tooltip>
              {t('Save')}
            </div>
            <label htmlFor={`risk-${risk.id}`}>{t('No')}</label>
            <Switch
              id={`risk-${risk.id}`}
              name={`risk-${risk.id}`}
              checked={!!risksForSave.find(riskItem => riskItem.id === risk.id)}
              onChange={() => {
                !isDateErrorEnabled && setIsDateErrorEnabled(true); // trigger touched effect
                ownerName.length > 0 && isDateValid && saveRisk?.({
                  id: risk.id,
                  owner: ownerName,
                  due_date: dueDate,
                });
                !!risksForSave.find(riskItem => riskItem.id === risk.id) && removeRiskFromSaved?.(risk.id); // remove risk from saved
                ownerName.length === 0 && !fieldErrors.includes(FieldErrors.OWNER) && setFieldErrors(prev => [...prev, FieldErrors.OWNER]);
                !dueDate && !fieldErrors.includes(FieldErrors.DUE_DATE) && setFieldErrors(prev => [...prev, FieldErrors.DUE_DATE]);
              }}
            />
            <label htmlFor={`risk-${risk.id}`}>{t('Yes')}</label>
          </div>
        </div>
      ) : (
        <div className={styles.item}>
          <PopoverOptions
            options={[
              {
                id: 0,
                title: (
                  <div className={styles.option}>
                    <svg>
                      <use
                        xlinkHref={`${EditSVG}#editSVG`}
                        href={`${EditSVG}#editSVG`}
                      />
                    </svg>
                    <p>{t('Edit risk')}</p>
                  </div>
                ),
                handler: () => onRiskActionHandler?.(ProjectRisksActionType.EDIT, risk),
              },
              {
                id: 1,
                title: (
                  <div className={classNames(styles.option, styles.option_delete)}>
                    <svg>
                      <use
                        xlinkHref={`${DeleteSVG}#deleteSVG`}
                        href={`${DeleteSVG}#deleteSVG`}
                      />
                    </svg>
                    <p>{t('Delete risk')}</p>
                  </div>
                ),
                handler: () => onRiskActionHandler?.(ProjectRisksActionType.DELETE, risk),
              },
            ]}
            paperClassName={styles.navigation__mobile__paper}
            placement={PopoverPlacement.CONTEXT_MENU}
          />
        </div>
      )}
    </div>
  );
};

interface IProjectRisksTableProps {
  isEditMode: boolean;
  risks: IProjectRisk[];
  onRiskActionHandler?: (action: ProjectRisksActionType, risk: IProjectRisk) => void;
  risksForSave?: RiskItem[];
  saveRisk?: (risk: RiskItem) => void;
  removeRiskFromSaved?: (riskId: number) => void;
}

const ProjectRisksTable = ({
  risks,
  isEditMode,
  onRiskActionHandler,
  risksForSave = [],
  saveRisk,
  removeRiskFromSaved,
}: IProjectRisksTableProps) => {
  const { t } = useCustomTranslation();

  return (
    <div className={styles.projectRisksTableWrapper}>
      <div className={classNames(styles.projectRisksTable, {
        [styles.projectRisksTable_editMode]: isEditMode,
      })}
      >
        <header className={classNames(styles.projectRisksTable__header, styles.row)}>
          <p className={styles.item}>{t('ID')}</p>
          <p className={styles.item}>{t('Risk Item')}</p>
          <p className={styles.item}>{t('Explanation')}</p>
          <p className={styles.item}>{t('Mitigation actions')}</p>
          <p className={styles.item}>{t('Owner')}</p>
          <p className={styles.item}>
            {isEditMode && (
              <Tooltip
                arrow
                title={(
                  <div className={styles.tooltipContent}>
                    <p className={styles.tooltipContent__text}>{t('Due date should be greater than today')}</p>
                  </div>
                )}
                placement='bottom-end'
              >
                <InfoSVG />
              </Tooltip>
            )}
            {t('Due date')}
          </p>
          {isEditMode && (
            <div className={styles.item}>
              <Tooltip
                arrow
                title={(
                  <div className={styles.tooltipContent}>
                    <p className={styles.tooltipContent__title}>{t('Save risk')}</p>
                    <p className={styles.tooltipContent__text}>{t('Save AI content to project risk list')}</p>
                  </div>
                )}
                placement='bottom-start'
              >
                <InfoSVG />
              </Tooltip>
              {t('Save')}
            </div>
          )}
        </header>
        <div className={styles.projectRisksTable__body}>
          {risks.map((risk, index) => (
            <ProjectRisksTableRow
              index={index + 1}
              risksForSave={risksForSave}
              saveRisk={saveRisk}
              removeRiskFromSaved={removeRiskFromSaved}
              onRiskActionHandler={onRiskActionHandler}
              key={risk.id}
              risk={risk}
              isEditMode={isEditMode}
              t={t}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectRisksTable;
