import React, { Suspense, lazy } from 'react';
import classNames from 'classnames';
import { PieValueType } from '@mui/x-charts';

import Loader from '../../../Loader/Loader';
import { VotingChartProps } from './VotingChart';

import { ParticipantVote } from '../types';
import { useCustomTranslation } from '../../../../useAppTranslate';

import styles from './Voting.module.scss';

const VotingChart = lazy(() => import('./VotingChart'));

const getVotePercent = (values: PieValueType[], voteType: ParticipantVote) => {
  const total = values.reduce((acc, option) => acc + option.value, 0);
  const vote = values.find(option => option.id === voteType);
  return vote && total ? `${Math.round((vote.value / total) * 100)}%` : '0%';
};

const VotingResults = ({ value }: VotingChartProps) => {
  const { t } = useCustomTranslation();
  return (
    <section className={styles.results}>
      <h5 className={styles.results__title}>{t('Voting results')}</h5>
      <div className={styles.results__body}>
        <div className={styles.results__chart}>
          <Suspense fallback={<Loader className={styles.loader} />}>
            <VotingChart {...{ value }} />
          </Suspense>
        </div>
        <div className={styles.results__legend}>
          <div className={classNames(styles.vote, styles.vote_passed)}>
            <p className={styles.vote__label}>{t('Passed')}</p>
            <p className={styles.vote__percent}>{getVotePercent(value, ParticipantVote.PASS)}</p>
          </div>
          <div className={classNames(styles.vote, styles.vote_constraint)}>
            <p className={styles.vote__label}>{t('Passed with constraints')}</p>
            <p className={styles.vote__percent}>{getVotePercent(value, ParticipantVote.PASS_WITH_CONSTRAINTS)}</p>
          </div>
          <div className={classNames(styles.vote, styles.vote_notVoted)}>
            <p className={styles.vote__label}>{t('Not voted yet')}</p>
            <p className={styles.vote__percent}>{getVotePercent(value, ParticipantVote.NOT_VOTED)}</p>
          </div>
          <div className={classNames(styles.vote, styles.vote_fail)}>
            <p className={styles.vote__label}>{t('Fail')}</p>
            <p className={styles.vote__percent}>{getVotePercent(value, ParticipantVote.FAIL)}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VotingResults;
