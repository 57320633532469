import React from 'react';

import { useMediaQuery } from '@mui/material';
import styles from './ErrorPage.module.scss';
import OopsSVG from '../../../public/media/oops.svg';
import Button from '../../UIKit/Button/Button.tsx';
import { MOBILE_MEDIA_QUERY } from '../../../constants.ts';
import { useCustomTranslation } from '../../../useAppTranslate.tsx';

const ErrorPage = () => {
  const { t } = useCustomTranslation('global');
  const isMobileDevice = useMediaQuery(MOBILE_MEDIA_QUERY);

  return (
    <div className={styles.wrapper}>
      <div className={styles.error}>
        <svg>
          <use
            width={isMobileDevice ? '315px' : undefined}
            xlinkHref={`${OopsSVG}#oopsSVG`}
            href={`${OopsSVG}#oopsSVG`}
          />
        </svg>
        <h1>{t('Ooops! Something went wrong...')}</h1>
        <p>{t('Please reload the page')}</p>
        <Button link='/'>{t('Ok, got it')}</Button>
      </div>
    </div>
  );
};

export default ErrorPage;
