import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import styles from './Autocomplete.module.scss';
import SearchSVG from '../../../public/media/search.svg';
import CloseSVG from '../../../public/media/close.svg';
import AngleDownSVG from '../../../public/media/angle-down.svg';
import CheckedSVG from '../../../public/media/checked.svg';

import { useOutsideClick } from '../../../useOutsideClick.ts';
import { useCustomTranslation } from '../../../useAppTranslate.tsx';

type Item = {
  icon?: React.ReactNode,
  title?: string,
  id: number | null,
  value: string,
  inactive?: boolean
};

type AutocompleteProps = {
  label: string,
  selected?: Item | null,
  onChange: (item: Item | null) => void,
  customSearch?: (value: string) => void,
  ctaButton?: React.ReactNode | null,
  options: Item[],
  error?: boolean,
  errorMessage?: string,
  classNames?: {
    input?: string,
    label?: string
    label_active?: string
  },
  disabled?: boolean
};

const Autocomplete = ({
  label, selected, ctaButton, options, onChange, customSearch, error, errorMessage, classNames, disabled,
}: AutocompleteProps) => {
  const { t } = useCustomTranslation('global');

  const [isActive, setIsActive] = useState(false);
  const [items, setItems] = useState<Item[]>();
  const [searchValue, setSearchValue] = useState('');

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => setIsActive(false));

  useEffect(() => {
    if (!options) return;

    setItems(options);
  }, [options]);

  const onClick = (item: Item) => {
    onChange(item);
    setSearchValue('');
    setIsActive(false);
  };

  const reset = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (disabled) return;
    setSearchValue('');
    onChange(null);
  };

  const search = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);

    if (customSearch) {
      customSearch(value);
    } else {
      const filtered = value ? options?.filter(item => item?.value?.toLowerCase().indexOf(value.toLowerCase()) > -1) : options;
      setItems(filtered);
    }
  };

  return (
    <div>
      <div
        className={classnames(styles.autocomplete, {
          [styles.active]: isActive,
          [styles.selected]: selected,
          [styles.error]: error,
          'field-error': error,
          [styles.disabled]: disabled,
        })}
        ref={wrapperRef}
      >
        <button
          type='button'
          onClick={() => !disabled && setIsActive(prev => !prev)}
        >
          <div className={classnames(styles.input, classNames?.input)}>
            <span className={classnames(styles.label, classNames?.label, {
              [styles.activeLabel]: isActive || selected?.value,
              [classNames?.label_active || '']: isActive || selected?.value,
            })}
            >
              {label}
            </span>
            {selected && (
              <>
                <div className={classnames(styles.item, {
                  [styles.selected]: selected,
                })}
                >
                  {selected?.icon}
                  <div className={styles.itemText}>
                    {selected.title && <p className={styles.title}>{selected.title}</p>}
                    <p>{selected.value}</p>
                  </div>
                </div>
                <svg
                  className={styles.closeIcon}
                  onClick={reset}
                >
                  <use
                    xlinkHref={`${CloseSVG}#closeSVG`}
                    href={`${CloseSVG}#closeSVG`}
                  />
                </svg>
              </>
            )}
            <svg className={styles.angleIcon}>
              <use
                xlinkHref={`${AngleDownSVG}#angleDownSVG`}
                href={`${AngleDownSVG}#angleDownSVG`}
              />
            </svg>
          </div>
        </button>
        {isActive && (
          <div className={styles.options}>
            <div className={styles.options__search}>
              <svg>
                <use
                  xlinkHref={`${SearchSVG}#searchSVG`}
                  href={`${SearchSVG}#searchSVG`}
                />
              </svg>
              <input
                type='text'
                placeholder={t('Search')}
                onChange={search}
                value={searchValue}
              />
              {ctaButton}
            </div>
            <div className={styles.options__items}>
              {items?.length ? items.map((item) => (
                <button
                  type='button'
                  onClick={() => onClick(item)}
                  key={item.value + item.title}
                >
                  <div className={classnames(styles.item, {
                    [styles.inactive]: item.inactive,
                  })}
                  >
                    {item?.icon}
                    <div>
                      {item.title && <p className={styles.title}>{item.title}</p>}
                      <p>{item.value}</p>
                    </div>
                    {selected?.value === item.value && (
                      <svg>
                        <use
                          xlinkHref={`${CheckedSVG}#checkedSVG`}
                          href={`${CheckedSVG}#checkedSVG`}
                        />
                      </svg>
                    )}
                  </div>
                </button>
              )) : null}
            </div>
          </div>
        ) }
      </div>
      {(errorMessage && error) && <p className={styles.error}>{errorMessage}</p>}
    </div>
  );
};

export default Autocomplete;
