import { AuthStorageKey } from '../store/auth.ts';

export const getAuthorizeToken = (): string | null => {
  try {
    const data = localStorage.getItem(AuthStorageKey.USER_DATA);
    const user = data && JSON.parse(data);
    return user?.token ?? null;
  } catch (e) {
    console.error(e);
    return null;
  }
};
