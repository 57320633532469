import React from 'react';
import { Link } from 'react-router-dom';
import BellSVG from '../../public/media/bell.svg';
import styles from './NotificationCounter.module.scss';
import { useCustomTranslation } from '../../useAppTranslate';

const NotificationCounter = ({
  redirectPath = '/d/releases',
  ariaLabel, count,
}: { redirectPath?: string, ariaLabel: string, count: number }) => {
  const { t } = useCustomTranslation('global');
  return (
    <Link
      to={redirectPath}
      aria-label={t(ariaLabel)}
      data-notification-count={count > 0 ? count : 0}
      className={styles.notificationCounter}
    >
      <svg className={styles.bellSvg}>
        <use
          xlinkHref={`${BellSVG}#bellSVG`}
          href={`${BellSVG}#bellSVG`}
        />
      </svg>
    </Link>
  );
};

export default NotificationCounter;
