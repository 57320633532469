import React, {
  useEffect, useId, useRef, useState,
} from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useAtomValue } from 'jotai';
import { useMediaQuery } from '@mui/material';
import styles from './GlobalSearch.module.scss';
import SearchSVG from '../../../public/media/search.svg';
import CloseSVG from '../../../public/media/close.svg';
import Loader from '../../Loader/Loader.tsx';
import apiClient from '../../../apiClient.ts';
import { useDebounce } from '../../../useDebounce.ts';
import { useOutsideClick } from '../../../useOutsideClick.ts';
import { stringToColor } from '../../../utils';
import { StatusState } from '../../UIKit/StatusLabel/types.ts';
import type {
  ItemType, Project, ProjectTypes, SearchResult, UserTypes,
} from './search';
import { userClientAtom } from '../../../store/auth.ts';
import { Permissions } from '../../pages/Login/user.props.ts';
import { usePermissions } from '../../../usePermission.ts';
import { MOBILE_MEDIA_QUERY } from '../../../constants.ts';
import { useCustomTranslation } from '../../../useAppTranslate.tsx';

enum SearchResultKeys {
  CUSTOMERS = 'customers',
  PROJECTS = 'projects',
  TEMPLATES = 'templates',
  CLIENTS = 'clients',
  USERS = 'users',
}

const GlobalSearch = () => {
  const minSearchLength = 2;
  const { t } = useCustomTranslation('global');
  const userClient = useAtomValue(userClientAtom);

  const [value, setValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [searchResult, setSearchResult] = useState<SearchResult | null>(null);

  const debouncedValue = useDebounce<string>(value, 300);
  const { hasGlobalPermission, hasClientPermission } = usePermissions();
  const isMobileDevice = useMediaQuery(MOBILE_MEDIA_QUERY);

  const globalSearchId = useId();

  useEffect(() => {
    if (debouncedValue.length < minSearchLength) return;
    const params = new URLSearchParams({ search: debouncedValue }).toString();

    apiClient.get<{ data: SearchResult }>(`search?${params}`)
      .then(({ response }) => {
        if ((response.data as any)?.length === 0) {
          setSearchResult(null);
        } else {
          setSearchResult(response.data);
        }
      })
      .catch(e => console.error(e))
      .finally(() => setIsLoading(false));
  }, [debouncedValue]);

  const onSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsLoading(true);
    setValue(e.target.value);
  };

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => setValue(''));

  const getInitials = (name: string) => {
    const split = name.split(' ');
    return `${split[0]?.[0]}${split[1]?.[0] ?? ''}`;
  };

  const getRoute = (item: ItemType, groupName: string) => {
    const clientId = ('client' in item) && item.client ? item.client.id : userClient.id;

    switch (groupName) {
      case SearchResultKeys.PROJECTS: {
        const suffix = (item as Project).status.state === StatusState.ACTIVE ? '/summary' : '';
        return `/d/client/${clientId}/project/${item.id}${suffix}`;
      }
      case SearchResultKeys.TEMPLATES: {
        return `/m/client/${clientId}/templates?active=${item.id}`;
      }
      case SearchResultKeys.CUSTOMERS: {
        return `/m/client/${clientId}/customers?active=${item.id}`;
      }
      case SearchResultKeys.USERS: {
        return `/m/client/${clientId}/users?active=${item.id}`;
      }
      case SearchResultKeys.CLIENTS: {
        return `/m/client/${item.id}/users`;
      }
      default: return '';
    }
  };

  const renderProjects = (items: ProjectTypes, groupName: string) => {
    if (!items || !items?.length) return null;

    return items.map(item => (
      <Link
        key={item.id}
        to={getRoute(item, groupName)}
        onClick={() => setValue('')}
      >
        <div className={styles.item}>
          <div className={styles.item__leftContent}>
            <div
              className={styles.item__projectIcon}
              style={{ backgroundColor: `${stringToColor(item.caption)}25` }}
            >
              {getInitials(item.caption)}
            </div>
            <div className={styles.item__info}>
              <p>{item.caption}</p>
              {item?.client && <span>{item?.client?.name}</span>}
            </div>
          </div>
        </div>
      </Link>
    ));
  };

  const renderUsers = (items: UserTypes, groupName: string) => {
    if (!items || !items?.length) return null;

    return items.map(item => (
      <Link
        key={item.id}
        to={getRoute(item, groupName)}
        onClick={() => setValue('')}
      >
        <div className={styles.item}>
          <div className={styles.item__leftContent}>
            <div className={styles.item__userIcon}>
              {getInitials(item.name)}
            </div>
            <div className={styles.item__info}>
              <p>{item.name}</p>
              {('client' in item && item?.client) && <span>{item?.client?.name}</span> }
            </div>
          </div>
          {('email' in item && item.email) && <span className={styles.item__email}>{item.email}</span>}
        </div>
      </Link>
    ));
  };

  const getPlaceholder = () => {
    if (isMobileDevice) {
      return t('Searching');
    }

    if (hasGlobalPermission(Permissions.ADMIN)) {
      return t('Search projects, clients, customers, project templates or users');
    }

    if (hasClientPermission(Permissions.ADMIN)) {
      return t('Search projects, customers or users');
    }

    return t('Search projects');
  };

  return (
    <div
      className={styles.search}
      ref={wrapperRef}
    >
      <label
        htmlFor={globalSearchId}
        className={styles.search_container}
      >
        <svg className={styles.search__searchIcon}>
          <use
            xlinkHref={`${SearchSVG}#searchSVG`}
            href={`${SearchSVG}#searchSVG`}
          />
        </svg>
        <input
          value={value}
          type='text'
          id={globalSearchId}
          onChange={onSearch}
          className={classNames(styles.search__input, {
            [styles.search__inputActive]: value.length >= 3,
          })}
          placeholder={getPlaceholder()}
        />
        {value.length >= minSearchLength ? (
          <button
            type='button'
            onClick={() => setValue('')}
            className={styles.search__closeButton}
          >
            <svg>
              <use
                xlinkHref={`${CloseSVG}#closeSVG`}
                href={`${CloseSVG}#closeSVG`}
              />
            </svg>
          </button>
        ) : null}
        {value.length >= minSearchLength ? (
          <div className={styles.result}>
            {isLoading ? (
              <div className={styles.result__loader}>
                <Loader size={16} />
              </div>
            ) : !searchResult ? (
              <div className={styles.notFound}>
                <h6>{t('Nothing found')}</h6>
                <p>{t('Check the spelling or try a new search')}</p>
              </div>
            ) : (
              (Object.keys(searchResult) as Array<SearchResultKeys>).map(title => {
                const items = searchResult[title];
                const isUserBlock = [SearchResultKeys.USERS, SearchResultKeys.CUSTOMERS, SearchResultKeys.CLIENTS].includes(title);
                const translatedTitle = t(title.charAt(0).toUpperCase() + title.slice(1));

                return (
                  <div
                    key={title}
                    className={styles.result__group}
                  >
                    <p className={styles.result__groupTitle}>{translatedTitle}</p>
                    {isUserBlock ? renderUsers(items as UserTypes, title) : renderProjects(items as ProjectTypes, title)}
                  </div>
                );
              })
            )}
          </div>
        ) : null}
      </label>

    </div>
  );
};

export default GlobalSearch;
