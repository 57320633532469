import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { useOutsideClick } from '../../../useOutsideClick';
import styles from './SimpleSelect.module.scss';
import AngleDownSVG from '../../../public/media/angle-down.svg';

export interface OptionWithElement {
  element: React.ReactNode;
  id?: string | number | undefined;
}

interface SimpleSelectProps<T extends OptionWithElement> {
  selected?: T,
  options: T[],
  onSelect: (selected: T) => void,
  placeholder: React.ReactNode,
  className?: string,
  error?: boolean,
  disabled?: boolean
}

const SimpleSelect = <T extends OptionWithElement>({
  selected, options, onSelect, placeholder, className, error = false, disabled,
}: SimpleSelectProps<T>) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const wrapperRef = useRef(null);

  useOutsideClick(wrapperRef, () => setIsOpened(false));

  return (
    <div
      className={classNames(styles.wrapper, className, {
        [styles.opened]: isOpened,
        [styles.error]: error,
        [styles.disabled]: disabled,
      })}
      ref={wrapperRef}
    >
      <div className={styles.selector}>
        <button
          type='button'
          onClick={() => setIsOpened(!isOpened)}
          disabled={disabled}
        >
          <div className={classNames(styles.selector__item, styles.selector__selected)}>
            {selected ? selected.element : placeholder}
            <svg className={styles.selector__icon}>
              <use
                xlinkHref={`${AngleDownSVG}#angleDownSVG`}
                href={`${AngleDownSVG}#angleDownSVG`}
              />
            </svg>
          </div>
        </button>
        {isOpened
          && options.filter(option => option?.id !== selected?.id)?.map((option) => (
            <button
              type='button'
              onClick={() => {
                onSelect(option);
                setIsOpened(false);
              }}
              key={option?.id}
            >
              <div className={classNames(styles.selector__item, styles.selector__option)}>
                {option.element}
              </div>
            </button>
          ))}
      </div>
    </div>
  );
};

export default SimpleSelect;
