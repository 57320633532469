import { Switch as MuiSwitch, SwitchProps } from '@mui/material';

import styles from './Switch.module.scss';

const Switch = (props: SwitchProps) => (
  <MuiSwitch
    className={styles.toggleSwitch}
    disableRipple
    {...props}
  />
);

export default Switch;
