import { atom } from 'jotai';

export enum FilterInternal {
  ALL = 'all',
  INTERNAL = 'internal',
  EXTERNAL = 'external',
  UNCHECKED = 'unchecked',
}

type InternalFilter = {
  projectId: number | null,
  type: FilterInternal,
};

export const internalFilter = atom<InternalFilter>({
  projectId: null,
  type: FilterInternal.ALL,
});

export type Categories = {
  [key: string]: {
    id: number,
    caption: string,
    checked: boolean
  }
};

type CategoriesFilter = {
  quantity: number | null,
  projectId: number | null,
  categories: Categories | null,
};

export const categoriesFilter = atom<CategoriesFilter>({
  quantity: null,
  projectId: null,
  categories: null,
});
