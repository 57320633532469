import { GridFilterInputValueProps } from '@mui/x-data-grid';
import { useAtom } from 'jotai';

import Input from '../../Input/Input';

import { filters } from '../../../../store/table';
import { useCustomTranslation } from '../../../../useAppTranslate';

import styles from '../Table.module.scss';

function NumberRangeFilter(props: GridFilterInputValueProps) {
  const { t } = useCustomTranslation('global');
  const { item, applyValue } = props;
  const [currentFilters, setCurrentFilters] = useAtom(filters);

  const savedItem = currentFilters?.items?.find((scope) => scope?.field === props?.item.field);

  return (
    <div className={styles.filter__body}>
      <p className={styles.filter__title}>
        {t('Range')}
        {' '}
        {item.field}
      </p>
      <div className={styles.filter__inputGroup}>
        <Input
          label='From'
          id={`filterNumberRangeFilterFrom-${item.id}`}
          value={savedItem?.value?.[0] ?? ''}
          setValue={e => {
            applyValue({ ...item, value: [e.target.value || null, savedItem?.value?.[1] || null] });
          }}
          className={styles.filter__input}
          type='number'
        />
        <Input
          label='To'
          id={`filterNumberRangeFilterTo-${item.id}`}
          value={savedItem?.value?.[1] ?? ''}
          setValue={e => applyValue({ ...item, value: [savedItem?.value?.[0] || null, e.target.value || null] })}
          className={styles.filter__input}
          type='number'
        />
      </div>
      <button
        type='button'
        className={styles.filter__clearBtn}
        onClick={() => {
          const filtered = currentFilters?.items?.filter((scope) => scope?.field !== item.field) || [];
          setCurrentFilters({
            ...currentFilters,
            items: filtered,
          });
          applyValue({ ...item, value: null });
        }}
      >
        {t('Clear filter')}
      </button>
    </div>
  );
}

export default NumberRangeFilter;
