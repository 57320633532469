import { Params, redirect } from 'react-router-dom';
import { getAuthorizeToken } from '../utils';
import { clientCiLoader } from './clientCiLoader.ts';
import { getClients } from './main.loader.ts';

export async function dashboardLoader(params?: Params) {
  const authorizeToken = getAuthorizeToken();

  if (!authorizeToken) {
    return redirect('/login');
  }

  clientCiLoader(params);

  return null;
}

export async function dashboardIndexLoader() {
  const clients = await getClients();

  if (!clients.length) return redirect('/not-found?logout=true');
  const client = clients[0];

  return redirect(`client/${client.id}/projects`);
}
