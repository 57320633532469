import { Tooltip } from '@mui/material';

import styles from './Table.module.scss';

const ClippedCell = ({ value }: { value?: string }) => (
  <Tooltip
    title={value?.length && value?.length > 35 ? value : null}
  >
    <p className={styles.clippedCell}>
      {value}
    </p>
  </Tooltip>
);

export default ClippedCell;
