import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import { getProjectDetails } from './new-project.loader.ts';

export const newProjectDeliverablesLoader = async ({ params }: LoaderFunctionArgs<Record<string, string>>) => {
  let project = null;

  if (params.clientId && params.projectId) {
    project = await getProjectDetails(params.clientId, params.projectId);
  }

  if (project && project.step < 2) {
    return redirect(`/d/client/${params.clientId}/project/${params.projectId}`);
  }
  return { project };
};
