import React, { useState } from 'react';
import dayjs from 'dayjs';
import useSWR from 'swr';
import { FormikValues, useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import Button from '../../UIKit/Button/Button';
import LangSelector from '../../LangSelector/LangSelector';
import Input from '../../UIKit/Input/Input';
import Loader from '../../Loader/Loader';
import Logo from '../../Header/Logo';
import RadioGroup from '../../UIKit/RadioGroup/RadioGroup';
import { DueDateWrapper, QualityGateHeader } from '../QualityGate/QualityGate';
import {
  GateResource, LocalizedResource, ParticipantSelfVotingResource, ParticipantVote,
} from '../QualityGate/types';

import apiClient from '../../../apiClient';
import { notify } from '../../../store/notifications';
import { DEFAULT_DATE_FORMAT } from '../../../constants';

import QuestionnaireSVG from '../../../public/media/questionnaire.svg';

import styles from './SelfVoting.module.scss';
import { useCustomTranslation } from '../../../useAppTranslate';

type SelfVotingGateResource = Omit<GateResource, 'executed_date' | 'deliverables_count'> & {
  project_id: number;
  websocket_url: string;
  deliverables: any[];
};

type FirstStepProps = {
  onSubmit: (values: Record<string, string>) => void;
  gate: SelfVotingGateResource;
  participant: ParticipantSelfVotingResource | null;
};

const FirstStep = ({ onSubmit, gate, participant }: FirstStepProps) => {
  const { t } = useCustomTranslation();

  const {
    handleSubmit, values, handleChange, handleBlur, touched, errors,
  } = useFormik({
    initialValues: {
      role: participant?.role ?? '',
      name: participant?.name ?? '',
      deputy: participant?.deputy ?? '',
    },
    validationSchema: Yup.object({
      role: Yup.string().trim().required(t('Role is required')).max(100, t('Must be 100 characters at most')),
      name: Yup.string().trim().required(t('Name is required')).max(100, t('Must be 100 characters at most')),
      deputy: Yup.string().trim().required(t('Deputy is required')).max(100, t('Must be 100 characters at most')),
    }),
    onSubmit,
  });

  return (
    <form
      onSubmit={handleSubmit}
      className={styles.step_first}
    >
      <div className={styles.heading}>
        <p>{gate.caption}</p>
        <DueDateWrapper>{dayjs(gate.due_date).format(DEFAULT_DATE_FORMAT)}</DueDateWrapper>
      </div>
      <div className={styles.form__inputs}>
        <Input
          value={values.role}
          setValue={(e) => handleChange(e as React.ChangeEvent<HTMLInputElement>)}
          onBlur={handleBlur}
          id='role'
          label={t('Role')}
          name='role'
          error={!!(touched.role && errors.role)}
          errorMessage={errors.role}
        />
        <Input
          value={values.name}
          setValue={(e) => handleChange(e as React.ChangeEvent<HTMLInputElement>)}
          onBlur={handleBlur}
          id='name'
          label={t('Name of participant')}
          name='name'
          error={!!(touched.name && errors.name)}
          errorMessage={errors.name}
        />
        <Input
          value={values.deputy}
          setValue={(e) => handleChange(e as React.ChangeEvent<HTMLInputElement>)}
          onBlur={handleBlur}
          id='deputy'
          label={t('Deputy of the participant')}
          name='deputy'
          error={!!(touched.deputy && errors.deputy)}
          errorMessage={errors.deputy}
        />
      </div>
      <footer className={styles.nextBtn}>
        <Button type='submit'>{t('Next')}</Button>
      </footer>
    </form>
  );
};

const fieldsLabels: Record<string, string> = {
  role: 'Role',
  name: 'Name of participant',
  deputy: 'Deputy of the participant',
};

const SecondStep = ({
  onSubmit,
  participant,
}: {
  onSubmit: (values: FormikValues) => Promise<void>;
  participant: ParticipantSelfVotingResource;
}) => {
  const { t } = useCustomTranslation();
  const voteOptions = [
    { id: ParticipantVote.PASS, caption: t('Pass') },
    { id: ParticipantVote.PASS_WITH_CONSTRAINTS, caption: t('Pass with constrains') },
    { id: ParticipantVote.FAIL, caption: t('Fail') },
  ];

  const {
    handleSubmit, values, handleChange, handleBlur, touched, errors, setFieldValue,
  } = useFormik({
    initialValues: {
      vote: null,
      explanation: '',
    },
    validationSchema: Yup.object({
      vote: Yup.number()
        .oneOf(
          voteOptions.map(({ id }) => id),
          t('Select a voting option'),
        )
        .required(t('Select a voting option')),
      explanation: Yup.string().when('vote', {
        is: (val: number) => [ParticipantVote.FAIL, ParticipantVote.PASS_WITH_CONSTRAINTS].includes(val),
        then: (schema) => schema.required(t('Explanation is required')),
        otherwise: (schema) => schema.notRequired(),
      }),
    }),
    onSubmit,
  });

  const selectedVoteLabel = voteOptions.find(({ id }) => id === Number(values.vote))?.caption.toLowerCase();

  return (
    <form
      onSubmit={handleSubmit}
      className={styles.step_second}
    >
      <div className={styles.participant}>
        {Object.entries(participant).map(([key]) => (key in fieldsLabels ? (
          <div
            className={styles.infoGroup}
            key={key}
          >
            <p className={styles.infoGroup__title}>{t(fieldsLabels[key])}</p>
            <p className={styles.infoGroup__value}>{(participant as Record<string, any>)[key]}</p>
          </div>
        ) : null))}
        <hr />
        <div>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label
            htmlFor='vote-options'
            className={styles.label}
          >
            {t('Select a Voting option')}
          </label>
          <div className={styles.radioGroup}>
            <RadioGroup
              value={values.vote}
              setValue={(value) => setFieldValue('vote', value)}
              options={voteOptions}
              groupId='vote-options'
              className={styles.form__block_radio}
              iconSize={20}
            />
          </div>
          {touched.vote && errors.vote && <div className={styles.form__error}>{errors.vote}</div>}
        </div>
        <div>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label
            htmlFor='explanation'
            className={styles.label}
          >
            {t('Add some explenations')}
          </label>
          <Input
            id='explanation'
            name='explanation'
            type='textarea'
            value={values.explanation}
            setValue={handleChange}
            onBlur={handleBlur}
            error={!!(touched.explanation && errors.explanation)}
            errorMessage={errors.explanation}
            className={styles.form__textarea}
            placeholder={selectedVoteLabel && t(`Explain why do you think it ${selectedVoteLabel}`)}
            multiline
          />
        </div>
      </div>
      <footer>
        <Button type='submit'>{t('End voting')}</Button>
      </footer>
    </form>
  );
};

const postVote = async (values: Record<string, string>, projectId: number, gateId: number) => {
  try {
    return await apiClient.post<{ data: ParticipantSelfVotingResource }>(
      `projects/${projectId}/quality-gates/${gateId}/self-create-participant`,
      {
        body: JSON.stringify(values),
      },
    );
  } catch (e) {
    console.error(e);
  }
};

const putVote = async (values: Record<string, string>, projectId: number, gateId: number, participantId: number) => {
  try {
    return await apiClient.put<{ data: ParticipantSelfVotingResource }>(
      `projects/${projectId}/quality-gates/${gateId}/participant/${participantId}/self-update-participant`,
      {
        body: JSON.stringify({ explanation: '', ...values, id: participantId }),
      },
    );
  } catch (e) {
    console.error(e);
  }
};

const SelfVoting = () => {
  const [activeStep, setActiveStep] = useState<1 | 2 | 3>(1);
  const { i18n, t } = useCustomTranslation();
  const { id: gateHashId } = useParams();
  const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);
  const [participant, setParticipant] = useState<any>(null);

  const { data: qualityGate, isLoading } = useSWR(
    [`quality-gates/self-voting?qualityGate=${gateHashId} `, i18n.language],
    async ([url]) => (gateHashId
      ? apiClient
        .withoutAuth()
        .get<{ data: SelfVotingGateResource; localized: LocalizedResource }>(url)
        .then(({ response }) => response.data)
      : null),
    {
      keepPreviousData: false,
      revalidateOnFocus: false,
      revalidateOnMount: true,
    },
  );

  const submitFirstStepForm = async (values: Record<string, string>) => {
    try {
      if (qualityGate) {
        const response = typeof participant?.id === 'number'
          ? await putVote(values, qualityGate.project_id, qualityGate.id, participant.id)
          : await postVote(values, qualityGate.project_id, qualityGate.id);

        if (response && (response.statusCode === 201 || response.statusCode === 200)) {
          setActiveStep(2);
          setParticipant(response?.response.data);
        }
      }
    } catch (error) {
      console.error(error);
      notify();
    }
  };

  const submitSecondStepForm = async (values: Record<string, string>) => {
    if (participant.id && qualityGate) {
      try {
        const response = await putVote(values, qualityGate.project_id, qualityGate.id, participant.id);
        if (response?.statusCode === 200) {
          setIsSuccessMessageVisible(true);
        }
      } catch (error) {
        console.error(error);
        notify();
      }
    } else {
      setActiveStep(1);
    }
  };

  if (isSuccessMessageVisible) {
    return (
      <div className={styles.successMessage}>
        <div className={styles.successMessage__content}>
          <svg>
            <use
              xlinkHref={`${QuestionnaireSVG}#questionnaireSVG`}
              href={`${QuestionnaireSVG}#questionnaireSVG`}
            />
          </svg>
          <div>
            <h2 className={styles.successMessage__title}>{t('Great!')}</h2>
            <p className={styles.successMessage__subtitle}>{t('Your vote has been successfully sent.')}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.selfVoting}>
      <header className={styles.header}>
        <Logo visibleOnMobile />
        <LangSelector />
      </header>
      {isLoading || !qualityGate ? (
        <div className={styles.loader}>
          <Loader />
        </div>
      ) : (
        <section className={styles.body}>
          <div className={styles.body__header}>
            {activeStep === 1 ? (
              <header>
                <h3>{t('Voting')}</h3>
              </header>
            ) : (
              <QualityGateHeader
                onBackClick={() => setActiveStep(1)}
                gate={qualityGate}
              />
            )}
          </div>
          {activeStep === 1 ? (
            <FirstStep
              participant={participant}
              onSubmit={submitFirstStepForm}
              gate={qualityGate}
            />
          ) : (
            <SecondStep
              participant={participant}
              onSubmit={submitSecondStepForm}
            />
          )}
        </section>
      )}
    </div>
  );
};

export default SelfVoting;
