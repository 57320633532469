import React from 'react';
import EntityBaseModal from '../EntityBaseModal/EntityBaseModal';
import styles from './ConfirmPresenceModal.module.scss';

type ConfirmPresenceModalProps = {
  getOutHandler: ({ shouldNavigateToProjects, isAutomaticRedirect }: {
    shouldNavigateToProjects?: boolean | undefined;
    isAutomaticRedirect?: boolean | undefined;
  }) => Promise<void>;
  confirmPresenceHandler: () => void;
  isShowGradientProgressBar: boolean;
  valueOfTimer: number;
  numberOfTimer: number;
  t: (key: string) => string;
};

const ConfirmPresenceModal = ({
  getOutHandler, confirmPresenceHandler, isShowGradientProgressBar, valueOfTimer, numberOfTimer, t,
}: ConfirmPresenceModalProps) => (
  <EntityBaseModal
    onClose={() => getOutHandler({ shouldNavigateToProjects: true })}
    onConfirmClick={confirmPresenceHandler}
    heading={t('Are you still here?')}
    confirmButtonText={t('Yes, I`m here')}
    cancelButtonText={t('No, get out')}
    modalClassName={styles.areYouStillHereModal}
    modalContentClassName={styles.areYouStillHereModal__content}
    isShowGradientProgressBar={isShowGradientProgressBar}
    valueTimer={valueOfTimer}
    numberOfSecondsTimer={numberOfTimer}
  >
    <p className={styles.areYouStillHereModal__message}>
      {t('If you do not confirm the action, you will be automatically redirected to the project list')}
    </p>
  </EntityBaseModal>
);

export default ConfirmPresenceModal;
