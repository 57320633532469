import Pusher from 'pusher-js';
import * as PusherTypes from 'pusher-js';

import { SOCKET_HOST, SOCKET_APP_KEY } from './constants';

class SocketApi {
  private path: string;

  private socket: PusherTypes.default | null = null;

  constructor(path: string) {
    this.path = path;
    this.createConnection();
  }

  /**
   * Creates a new Pusher connection instance
   * @returns {Pusher} The Pusher instance
   */
  private createConnection(): Pusher {
    try {
      this.socket = new Pusher(SOCKET_APP_KEY!, {
        wsHost: SOCKET_HOST!,
        enabledTransports: ['wss', 'ws'],
        cluster: '',
        forceTLS: true,
      });
    } catch (error) {
      console.error('Failed to create Pusher connection:', error);
      throw error;
    }
    return this.socket;
  }

  /**
   * Subscribes to an event and binds a callback
   * @param {string} action - The event name to bind to
   * @param {(response: T) => void} callBack - The callback function to handle the event
   */
  on<T>(action: string, callBack: (response: T) => void) {
    if (this.socket) {
      const channel = this.socket.subscribe(this.path);
      channel.bind(action, (response: T) => {
        callBack(response);
      });
    } else {
      console.warn('Socket connection not established. Call createConnection() first.');
    }
  }

  /**
   * Unsubscribes and disconnects the Pusher connection
   */
  off() {
    if (this.socket) {
      this.socket.disconnect();
    } else {
      console.warn('Socket connection not established or already disconnected.');
    }
  }
}

export default SocketApi;
