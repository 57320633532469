import { Params, redirect } from 'react-router-dom';
import { Permissions } from '../components/pages/Login/user.props.ts';
import apiClient from '../apiClient.ts';
import { ClientProps } from '../components/AdminSidebar/client.props.ts';
import { AuthStorageKey } from '../store/auth.ts';
import { GENERAL_FEATURE_FLAG_KEY, getClients, isUserHasFeatureAccess } from './main.loader.ts';

const getClient = async (id: string | number) => {
  try {
    const {
      response: { permissions },
    } = await apiClient.get<{ data: ClientProps; permissions: string[] }>(`clients/${id}`);
    return permissions;
  } catch (e) {
    console.error(e);
    return [];
  }
};

const getGlobalPermissions = async () => {
  try {
    const {
      response: {
        data: permissions,
      },
    } = await apiClient.get<{ data: string[] }>('user/permissions');
    return permissions;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export enum CheckingType {
  GLOBAL = 'global',
  CLIENT = 'client',
  FULL = 'full',
}

export const checkUserPermission = async (loaderPermission: Permissions, id?: string, checkingType?: CheckingType) => {
  const checkPermission = (permissions: {
    global: string[],
    client: string[],
  }, checkedPermission: Permissions) => {
    if (checkingType === CheckingType.GLOBAL) {
      return permissions.global.includes(checkedPermission) ?? false;
    } else if (checkingType === CheckingType.CLIENT) {
      return permissions.client.includes(checkedPermission) ?? false;
    } else {
      return [...permissions.client, ...permissions.global].includes(checkedPermission) ?? false;
    }
  };

  const savedClientPermissions = localStorage.getItem(AuthStorageKey.CLIENT_PERMISSIONS);
  const savedGlobalPermissions = localStorage.getItem(AuthStorageKey.GLOBAL_PERMISSIONS);

  const checkedPermissions = {
    global: savedGlobalPermissions ? JSON.parse(savedGlobalPermissions) : '',
    client: savedClientPermissions ? JSON.parse(savedClientPermissions) : '',
  };

  const getPermissions = async (clientId: string | number) => {
    const clientPermissions = await getClient(clientId);
    const globalPermissions = await getGlobalPermissions();
    localStorage.setItem(AuthStorageKey.CLIENT_PERMISSIONS, JSON.stringify(clientPermissions));
    localStorage.setItem(AuthStorageKey.GLOBAL_PERMISSIONS, JSON.stringify(globalPermissions));
    return checkPermission(checkedPermissions, loaderPermission);
  };

  if (savedClientPermissions && savedGlobalPermissions) {
    return checkPermission(
      checkedPermissions,
      loaderPermission,
    );
  } else if (id) {
    await getPermissions(id);
  } else {
    const clients = await getClients();
    await getPermissions(clients?.[0]?.id);
  }
};

export const checkPermissionsLoader = async (
  params?: Params<string>,
  neededPermission?: Permissions,
  isFeature?: boolean,
  checkingType: CheckingType = CheckingType.FULL,
) => {
  if (isFeature && !isUserHasFeatureAccess(GENERAL_FEATURE_FLAG_KEY)) {
    return redirect('/404');
  }
  const hasPermission = neededPermission
    ? await checkUserPermission(neededPermission, (params?.clientId ?? params?.id), checkingType)
    : true;
  if (!hasPermission) {
    return redirect('/access-denied');
  }

  return null;
};
