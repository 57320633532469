import React, { Suspense } from 'react';

const PortfolioContent = React.lazy(() => import('./PortfolioContent.tsx'));

const Portfolio = () => (
  <Suspense>
    <PortfolioContent />
  </Suspense>
);

export default Portfolio;
