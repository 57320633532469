import React from 'react';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import OptionsGroup from '../../../UIKit/OptionsGroup/OptionsGroup';
import styles from './VotingToggle.module.scss';
import { ParticipantVote } from '../types';

const getOptions = (t: TFunction<'translation', undefined>) => [
  {
    caption: t('Not voted yet'),
    value: ParticipantVote.NOT_VOTED.toString(),
  },
  {
    caption: t('Pass'),
    value: ParticipantVote.PASS.toString(),
  },
  {
    caption: t('Pass with Constraints'),
    value: ParticipantVote.PASS_WITH_CONSTRAINTS.toString(),
  },
  {
    caption: t('Fail'),
    value: ParticipantVote.FAIL.toString(),
  },
];

type VotingToggleProps = {
  t: TFunction<'translation', undefined>,
  value: string,
  setValue: (value: string) => void,
  error?: boolean,
  errorMessage?: string,
  disabled?: boolean,
};
const VotingToggle = ({
  t, value, setValue, error, errorMessage, disabled,
}: VotingToggleProps) => (
  <OptionsGroup
    {...{
      value, setValue, error, errorMessage, disabled,
    }}
    options={getOptions(t)}
    classNames={{
      button: styles.answerGroup__button,
      group: classNames(styles.answerGroup, {
        [styles.answerGroup_default]: value === ParticipantVote.NOT_VOTED.toString(),
        [styles.answerGroup_pass]: value === ParticipantVote.PASS.toString(),
        [styles.answerGroup_passWithConstraints]: value === ParticipantVote.PASS_WITH_CONSTRAINTS.toString(),
        [styles.answerGroup_fail]: value === ParticipantVote.FAIL.toString(),
      }),
    }}
  />
);

export default VotingToggle;
