import React, { Suspense } from 'react';

const ManageDeliverablesContent = React.lazy(() => import('./ManageDeliverablesContent.tsx'));

const ManageDeliverables = () => (
  <Suspense>
    <ManageDeliverablesContent />
  </Suspense>
);

export default ManageDeliverables;
