import React, { useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import useSWR from 'swr';
import { useAtomValue } from 'jotai';
import dayjs from 'dayjs';
import { KeyedMutator } from 'swr/_internal';
import weekday from 'dayjs/plugin/weekday';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import { Link, useParams } from 'react-router-dom';
import { notify } from '../../../../store/notifications.ts';
import Drawer from '../../../UIKit/Drawer/Drawer.tsx';
import styles from './ProjectHistory.module.scss';
import Button, { ButtonVariants } from '../../../UIKit/Button/Button.tsx';
import Select from '../../../UIKit/Select/Select.tsx';
import apiClient from '../../../../apiClient.ts';
import { ProjectResource, VersionResource } from '../types.ts';
import Loader from '../../../Loader/Loader.tsx';
import EmptyHistorySVG from '../../../../public/media/empty-history.svg';
import { userAtom } from '../../../../store/auth.ts';
import { DEFAULT_DATE_FORMAT } from '../../../../constants.ts';
import Hint from '../../../UIKit/Hint/Hint.tsx';
import InfoSVG from '../../../../public/media/info.svg';
import { StatusState } from '../../../UIKit/StatusLabel/types.ts';
import StatusLabel from '../../../UIKit/StatusLabel/StatusLabel.tsx';
import { ProjectPermissions } from '../../Login/user.props.ts';
import { useCustomTranslation, CustomTrans } from '../../../../useAppTranslate.tsx';

dayjs.extend(weekday);
dayjs.extend(dayOfYear);

type ProjectHistoryProps = {
  project: {
    id: number,
    name: string,
    isAllowedToRestore: boolean
  } | null,
  close: () => void,
  updateProjects: KeyedMutator<any> | (() => void)
};

const periodOptions = [
  { caption: 'All versions', value: '0' },
  { caption: 'All versions for today', value: dayjs().format('YYYY-MM-DD') },
  { caption: 'All versions for this week', value: dayjs().weekday(1).format('YYYY-MM-DD 00:00:00') },
  { caption: 'All versions for this month', value: dayjs().date(1).format('YYYY-MM-DD 00:00:01') },
  { caption: 'All versions for this year', value: dayjs().dayOfYear(1).format('YYYY-MM-DD 00:00:02') },
];

const ProjectHistory = ({ project, close, updateProjects }: ProjectHistoryProps) => {
  const { t, i18n } = useCustomTranslation();
  const userData = useAtomValue(userAtom);
  const { clientId, id } = useParams();
  const client = clientId || id;

  const [author, setAuthor] = useState('0');
  const [period, setPeriod] = useState('0');

  const { data: versions, isLoading } = useSWR(
    project ? [`projects/${project.id}/versions`, i18n.language, author, period] : null,
    ([url]) => {
      const params = new URLSearchParams({
        page: 'all',
        ...(author !== '0' ? { creators: author } : {}),
        ...(period !== '0' ? { from: period } : {}),
      }).toString();

      return apiClient.get<{ data: VersionResource[] }>(`${url}${params ? `?${params}` : ''}`).then(({ response }) => response.data);
    },
    {
      keepPreviousData: false,
      revalidateOnFocus: false,
    },
  );

  const { data: creators = [] } = useSWR(
    project ? [`projects/${project.id}/versions-creators`, i18n.language] : null,
    ([url]) => apiClient.get<{ data: { id: number; name: string }[] }>(url).then(({ response }) => response.data),
    {
      keepPreviousData: false,
      revalidateOnFocus: false,
    },
  );

  const restore = async (version: number) => {
    if (!project) return;

    try {
      const { statusCode, response } = await apiClient.post<{ message: string }>(`projects/${project.id}/versions/${version}/restore`);

      if (statusCode === 200) {
        await updateProjects();
        close();
      } else if (statusCode === 423) {
        notify({
          text: {
            title: t('This project is linked to Jira.'),
            body: t('The linked project must be removed and re-created from the restored version in the'
              + ' external system or a new project must be configured in ZEEMLESS'),
          },
        });
        throw new Error(response?.message);
      } else {
        notify({ text: { title: response?.message || t('An error occurred on restoring version'), body: '' } });
        throw new Error(response?.message);
      }
    } catch (e) {
      notify({ text: { title: e?.message || t('An error occurred on restoring version'), body: '' } });
      console.error(e);
    }
  };

  // TODO: remove after backed dev added permission and project name to the history response
  const { data: fetchedProject } = useSWR(
    project ? [`clients/${client}/projects/${project.id}?with=jiraIntegration`] : null,
    ([url]) => apiClient.get<{ data: ProjectResource }>(url).then(({ response }) => response.data),
    {
      keepPreviousData: false,
      revalidateIfStale: false,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      onError: (error) => {
        notify(error.message ? { text: { body: error.message } } : {});
        console.error(error);
      },
    },
  );

  const projectJira = fetchedProject?.jiraIntegration;
  const userHasRestoreAccess = project?.isAllowedToRestore || fetchedProject?.permissions.includes(ProjectPermissions.RESTORE);
  const projectName = project?.name || fetchedProject?.caption;

  return (
    <Drawer
      className={styles.drawer}
      isOpen={project !== null}
      setIsOpen={close}
      title={userHasRestoreAccess ? t('Restore earlier version') : t('View earlier versions')}
      subTitle={projectName}
    >
      {isLoading ? (
        <div className={styles.loader}>
          <Loader size={32} />
        </div>
      ) : (
        <>
          {projectJira && (
            <Hint
              className={styles.hint}
              icon={{
                component: InfoSVG,
                id: 'infoSVG',
                size: { width: 16, height: 16 },
                className: styles.hint__icon,
              }}
              title={t('Restore ability is restricted')}
              description={(
                <CustomTrans
                  components={{
                    lnk: (
                      <Link
                        className={styles.hint__link}
                        to={`/d/client/${client}/project/${project?.id}/summary?tab=tools`}
                      />
                    ),
                  }}
                  // eslint-disable-next-line max-len
                  defaults='Due to Jira connectivity of this project the ability to restore project’s version is restricted. Open <lnk>Tools integration</lnk> for details.'
                />
              )}
            />
          )}
          <div className={styles.filters}>
            <Select
              options={[{ name: t('All authors'), id: '0' }, ...creators].map(creator => ({
                caption: creator.name,
                value: `${creator.id}`,
              }))}
              value={author}
              setValue={(e) => setAuthor((e as SelectChangeEvent).target.value)}
              name='authors'
              labelId='authors'
              paperClassName={styles.filters__filter}
            />
            <Select
              options={periodOptions.map((option) => ({ ...option, caption: t(option.caption) }))}
              value={period}
              setValue={(e) => setPeriod((e as SelectChangeEvent).target.value)}
              name='period'
              labelId='period'
              paperClassName={styles.filters__filter}
            />
          </div>
          {versions?.length ? (
            <div className={styles.history}>
              {versions.map((version) => (
                <div
                  className={styles.history__item}
                  key={version.number}
                >
                  <div>
                    <p>
                      <span className={styles.history__version}>{version.number}</span>
                      {version.parent
                        && (
                          <span
                            className={styles.history__restored}
                          >
                            {`(${t('Restored from')} ${version.parent.number})`}
                          </span>
                        )}
                    </p>
                    <p className={styles.history__info}>
                      <span>{dayjs(version.created_at).format(`${userData?.user?.dateFormat ?? DEFAULT_DATE_FORMAT} HH:mm`)}</span>
                      {version?.creator && <span>{`${t('by')} ${version.creator}`}</span>}
                    </p>
                  </div>
                  <div className={styles.history__controls}>
                    {version?.schedule_edited && <StatusLabel status={{ caption: 'Schedule edited', state: StatusState.PENDING }} />}
                    {userHasRestoreAccess && (
                    <Button
                      variant={ButtonVariants.SECONDARY}
                      className={styles.history__button}
                      onClick={() => restore(version.id)}
                      disabled={!!projectJira}
                    >
                      {t('Restore')}
                    </Button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className={styles.emptyHistory}>
              <svg>
                <use
                  xlinkHref={`${EmptyHistorySVG}#emptyHistorySVG`}
                  href={`${EmptyHistorySVG}#emptyHistorySVG`}
                />
              </svg>
              <p>{t('No project versions available')}</p>
            </div>
          )}
        </>
      )}
    </Drawer>
  );
};

export default ProjectHistory;
