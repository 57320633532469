import React from 'react';
import AbstSVG from '../../../../../public/media/deliverable_domains/Abst.svg';
import AbstractSVG from '../../../../../public/media/deliverable_domains/Abstract.svg';
import AnalysisSVG from '../../../../../public/media/deliverable_domains/Analysis.svg';
import AppSVG from '../../../../../public/media/deliverable_domains/App.svg';
import ArrowsSVG from '../../../../../public/media/deliverable_domains/Arrows.svg';
import AssignmentSVG from '../../../../../public/media/deliverable_domains/Assignment.svg';
import BadgeSVG from '../../../../../public/media/deliverable_domains/Badge.svg';
import BoardsSVG from '../../../../../public/media/deliverable_domains/Boards.svg';
import BuildSVG from '../../../../../public/media/deliverable_domains/Build_and_test.svg';
import CardsSVG from '../../../../../public/media/deliverable_domains/Cards.svg';
import ComplianceSVG from '../../../../../public/media/deliverable_domains/Compliance.svg';
import ContractSVG from '../../../../../public/media/deliverable_domains/Contract.svg';
import CoreSVG from '../../../../../public/media/deliverable_domains/Core.svg';
import CorporateSVG from '../../../../../public/media/deliverable_domains/Corporate.svg';
import CutoverSVG from '../../../../../public/media/deliverable_domains/Cutover.svg';
import DataUsageSVG from '../../../../../public/media/deliverable_domains/Data_usage.svg';
import FilesUploadedSVG from '../../../../../public/media/deliverable_domains/Files_uploaded.svg';
import FinancesSVG from '../../../../../public/media/deliverable_domains/Finances.svg';
import FocusSVG from '../../../../../public/media/deliverable_domains/Focus.svg';
import GroupSVG from '../../../../../public/media/deliverable_domains/Group.svg';
import HubSVG from '../../../../../public/media/deliverable_domains/Hub.svg';
import HypercareSVG from '../../../../../public/media/deliverable_domains/Hypercare.svg';
import InfrastructureSVG from '../../../../../public/media/deliverable_domains/Infrastructure.svg';
import IntegrationSVG from '../../../../../public/media/deliverable_domains/Integration.svg';
import LeaderboardsSVG from '../../../../../public/media/deliverable_domains/Leaderboards.svg';
import MatterSVG from '../../../../../public/media/deliverable_domains/Matter.svg';
import MergeSVG from '../../../../../public/media/deliverable_domains/Merge.svg';
import OcSVG from '../../../../../public/media/deliverable_domains/Oc.svg';
import OverviewSVG from '../../../../../public/media/deliverable_domains/Overview.svg';
import PreviewSVG from '../../../../../public/media/deliverable_domains/Preview.svg';
import ProjectsSVG from '../../../../../public/media/deliverable_domains/Projects.svg';
import PromptSVG from '../../../../../public/media/deliverable_domains/Prompt.svg';
import RecentsSVG from '../../../../../public/media/deliverable_domains/Recents.svg';
import RectangleSVG from '../../../../../public/media/deliverable_domains/Rectangle.svg';
import RewardSVG from '../../../../../public/media/deliverable_domains/Reward.svg';
import RolesSVG from '../../../../../public/media/deliverable_domains/Roles.svg';
import ScanSVG from '../../../../../public/media/deliverable_domains/Scan.svg';
import ScoreSVG from '../../../../../public/media/deliverable_domains/Score.svg';
import SettingsSVG from '../../../../../public/media/deliverable_domains/Settings.svg';
import SpokeSVG from '../../../../../public/media/deliverable_domains/Spoke.svg';
import StorSVG from '../../../../../public/media/deliverable_domains/Stor.svg';
import StreamsSVG from '../../../../../public/media/deliverable_domains/Streams.svg';
import SwitchSVG from '../../../../../public/media/deliverable_domains/Switch.svg';
import TargetSVG from '../../../../../public/media/deliverable_domains/Target.svg';
import TokenSVG from '../../../../../public/media/deliverable_domains/Token.svg';
import TransitionSVG from '../../../../../public/media/deliverable_domains/Transition.svg';
import UnknownSVG from '../../../../../public/media/deliverable_domains/Unknown.svg';
import UserInfoSVG from '../../../../../public/media/deliverable_domains/UserInfo.svg';
import ValidationSVG from '../../../../../public/media/deliverable_domains/Validation.svg';
import WidgetsSVG from '../../../../../public/media/deliverable_domains/Widgets.svg';
import WorkSVG from '../../../../../public/media/deliverable_domains/Work.svg';
import WorkspaceSVG from '../../../../../public/media/deliverable_domains/Workspace.svg';
import NoneSVG from '../../../../../public/media/deliverable_domains/None.svg';

import styles from './DeliverablesList.module.scss';

export const icons = {
  abst: { name: AbstSVG, id: 'abstSVG' },
  abstract: { name: AbstractSVG, id: 'abstractSVG' },
  analysis: { name: AnalysisSVG, id: 'analysisSVG' },
  app: { name: AppSVG, id: 'appSVG' },
  arrows: { name: ArrowsSVG, id: 'arrowsSVG' },
  assignment: { name: AssignmentSVG, id: 'assignmentSVG' },
  badge: { name: BadgeSVG, id: 'badgeSVG' },
  boards: { name: BoardsSVG, id: 'boardsSVG' },
  build: { name: BuildSVG, id: 'buildSVG' },
  cards: { name: CardsSVG, id: 'cardsSVG' },
  compliance: { name: ComplianceSVG, id: 'complianceSVG' },
  contract: { name: ContractSVG, id: 'contractSVG' },
  core: { name: CoreSVG, id: 'coreSVG' },
  corporate: { name: CorporateSVG, id: 'corporateSVG' },
  cutover: { name: CutoverSVG, id: 'cutoverSVG' },
  dataUsage: { name: DataUsageSVG, id: 'dataUsageSVG' },
  filesUploaded: { name: FilesUploadedSVG, id: 'filesUploadedSVG' },
  finances: { name: FinancesSVG, id: 'financesSVG' },
  focus: { name: FocusSVG, id: 'focusSVG' },
  group: { name: GroupSVG, id: 'groupSVG' },
  hub: { name: HubSVG, id: 'hubSVG' },
  hypercare: { name: HypercareSVG, id: 'hypercareSVG' },
  infrastructure: { name: InfrastructureSVG, id: 'infrastructureSVG' },
  integration: { name: IntegrationSVG, id: 'integrationSVG' },
  leaderboards: { name: LeaderboardsSVG, id: 'leaderboardsSVG' },
  matter: { name: MatterSVG, id: 'matterSVG' },
  merge: { name: MergeSVG, id: 'mergeSVG' },
  oc: { name: OcSVG, id: 'ocSVG' },
  overview: { name: OverviewSVG, id: 'overviewSVG' },
  preview: { name: PreviewSVG, id: 'previewSVG' },
  projects: { name: ProjectsSVG, id: 'projectsSVG' },
  prompt: { name: PromptSVG, id: 'promptSVG' },
  recents: { name: RecentsSVG, id: 'recentsSVG' },
  rectangle: { name: RectangleSVG, id: 'rectangleSVG' },
  reward: { name: RewardSVG, id: 'rewardSVG' },
  roles: { name: RolesSVG, id: 'rolesSVG' },
  scan: { name: ScanSVG, id: 'scanSVG' },
  score: { name: ScoreSVG, id: 'scoreSVG' },
  settings: { name: SettingsSVG, id: 'settingsSVG' },
  spoke: { name: SpokeSVG, id: 'spokeSVG' },
  stor: { name: StorSVG, id: 'storSVG' },
  streams: { name: StreamsSVG, id: 'streamsSVG' },
  switch: { name: SwitchSVG, id: 'switchSVG' },
  target: { name: TargetSVG, id: 'targetSVG' },
  token: { name: TokenSVG, id: 'tokenSVG' },
  transition: { name: TransitionSVG, id: 'transitionSVG' },
  unknown: { name: UnknownSVG, id: 'unknownSVG' },
  userInfo: { name: UserInfoSVG, id: 'userInfoSVG' },
  validation: { name: ValidationSVG, id: 'validationSVG' },
  widgets: { name: WidgetsSVG, id: 'widgetsSVG' },
  work: { name: WorkSVG, id: 'workSVG' },
  workspace: { name: WorkspaceSVG, id: 'workspaceSVG' },
  none: { name: NoneSVG, id: 'noneSVG' },
};

type DomainIconProps = {
  iconName: keyof typeof icons | string,
};
const DomainIcon = ({ iconName }: DomainIconProps) => {
  const icon = icons[iconName as keyof typeof icons];

  return (
    <div className={styles.domainIcon}>
      <svg>
        <use
          xlinkHref={icon ? `${icon.name}#${icon.id}` : `${icons.none.name}#${icons.none.id}`}
          href={icon ? `${icon.name}#${icon.id}` : `${icons.none.name}#${icons.none.id}`}
        />
      </svg>
    </div>
  );
};

export default DomainIcon;
