import React, { useMemo, useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import styles from './VoteGroup.module.scss';

enum VoteResult {
  PASS = 'pass',
  PASS_WITH_CONSTRAINTS = 'passWithConstraints',
  FAIL = 'fail',
}

const VoteGroup = () => {
  const [vote, setVote] = useState<VoteResult>(VoteResult.PASS);

  const backgroundColor = useMemo(() => (vote === VoteResult.PASS
    ? 'rgba(96, 198, 106, 0.12);' : vote === VoteResult.PASS_WITH_CONSTRAINTS
      ? 'rgba(255, 187, 24, 0.12);'
      : 'rgba(235, 96, 96, 0.12);'), [vote]);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    voteValue: VoteResult,
  ) => {
    if (voteValue !== null) setVote(voteValue);
  };

  const children = [
    <ToggleButton
      className={styles.button}
      value={VoteResult.PASS}
      key='pass'
    >
      Pass
    </ToggleButton>,
    <ToggleButton
      className={styles.button}
      value={VoteResult.PASS_WITH_CONSTRAINTS}
      key='passWithConstraints'
    >
      Pass with Constraints
    </ToggleButton>,
    <ToggleButton
      className={styles.button}
      value={VoteResult.FAIL}
      key='fail'
    >
      Fail
    </ToggleButton>,
  ];

  const control = {
    value: vote,
    onChange: handleChange,
    exclusive: true,
  };

  return (
    <ToggleButtonGroup
      {...control}
      className={styles.voteGroup}
      sx={{
        background: backgroundColor,
      }}
    >
      {children}
    </ToggleButtonGroup>
  );
};

export default VoteGroup;
