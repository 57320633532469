import React from 'react';
import { useAtomValue } from 'jotai';

import Button, { ButtonVariants } from '../../UIKit/Button/Button';

import { twoFARecoveryCode } from '../../../store/auth';
import { useCustomTranslation } from '../../../useAppTranslate';

import CopySVG from '../../../public/media/copy.svg';
import DownloadSVG from '../../../public/media/simple-download.svg';
import InfoSVG from '../../../public/media/info.svg';
import PrintSVG from '../../../public/media/print.svg';

import styles from './RecoveryCode.module.scss';

const copyText = (text: string[]) => {
  navigator.clipboard.writeText(text.join(' '));
};

const downloadText = (text: string[]) => {
  const element = document.createElement('a');
  const file = new Blob([text.join(' ')], { type: 'text/plain' });
  element.href = URL.createObjectURL(file);
  element.download = 'recovery-codes.txt';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

const printText = (text: string[]) => {
  const iframe = document.createElement('iframe');
  iframe.style.position = 'absolute';
  iframe.style.width = '0';
  iframe.style.height = '0';
  iframe.style.border = 'none';
  document.body.appendChild(iframe);
  const onIframeLoad = () => {
    try {
      if (iframe.contentDocument && iframe.contentWindow) {
        iframe.contentDocument.body.innerHTML = text.join('<br>');
        iframe.contentWindow.focus();
        iframe.contentWindow.print();
      }
    } catch (error) {
      console.error('Error printing content:', error);
    } finally {
      setTimeout(() => {
        document.body.removeChild(iframe);
      }, 100);
    }
  };

  iframe.addEventListener('load', onIframeLoad);
};

const RecoveryCode = () => {
  const { t } = useCustomTranslation();
  const recoveryCode = useAtomValue(twoFARecoveryCode);

  return (
    <section className={styles.recovery}>
      <div className={styles.recovery__warning}>
        <div className={styles.recovery__warning__title}>
          <svg>
            <use
              xlinkHref={`${InfoSVG}#infoSVG`}
              href={`${InfoSVG}#infoSVG`}
            />
          </svg>
          <p>{t('Please copy, download, or print your recovery codes before proceeding.')}</p>
        </div>
        <p className={styles.recovery__warning__body}>
          {/* eslint-disable-next-line max-len */}
          {t('Should you ever lose your phone or access to your one time password secret, each of these recovery codes can be used one time each to regain access to your account. Please save them in a safe place, or you will lose access to your account.')}
        </p>
      </div>
      <div className={styles.recovery__code}>
        <ul>
          {recoveryCode?.map(code => <li key={code}>{code}</li>)}
        </ul>
      </div>
      <div className={styles.recovery__footer}>
        <Button
          className={styles.recovery__btn}
          onClick={() => copyText(recoveryCode)}
          icon={(
            <svg>
              <use
                xlinkHref={`${CopySVG}#copySVG`}
                href={`${CopySVG}#copySVG`}
              />
            </svg>
                  )}
        >
          {t('Copy codes')}
        </Button>
        <Button
          className={styles.recovery__btn}
          variant={ButtonVariants.SECONDARY}
          onClick={() => downloadText(recoveryCode)}
          icon={(
            <svg>
              <use
                xlinkHref={`${DownloadSVG}#simpleDownloadSVG`}
                href={`${DownloadSVG}#simpleDownloadSVG`}
              />
            </svg>
          )}
        >
          {t('Download codes')}
        </Button>
        <Button
          className={styles.recovery__btn}
          variant={ButtonVariants.SECONDARY}
          onClick={() => printText(recoveryCode)}
          icon={(
            <svg>
              <use
                xlinkHref={`${PrintSVG}#printSVG`}
                href={`${PrintSVG}#printSVG`}
              />
            </svg>
          )}
        >
          {t('Print codes')}
        </Button>
      </div>
    </section>
  );
};

export default RecoveryCode;
