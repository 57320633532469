import classnames from 'classnames';
import React from 'react';
import ErrorSVG from '../../../../public/media/error.svg';
import styles from './Error.module.scss';

const Error = ({ message, className }: { message: string, className?: string }) => (
  <div className={classnames(className, styles.error)}>
    <svg className={styles.error__icon}>
      <use
        xlinkHref={`${ErrorSVG}#errorSVG`}
        href={`${ErrorSVG}#errorSVG`}
      />
    </svg>
    <p className={styles.error__message}>{message}</p>
  </div>
);

export default Error;
