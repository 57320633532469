import { PASSWORD_REGEXP } from '../constants.ts';

export const evaluatePasswordStrength = (password: string): string => {
  let strength = 0;

  if (PASSWORD_REGEXP.CASE.test(password)) strength += 1;
  if (PASSWORD_REGEXP.SPECIAL_CHARACTER.test(password)) strength += 1;
  if (PASSWORD_REGEXP.TWO_OR_MORE_SPECIAL_CHARACTERS.test(password)) strength += 1;
  if (PASSWORD_REGEXP.NUMBER.test(password)) strength += 1;

  if (PASSWORD_REGEXP.CONSECUTIVE_LOWER_CASE.test(password)) strength -= 1;
  if (PASSWORD_REGEXP.CONSECUTIVE_UPPER_CASE.test(password)) strength -= 1;
  if (PASSWORD_REGEXP.CONSECUTIVE_NUMBER.test(password)) strength -= 1;

  strength += Math.round(Math.log2(password.length)) - 1;

  switch (true) {
    case strength >= 5: return 'excellent';
    case strength === 4: return 'good';
    case (strength === 3 || strength === 2): return 'good';
    case (strength <= 1): return 'poor';
    default: return 'poor';
  }
};
