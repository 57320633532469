import classNames from 'classnames';
import React from 'react';
import MuiTabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
import styles from './Tabs.module.scss';

type TabsProps = {
  value: number,
  setValue: (value: number) => void,
  tabs: {
    id: number,
    button: {
      title: React.ReactNode | string,
      callback?: () => void,
    },
    content: React.ReactNode,
  }[],
  buttonsClassName?: string,
  contentClassName?: string,
};
const Tabs = ({
  value, setValue, tabs, buttonsClassName, contentClassName,
}: TabsProps) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={styles.tabs}>
      <MuiTabs
        value={value}
        onChange={handleChange}
        className={classNames(styles.tabButtons, buttonsClassName)}
      >
        {tabs.map(tab => (
          <MuiTab
            key={tab.id}
            disableRipple
            label={tab.button.title}
            onClick={tab.button.callback}
            id={`tab-${tab.id}`}
            aria-controls={`tabpanel-${tab.id}`}
            className={styles.tabButton}
          />
        ))}
      </MuiTabs>
      <div className={classNames(styles.tabContent, contentClassName)}>
        {tabs.map(tab => (
          <div
            key={tab.id}
            role='tabpanel'
            hidden={value !== tab.id}
            id={`tabpanel-${tab.id}`}
            aria-labelledby={`tab-${tab.id}`}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
