import React, { useState } from 'react';
import classNames from 'classnames';
import { useAtom, useAtomValue } from 'jotai';
import dayjs from 'dayjs';
import MenuSVG from '../../public/media/menu.svg';
import {
  latestReleaseAtom, userAtom, userClientAtom, releaseBannerAtom,
} from '../../store/auth.ts';
import { previewLogoAtom, clientPreviewIdAtom, customLogoAtom } from '../../store/clientCI.ts';
import HeaderContent from './HeaderContent';
import styles from './Header.module.scss';
import HeaderMobileMenu from './HeaderMobileMenu.tsx';
import Drawer from '../UIKit/Drawer/Drawer.tsx';
import Banner from '../Banner/Banner.tsx';
import { DEFAULT_DATE_FORMAT } from '../../constants.ts';
import apiClient from '../../apiClient.ts';
import { IRelease } from '../pages/ReleaseNotes/ReleaseRow.tsx';
import ReleaseDetails from '../pages/ReleaseNotes/ReleaseDetails.tsx';
import { NotificationStatus, notify } from '../../store/notifications.ts';
import Logo from './Logo.tsx';
import { useCustomTranslation } from '../../useAppTranslate.tsx';

const Header = ({ showLogo = true, showAdminSidebar }: { showLogo?: boolean, showAdminSidebar?: boolean }) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isReleaseDrawerOpen, setIsReleaseDrawerOpen] = useState(false);
  const { t } = useCustomTranslation('global');
  const userData = useAtomValue(userAtom);
  const [isLogoutModalOpened, setIsLogoutModalOpened] = useState<boolean>(false);
  const clientPreviewId = useAtomValue(clientPreviewIdAtom);
  const previewLogo = useAtomValue(previewLogoAtom);
  const customLogo = useAtomValue(customLogoAtom);

  const currentClient = useAtomValue(userClientAtom);
  const [latestRelease, setLatestRelease] = useAtom(latestReleaseAtom);
  const [releaseBanner, setReleaseBanner] = useAtom(releaseBannerAtom);
  const [selectedRelease, setSelectedRelease] = useState<IRelease | null>(null);
  const [isLoadingRelease, setIsLoadingRelease] = useState(false);
  const [isReleaseError, setIsReleaseError] = useState(false);

  const dateFormat = userData?.user.dateFormat ?? DEFAULT_DATE_FORMAT;
  const releaseDate = dayjs(latestRelease?.latest_release?.release_date).format(dateFormat);

  const updateNotification = () => {
    if (latestRelease && typeof latestRelease.unread === 'number') {
      const reduceCondition = latestRelease.unread - 1;
      setLatestRelease(prev => (prev ? { ...prev, unread: reduceCondition } : null));
    }
  };

  const readReleaseBanner = () => {
    setReleaseBanner({ isBannerShown: false, isBannerRead: true });
  };

  const markAsReadRelease = async (releaseId: number) => apiClient.put(`releases/${releaseId}/markAsRead`)
    .then(({ statusCode }) => {
      if (statusCode === 204) {
        updateNotification();
      } else {
        throw new Error(t('Something went wrong'));
      }
    })
    .catch((e) => {
      notify(e?.message ? { status: NotificationStatus.ERROR, text: { body: e.message } } : {});
      console.error(e);
    });

  const getReleaseById = async () => {
    setIsReleaseDrawerOpen(true);
    setIsLoadingRelease(true);
    if (latestRelease?.latest_release?.id) {
      await apiClient.get<{ data: IRelease }>(`releases/${latestRelease?.latest_release?.id}`)
        .then(({ statusCode, response }) => {
          if (statusCode === 200) {
            setSelectedRelease(response.data);
            if (!latestRelease?.latest_release.read) {
              markAsReadRelease(latestRelease?.latest_release.id);
              readReleaseBanner();
            }
          } else {
            throw new Error(t('Something went wrong'));
          }
        })
        .catch((e) => {
          setIsReleaseError(true);
          notify(e?.message ? { status: NotificationStatus.ERROR, text: { body: e.message } } : {});
          console.error(e);
        })
        .finally(() => setIsLoadingRelease(false));
    }
  };

  const openReleaseDescription = () => {
    getReleaseById();
  };
  return (
    <>
      {(releaseBanner.isBannerShown && latestRelease?.latest_release)
        && (
          <Banner
            text={`${t('On')} ${releaseDate} ${t('you can expect a new Zeemless release with new changes. For more information,')}`}
            actionText={t('click here.')}
            action={openReleaseDescription}
            closeBanner={() => setReleaseBanner((prev) => ({ ...prev, isBannerShown: false }))}
          />
        )}
      <Drawer
        isOpen={isReleaseDrawerOpen}
        setIsOpen={setIsReleaseDrawerOpen}
        title={t('New release changes')}
        subTitle={`${t('From')} ${releaseDate}`}
        className={styles.releaseDrawer}
        contentClassName={styles.releaseDrawerContent}
        loading={isLoadingRelease}
        error={isReleaseError}
      >
        <ReleaseDetails
          selectedRelease={selectedRelease}
          releaseDetails={selectedRelease?.description}
          setIsReleaseDrawerOpen={setIsReleaseDrawerOpen}
        />
      </Drawer>
      <header
        className={classNames(styles.header, {
          [styles.withoutLogo]: !showLogo,
        })}
      >
        {showLogo && (
          <Logo
            clientPreviewId={clientPreviewId}
            previewLogo={previewLogo}
            customLogo={customLogo}
            currentClient={currentClient}
          />
        )}
        <button
          className={styles.menuButton}
          type='button'
          onClick={() => setIsMenuOpened(true)}
          aria-label={t('Open menu')}
        >
          <svg>
            <use
              xlinkHref={`${MenuSVG}#menuSVG`}
              href={`${MenuSVG}#menuSVG`}
            />
          </svg>
        </button>
        <HeaderContent
          isModalOpened={isLogoutModalOpened}
          setIsModalOpened={setIsLogoutModalOpened}
        />
        <HeaderMobileMenu
          isMenuOpened={isMenuOpened}
          setIsMenuOpened={setIsMenuOpened}
          setIsLogoutModalOpened={setIsLogoutModalOpened}
          user={userData?.user}
          showAdminSidebar={showAdminSidebar}
        />
      </header>
    </>
  );
};

export default Header;
