import { NUMBER_FORMAT } from '../constants';

export function formatNumberAsEuro(amount: number, numberFormat: typeof NUMBER_FORMAT[string]): string {
  const formattedNumber = new Intl.NumberFormat(numberFormat.locale, {
    style: 'decimal',
    maximumFractionDigits: numberFormat.decimalScale,
    minimumFractionDigits: numberFormat.decimalScale,
  }).format(amount);
  return `€ ${formattedNumber}`;
}
