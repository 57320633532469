import EmptyListSVG from '../../public/media/empty-list.svg';

import styles from './EmptyList.module.scss';

type EmptyListProps = {
  title: string;
};
const EmptyList = ({ title }: EmptyListProps) => (
  <div className={styles.emptyList}>
    <svg className={styles.emptyList__icon}>
      <use
        xlinkHref={`${EmptyListSVG}#emptyListSVG`}
        href={`${EmptyListSVG}#emptyListSVG`}
      />
    </svg>
    <p className={styles.emptyList__title}>{title}</p>
  </div>
);

export default EmptyList;
