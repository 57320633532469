import classNames from 'classnames';
import React, { ReactNode, useEffect, useState } from 'react';
import DotsSVG from '../../../public/media/dots.svg';
import ShevronSVG from '../../../public/media/angle_right.svg';

import Popover, { PopoverPlacement } from '../Popover/Popover';
import styles from './PopoverOptions.module.scss';

export type PopoverOptionsProps = {
  customButton?: React.ReactNode,
  options: IPopoverOptions[],
  buttonClassName?: string,
  className?: string,
  paperClassName?: string,
  placement?: PopoverPlacement,
  onClose?: () => void,
  activeOptionId?: number | string | null,
};

export interface IPopoverOptions {
  id: number | string,
  title: string | JSX.Element,
  icon?: ReactNode,
  handler?: () => void,
  preventClose?: boolean,
  hidden?: boolean,
  disabled?: boolean,
  children?: any[]
}
const PopoverOptions = ({
  customButton, options, buttonClassName, className, paperClassName, placement = PopoverPlacement.CONTEXT_MENU, onClose, activeOptionId,
}: PopoverOptionsProps) => {
  const [closePopover, setClosePopover] = useState<() => void>(() => {
  });

  const closeCallback = (func: (e: Event) => void) => {
    setClosePopover(() => func);
  };

  const [activeItem, setActiveItem] = useState<number | string | null>(activeOptionId || null);
  useEffect(() => {
    if (activeOptionId) {
      setActiveItem(activeOptionId);
    }
  }, [activeOptionId]);

  return (
    <Popover
      paperClassName={classNames(styles.popoverPaper, paperClassName)}
      className={className}
      placement={placement || PopoverPlacement.CONTEXT_MENU}
      triggerButton={customButton || (
        <div className={classNames(styles.button, buttonClassName)}>
          <svg>
            <use
              xlinkHref={`${DotsSVG}#dotsSVG`}
              href={`${DotsSVG}#dotsSVG`}
            />
          </svg>
        </div>
      )}
      closeCallback={closeCallback}
      onClose={onClose}
    >
      {options.map(option => (
        !option.hidden && (!option.children ? (
          <button
            key={option.id}
            type='button'
            onClick={(e) => {
              e?.stopPropagation();
              option.handler?.();
              !option.preventClose && closePopover();
            }}
            className={classNames(styles.option, {
              [styles.option_withIcon]: option.icon,
              [styles.option_active]: activeItem === option.id,
            })}
            disabled={option.disabled}
          >
            {option?.icon && option?.icon}
            {option.title}
          </button>
        ) : (
          <Popover
            paperClassName={classNames(styles.popoverPaper, paperClassName)}
            placement={PopoverPlacement.LEFT}
            className={styles.option_withChildren}
            onClose={() => setActiveItem(null)}
            triggerButton={(
              <button
                key={option.id}
                type='button'
                className={classNames(styles.option, {
                  [styles.option_active]: activeItem === option.id,
                })}
                disabled={option.disabled}
                onClick={() => {
                  setActiveItem(option.id);
                }}
              >
                {option.title}
                <svg className={styles.option__shevron}>
                  <use
                    xlinkHref={`${ShevronSVG}#angleRightSVG`}
                    href={`${ShevronSVG}#angleRightSVG`}
                  />
                </svg>
              </button>
          )}
            withoutArrow
          >
            {option.children?.map(child => (
              <button
                key={child.id}
                type='button'
                onClick={(e) => {
                  e?.stopPropagation();
                  child.handler();
                  !child.preventClose && closePopover();
                }}
                className={styles.option}
                disabled={child.disabled}
              >
                {child.title}
              </button>
            ))}
          </Popover>
        ))

      ))}
    </Popover>
  );
};

export default PopoverOptions;
