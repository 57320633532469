import dayjs from 'dayjs';
import { GridFilterInputValueProps, GridFilterModel } from '@mui/x-data-grid';
import { useAtom, useAtomValue } from 'jotai';

import DatePicker from '../../DatePicker/DatePicker';

import { userAtom } from '../../../../store/auth';
import { filters } from '../../../../store/table';
import { DEFAULT_DATE_FORMAT } from '../../../../constants';

import styles from '../Table.module.scss';

const DATE_FORMAT = 'YYYY-MM-DD';

function DateRangeFilter(props: GridFilterInputValueProps) {
  const { item, applyValue } = props;
  const userData = useAtomValue(userAtom);
  const [currentFilters, setCurrentFilters] = useAtom(filters);

  const savedItem = currentFilters?.items?.find((scope) => scope?.field === props?.item.field);
  const getValueByFiled = (scopeFilters: GridFilterModel | null, field: string) => scopeFilters
    ?.items?.find((itm) => itm?.field === field)?.value;

  return (
    <div className={styles.filter__body}>
      <p className={styles.filter__title}>Date range</p>
      <div className={styles.filter__inputGroup}>
        <DatePicker
          format={userData?.user?.dateFormat || DEFAULT_DATE_FORMAT}
          label='From'
          value={
            getValueByFiled(currentFilters, props?.item.field)?.[0] ? dayjs(getValueByFiled(currentFilters, props?.item.field)?.[0]) : null
          }
          className={styles.filter__input}
          setValue={(date) => applyValue({ ...item, value: [dayjs(date).format(DATE_FORMAT), savedItem?.value?.[1] || null] })}
        />
        <DatePicker
          format={userData?.user?.dateFormat || DEFAULT_DATE_FORMAT}
          label='To'
          value={
            getValueByFiled(currentFilters, props?.item.field)?.[1] ? dayjs(getValueByFiled(currentFilters, props?.item.field)?.[1]) : null
          }
          setValue={(date) => applyValue({ ...item, value: [savedItem?.value?.[0] || null, dayjs(date).format(DATE_FORMAT)] })}
          className={styles.filter__input}
        />
      </div>
      <button
        type='button'
        className={styles.filter__clearBtn}
        onClick={() => {
          const filtered = currentFilters?.items?.filter((scope) => scope?.field !== item.field) || [];
          setCurrentFilters({
            ...currentFilters,
            items: filtered,
          });
          applyValue({ ...item, value: null });
        }}
      >
        Clear all
      </button>
    </div>
  );
}

export default DateRangeFilter;
