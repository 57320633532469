import { Params } from 'react-router-dom';
import apiClient from '../apiClient.ts';
import { generatePalette } from '../colorPaletteGenerator.ts';
import { AuthStorageKey } from '../store/auth.ts';
import { clientPreviewId, customLogoStore, FileWithPreview } from '../store/clientCI.ts';
import { getClients } from './main.loader.ts';

const DEFAULT_PRIMARY_COLOR = '#006788';

type UpdateClientCIProps = {
  id: number;
  color: string;
  logo?: FileWithPreview
};

const uploadFile = async (token: string, logo: FileWithPreview) => {
  const formData = new FormData();
  formData.append('file', logo);
  formData.append('token', token);
  try {
    const { statusCode: uploadStatusCode, response: uploadResponse } = await apiClient.post<{
      message: string,
    }>('upload', {
      body: formData,
    });
    if (uploadStatusCode === 201) {
      return token;
    } else {
      throw new Error(uploadResponse.message);
    }
  } catch (e) {
    throw new Error(e);
  }
};
const uploadLogo = async (id: number, logo: FileWithPreview) => {
  try {
    const { response, statusCode } = await apiClient.post<{
      data: {
        token: string,
      }
    }>(`clients/${id}/upload`, {
      body: JSON.stringify({
        client: id,
      }),
    });
    if (statusCode === 201) {
      return await uploadFile(response.data.token, logo);
    } else {
      throw new Error();
    }
  } catch (e) {
    throw new Error(e);
  }
};
export const updateClientCI = async ({ id, color, logo }: UpdateClientCIProps) => {
  try {
    const uploadToken = logo && await uploadLogo(id, logo);
    const { statusCode } = await apiClient.put(`clients/${id}`, {
      body: JSON.stringify({
        primary_accent: color,
        logo: uploadToken ?? undefined,
      }),
    });

    if (statusCode === 200) {
      return { statusCode };
    } else {
      throw new Error();
    }
  } catch (e) {
    console.error(e.message);
    return { errorMessage: e.message };
  }
};

export const resetColorPalette = () => {
  const primaryColorPalette = generatePalette(DEFAULT_PRIMARY_COLOR, 'primary');
  Object.entries(primaryColorPalette).forEach(([key, value]) => {
    document.documentElement.style.setProperty(key, value);
  });
};

export async function clientCiLoader(params?: Params) {
  const isPreviewMode = clientPreviewId.getState();
  if (isPreviewMode) return;

  const clients = await getClients();
  if (!clients) return;

  const id = params?.id || params?.clientId;

  const client = id && clients.find((clientsClient) => clientsClient.id === +id);
  client && localStorage.setItem(AuthStorageKey.USER_CLIENT, JSON.stringify(client));
  const currentClient = JSON.parse(localStorage.getItem(AuthStorageKey.USER_CLIENT) || '');

  const currentPrimaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-500');

  if (currentClient?.primary_accent) {
    if (currentPrimaryColor !== currentClient.primary_accent) {
      const primaryColorPalette = generatePalette(currentClient.primary_accent, 'primary');
      Object.entries(primaryColorPalette).forEach(([key, value]) => {
        document.documentElement.style.setProperty(key, value);
      });
    }
  } else {
    currentPrimaryColor !== DEFAULT_PRIMARY_COLOR && resetColorPalette();
  }

  customLogoStore.setState({ logoUrl: currentClient?.logo || null, id: Number(id) });

  return null;
}
