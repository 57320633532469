import classNames from 'classnames';
import dayjs from 'dayjs';
import useSWR from 'swr';
import { useOutletContext, useParams } from 'react-router-dom';
import { useAtomValue } from 'jotai';

import EmptyList from '../../../../EmptyList/EmptyList';
import Loader from '../../../../Loader/Loader';

import apiClient from '../../../../../apiClient';
import { StakeholderResource } from '../types';
import { userAtom } from '../../../../../store/auth';
import { NewProjectContext } from '../../types';
import { StatusState } from '../../../../UIKit/StatusLabel/types';
import { useCustomTranslation } from '../../../../../useAppTranslate';

import styles from './SummaryDashboard.module.scss';

const SummaryStakeholders = () => {
  const { projectAtom } = useOutletContext<Pick<NewProjectContext, 'projectAtom'>>();
  const project = useAtomValue(projectAtom);
  const { t, i18n } = useCustomTranslation();
  const { projectId } = useParams();
  const userData = useAtomValue(userAtom);

  const dateFormat = userData?.user?.dateFormat || 'DD.MM.YYYY';

  const { data: stakeholders, isLoading, isValidating } = useSWR(
    [`projects/${projectId}/stakeholder-groups?page=all`, i18n.language, project?.status?.state],
    async ([url]) => (project?.status?.state !== StatusState.PENDING
      ? apiClient.get<{ data: StakeholderResource[] }>(url).then(({ response }) => response.data)
      : null),
    {
      keepPreviousData: false,
      revalidateOnFocus: false,
      revalidateOnMount: true,
    },
  );

  return (
    <section className={classNames(styles.section, styles.section_withList, styles.stakeholders, {
      [styles.section_validating]: isValidating && !isLoading,
    })}
    >
      {isLoading ? (
        <Loader className={styles.loader} />
      ) : (
        <>
          <header className={styles.sectionHeader}>
            <h3 className={styles.sectionTitle}>{t('Main stakeholder groups')}</h3>
          </header>
          {stakeholders?.length ? (
            <div className={styles.stakeholders__list}>
              <header
                className={classNames(styles.list__row, styles.list__header, styles.list__header_nowrap, styles.stakeholders__list__row)}
              >
                <p className={styles.list__row_onLeft}>{t('Name')}</p>
                <p className={classNames(styles.list__row_onRight)}>{t('First involvement')}</p>
              </header>
              {stakeholders?.map((item) => (
                <div
                  key={item.id}
                  className={classNames(styles.list__row, styles.stakeholders__list__item)}
                >
                  <p>{item.caption}</p>
                  {!!item.date && <p className={styles.stakeholders__list__item__date}>{dayjs(item.date).format(dateFormat)}</p>}
                </div>
              ))}
            </div>
          ) : (
            <EmptyList title='Stakeholders not added' />
          )}
        </>
      )}
    </section>
  );
};

export default SummaryStakeholders;
