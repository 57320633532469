import classNames from 'classnames';
import React from 'react';
import { ToggleButton, ToggleButtonGroup as MUIToggleButtonGroup } from '@mui/material';
import styles from './ToggleButtonGroup.module.scss';

type ToggleButtonGroupProps = {
  options: string[],
  value: string | null,
  setValue: (value: string) => void,
  disabled?: boolean,
  className?: string,
};
const ToggleButtonGroup = ({
  options, value, setValue, disabled, className,
}: ToggleButtonGroupProps) => {
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    buttonValue: string | null,
  ) => {
    if (buttonValue !== null) setValue(buttonValue);
  };

  const control = {
    value,
    onChange: handleChange,
    exclusive: true,
  };

  return (
    <MUIToggleButtonGroup
      {...control}
      className={classNames(styles.buttonGroup, className, {
        [styles.disabled]: disabled,
      })}
    >
      {options.map(option => (
        <ToggleButton
          className={styles.button}
          value={option}
          key={option}
        >
          {option}
        </ToggleButton>
      ))}
    </MUIToggleButtonGroup>
  );
};

export default ToggleButtonGroup;
