import React, { Suspense, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { useAtomValue } from 'jotai';
import { useMediaQuery } from '@mui/material';
import apiClient from '../../../../apiClient.ts';
import ComfortScore from '../../../ComfortScore/ComfortScore';
import Loader from '../../../Loader/Loader';
import { ProjectPermissions } from '../../Login/user.props.ts';
import { NewProjectContext, Redirect } from '../types.ts';
import DeliverablesList from './DeliverablesList/DeliverablesList';
import styles from './NewProjectDeliverables.module.scss';

import {
  Deliverable, DeliverableStatus, Domain, ScoreResponse, Scores,
} from './types';
import ScoreItem from '../Score/Score.tsx';
import Drawer from '../../../UIKit/Drawer/Drawer.tsx';
import { projectEditAccess } from '../../../../store/project.ts';
import { useCustomTranslation } from '../../../../useAppTranslate.tsx';

const getProjectDeliverables = async (projectId: number) => {
  try {
    const { response } = await apiClient.get<{
      data: Deliverable[]
    }>(`projects/${projectId}/deliverables?page=all&with=scores,task.domain&all=true`);
    return response;
  } catch (e) {
    console.error(e);
  }
};

const NewProjectDeliverables = () => {
  const { t, i18n } = useCustomTranslation();
  const { projectAtom, pollingSettings } = useOutletContext<NewProjectContext>();
  const project = useAtomValue(projectAtom);
  const navigate = useNavigate();
  const [deliverables, setDeliverables] = useState<Deliverable[] | null>(null);
  const [isDeliverablesLoading, setIsDeliverablesLoading] = useState<boolean>(false);
  const [isScoreModalOpened, setScoreModalOpened] = useState<boolean>(false);
  const [bulkUpdateWorking, setBulkUpdateWorking] = useState(false);

  const isMobileDevice = useMediaQuery('(max-width: 767px)');

  const getDeliverables = async () => {
    setIsDeliverablesLoading(true);
    const { data: deliverablesList } = await getProjectDeliverables(project.id) ?? {};
    deliverablesList && setDeliverables(deliverablesList);
    setIsDeliverablesLoading(false);
  };
  useEffect(() => {
    getDeliverables();
  }, [i18n.language, pollingSettings?.isTriggered]);

  const {
    data: domains,
  } = useSWR([`projects/${project.id}/domains?page=all`, i18n.language, pollingSettings?.isTriggered], ([url]) => apiClient
    .get<{ data: Domain[] }>(url).then(({ response }) => response.data), {
    keepPreviousData: true,
    revalidateOnFocus: false,
  });

  const {
    data: scoresData, isLoading: isScoresDataLoading,
  } = useSWR([`projects/${project.id}/scores?page=all`, i18n.language, pollingSettings?.isTriggered], ([url]) => apiClient
    .get<{ data: ScoreResponse[] }>(url).then(({ response }) => response.data), {
    keepPreviousData: false,
    revalidateOnFocus: false,
  });

  const modifiedScoresData: Scores | undefined = scoresData && scoresData.reduce((result: Scores, score: ScoreResponse) => {
    result[String(score.id)] = score;
    return result;
  }, {});

  useEffect(() => {
    if (!project) return;

    const { permissions } = project;
    const hasRequiredPermissions = permissions.includes(ProjectPermissions.UPDATE)
      || permissions.includes(ProjectPermissions.VIEW);
    if (!hasRequiredPermissions) {
      return navigate(Redirect.MANAGE);
    }
  }, [project]);

  const [scores, setScores] = useState<Scores>({});
  const [comfortScore, setComfortScore] = useState(0);

  useEffect(() => {
    if (Object.keys(scores).length > 0) {
      const { totalSelected, totalValues } = Object.values(scores).reduce((acc, curr) => {
        if (curr.values) {
          acc.totalSelected += (curr.values.selected * curr.values.scale);
          acc.totalValues += curr.values.total;
        }
        return acc;
      }, { totalSelected: 0, totalValues: 0 });
      const ratio = (totalSelected / totalValues) * 100;
      setComfortScore(ratio >= 99.5 ? Math.floor(ratio) : Math.round(ratio));
    }
  }, [scores]);

  useEffect(() => {
    if (deliverables && Object.keys(modifiedScoresData ?? {})?.length === 0) {
      const initialScores: Scores = deliverables.reduce((acc: Scores, deliverable) => {
        deliverable.scores.forEach(score => {
          const scoreId = score.id;
          const scoreCaption = score.caption;
          const scoreValue = score.value;
          const scoreIcon = score.icon;

          if (!acc[scoreId]) {
            acc[scoreId] = {
              id: scoreId,
              caption: scoreCaption,
              icon: scoreIcon,
              values: { total: 0, selected: 0, scale: 1 },
            };
          }
          acc[scoreId].values.total += scoreValue;

          if (deliverable.status.value === DeliverableStatus.EXTERNAL || deliverable.status.value === DeliverableStatus.INTERNAL) {
            acc[scoreId].values.selected += scoreValue;
          }
        });
        return acc;
      }, {});
      !isDeliverablesLoading && !isScoresDataLoading && setScores(initialScores);
    } else {
      setScores(modifiedScoresData ?? {});
    }
  }, [deliverables, scoresData, isDeliverablesLoading, isScoresDataLoading]);

  const updateScores = (deliverable: Deliverable, increase: boolean) => {
    deliverable.scores.forEach(deliverableScore => {
      setScores(prev => ({
        ...prev,
        [deliverableScore.id]: {
          ...prev[deliverableScore.id],
          values: {
            ...prev[deliverableScore.id].values,
            selected: (prev[deliverableScore.id].values?.selected ?? 0) + (
              increase
                ? deliverableScore.value
                : -deliverableScore.value
            ),
          },
        },
      }));
    });
  };

  const renderScore = () => (
    <section className={classNames(styles.scores, {
      [styles.loading]: bulkUpdateWorking,
    })}
    >
      <div className={classNames(styles.section, styles.scores_top)}>
        <ComfortScore value={comfortScore} />
        {scores && Object.values(scores).slice(0, 3).map(item => (
          <ScoreItem
            value={item.values?.total ? ((item.values.selected / item.values.total) * 100) * (item?.values?.scale ?? 1) : 0}
            title={item.caption}
            icon={item.icon}
            key={item.id}
            className={styles.scoreItem}
          />
        ))}
      </div>
      <div className={classNames(styles.section, styles.bottomScores)}>
        {scores && Object.values(scores).slice(3).map(item => (
          <ScoreItem
            value={item.values?.total ? ((item.values.selected / item.values.total) * 100) * (item?.values?.scale ?? 1) : 0}
            title={item.caption}
            icon={item.icon}
            key={item.id}
            className={styles.scoreItem}
          />
        ))}
      </div>
    </section>
  );

  const storedProjectEditAccess = useAtomValue(projectEditAccess);

  const isAbleToEdit = project?.permissions?.includes(ProjectPermissions.UPDATE);

  const params = useParams();
  let isReadOnly: boolean;
  if (params?.projectId) {
    isReadOnly = (!isAbleToEdit && project?.permissions?.includes(ProjectPermissions.VIEW))
    || !storedProjectEditAccess?.isEditingAvailableShowOnUI || project?.schedule_edited;
  } else {
    isReadOnly = (!isAbleToEdit && project?.permissions?.includes(ProjectPermissions.VIEW));
  }

  return (
    <div className={styles.deliverables}>
      <section className={styles.selectDeliverables}>
        {deliverables && domains && (
          <Suspense fallback={(
            <div className={styles.loader}>
              <Loader />
            </div>
          )}
          >
            <DeliverablesList
              data={deliverables}
              domains={domains}
              scores={scores}
              updateScores={updateScores}
              isReadOnly={isReadOnly}
              bulkUpdateWorking={bulkUpdateWorking}
              setBulkUpdateWorking={setBulkUpdateWorking}
              openScoreModal={() => setScoreModalOpened(true)}
            />
          </Suspense>
        )}
      </section>
      {isMobileDevice ? (
        <Drawer
          isOpen={isScoreModalOpened}
          setIsOpen={setScoreModalOpened}
          title={t('Summary Scores')}
          className={styles.deliverables__scoreModal}
        >
          {renderScore()}
        </Drawer>
      ) : renderScore()}
    </div>
  );
};

export default NewProjectDeliverables;
