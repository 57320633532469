import React from 'react';
import useSWR from 'swr';

import Loader from '../../../Loader/Loader';

import apiClient from '../../../../apiClient';

import styles from './Voting.module.scss';

const SelfVotingQR = ({ projectId, gateId }: { gateId: string | undefined, projectId: string | undefined }) => {
  const { data: qrCode, isLoading } = useSWR(
    [`projects/${projectId}/quality-gates/${gateId}/self-voting-qr-code`],
    async ([url]) => apiClient.get<{ qr_code: string | TrustedHTML }>(url).then(({ response }) => response.qr_code),
    {
      keepPreviousData: false,
      revalidateOnFocus: false,
      revalidateOnMount: true,
    },
  );

  return (
    <section className={styles.selfVoting__qrSection}>
      {!qrCode || isLoading ? (
        <Loader />
      ) : (
        <div
          className={styles.selfVoting__code}
          dangerouslySetInnerHTML={{ __html: qrCode }}
        />
      )}
    </section>
  );
};

export default SelfVotingQR;
