import React from 'react';
import { stringToColor } from '../../utils/string-to-color';
import AvatarColoredSVG from '../../public/media/avatar-colored.svg';

type ColoredAvatarProps = {
  name: string;
  className: string;
};
const ColoredAvatar = ({ name, className }: ColoredAvatarProps) => (
  <svg
    className={className}
    style={{ color: stringToColor(name) }}
  >
    <use
      xlinkHref={`${AvatarColoredSVG}#avatarColoredSVG`}
      href={`${AvatarColoredSVG}#avatarColoredSVG`}
    />
  </svg>
);

export default ColoredAvatar;
