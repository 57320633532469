import React from 'react';
import { CircularProgressbarWithChildren as CircleProgressBar } from 'react-circular-progressbar';
import classnames from 'classnames';
import styles from './GradientProgressBar.module.scss';

type GradientProgressBarProps = {
  value: number,
  strokeWidth: number,
  className?: string;
  children?: React.ReactNode;
};

const GradientProgressBar = ({
  children, value, strokeWidth, className,
}: GradientProgressBarProps) => {
  const primary100 = getComputedStyle(document.documentElement).getPropertyValue('--primary-100');
  const primary300 = getComputedStyle(document.documentElement).getPropertyValue('--primary-300');
  const primary500 = getComputedStyle(document.documentElement).getPropertyValue('--primary-500');

  return (
    <div className={classnames(styles.progress, className)}>
      <svg
        style={{ height: 0, width: 0 }}
      >
        <defs>
          <linearGradient
            id='Gradient1'
            gradientTransform='rotate(90)'
          >
            <stop
              offset='0%'
              stopColor={primary500}
            />
            <stop
              offset='80%'
              stopColor={primary300}
            />
            <stop
              offset='100%'
              stopColor={primary300}
            />
          </linearGradient>
          <linearGradient
            id='Gradient2'
            gradientTransform='rotate(90)'
          >
            <stop
              offset='0%'
              stopColor={primary100}
            />
            <stop
              offset='80%'
              stopColor={primary300}
            />
            <stop
              offset='100%'
              stopColor={primary300}
            />
          </linearGradient>
          <pattern
            id='Pattern'
            x='0'
            y='0'
            width='100'
            height='100'
            patternUnits='userSpaceOnUse'
          >
            <g>
              <rect
                shapeRendering='crispEdges'
                x='0'
                y='0'
                width='50'
                height='100'
                fill='url(#Gradient1)'
              />
              <rect
                shapeRendering='crispEdges'
                x='50'
                y='0'
                width='50'
                height='100'
                fill='url(#Gradient2)'
              />
            </g>
          </pattern>
        </defs>
      </svg>
      <CircleProgressBar
        styles={{
          path: {
            stroke: 'url(#Pattern)',
            height: '100%',
            strokeLinecap: 'butt',
          },
        }}
        value={value}
        strokeWidth={strokeWidth}
      >
        {children}
      </CircleProgressBar>
    </div>
  );
};

export default GradientProgressBar;
