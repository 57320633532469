import classNames from 'classnames';

import { ReactNode, forwardRef } from 'react';
import Button, { ButtonSize, ButtonVariants } from '../Button/Button';

import EmergencySVG from '../../../public/media/emergency.svg';

import styles from './ConfirmWithWarning.module.scss';

type ConfirmWithWarningProps = {
  onClose?: () => void;
  t: (key: string) => string;
  children: string | ReactNode;
  className?: string;
};

const ConfirmWithWarning = forwardRef<HTMLDivElement, ConfirmWithWarningProps>(({
  onClose, t, children, className,
}, ref) => (
  <section
    className={classNames(styles.warning, className)}
    ref={ref}
  >
    <svg className={styles.warning__icon}>
      <use
        xlinkHref={`${EmergencySVG}#emergencySVG`}
        href={`${EmergencySVG}#emergencySVG`}
      />
    </svg>
    <p className={styles.warning__text}>
      {children}
    </p>
    {!!onClose && (
    <Button
      variant={ButtonVariants.OUTLINED}
      onClick={onClose}
      className={styles.warning__button}
      size={ButtonSize.SMALL}
    >
      {t('I understand')}
    </Button>
    )}
  </section>
));

export default ConfirmWithWarning;
