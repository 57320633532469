import React, { Suspense, lazy } from 'react';

import Loader from '../../../../Loader/Loader';

import styles from './Schedule.module.scss';

const ScheduleTableContent = lazy(() => import('./ScheduleTableContent'));

const ScheduleTable = () => (
  <Suspense fallback={<Loader className={styles.table__loader} />}>
    <ScheduleTableContent />
  </Suspense>
);

export default ScheduleTable;
