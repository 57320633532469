import { Params, redirect } from 'react-router-dom';
import { checkUserPermission } from './check-permissions.loader';
import { Permissions } from '../components/pages/Login/user.props';
import { Redirect } from '../components/pages/NewProject/types';

export const checkViewLoader = async (params?: Params<string>, neededPermission?: Permissions, path?: string) => {
  const clientId = params?.clientId ?? params?.id;
  const hasPermission = neededPermission ? await checkUserPermission(neededPermission, clientId) : true;
  if (!hasPermission) {
    return redirect('/access-denied');
  }
  const isAdmin = await checkUserPermission(Permissions.ADMIN, clientId);
  if (isAdmin) {
    return redirect(`${Redirect.MANAGE}/${path}`);
  }

  return null;
};
