import {
  FormControl, InputLabel, MenuItem, MenuProps, Select as MuiSelect, SelectChangeEvent,
} from '@mui/material';
import React from 'react';
import classNames from 'classnames';
import { ReactComponent as AngleDownSVG } from '../../../public/media/angle-down.svg';
import styles from './Select.module.scss';

type SelectProps = {
  value: string,
  name: string,
  setValue: (e: SelectChangeEvent | string[]) => void,
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined,
  labelId: string,
  error?: boolean,
  errorMessage?: string,
  disabled?: boolean,
  className?: string,
  tileClassName?: string,
  paperClassName?: string,
  multiple?: boolean,
  type?: 'default' | 'tile',
  menuProps?: Partial<MenuProps>,
  renderValue?: (value: string | string[]) => React.ReactNode,
  options?: Array<{ caption: string, value: string, disabled?: boolean }>,
  children?: React.ReactNode,
} & ({
  label?: string,
} | {
  placeholder?: string,
});
const Select = ({
  options,
  value,
  setValue,
  onBlur,
  labelId,
  error,
  errorMessage,
  name,
  disabled,
  className,
  paperClassName,
  tileClassName,
  multiple,
  type = 'default',
  renderValue,
  children,
  menuProps,
  ...props
}: SelectProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    const { target } = event;

    if (multiple) {
      setValue?.(Array.isArray(target.value) ? target.value : target.value.split(','));
    } else {
      setValue?.(event);
    }
  };

  const label = 'label' in props ? props.label : undefined;
  const placeholder = 'placeholder' in props ? props.placeholder : undefined;

  return (
    <FormControl
      className={classNames(className, {
        'field-error': error,
      })}
      classes={{
        root: classNames(styles.root, {
          [styles.tile]: type === 'tile',
          [tileClassName ?? '']: type === 'tile',
          [styles.withPlaceholder]: placeholder && !label,
        }),
      }}
    >
      <InputLabel
        className={styles.label}
        id={labelId}
      >
        {label || placeholder}
      </InputLabel>
      <MuiSelect
        classes={{
          select: styles.select,

        }}
        IconComponent={
        AngleDownSVG
      }
        labelId={labelId}
        value={value}
        label={label || placeholder}
        error={error}
        name={name}
        disabled={disabled}
        onChange={handleChange}
        onBlur={onBlur}
        MenuProps={{
          PopoverClasses: {
            paper: classNames(paperClassName, styles.paperRoot, {
              [styles.tile__paper]: type === 'tile',
            }),
          },
          ...menuProps,
        }}
        multiple={multiple}
        renderValue={renderValue}
        placeholder={placeholder}
      >
        {children || options?.map(option => (
          <MenuItem
            key={option.caption}
            value={option.value}
            disabled={option?.disabled}
          >
            {option.caption}
          </MenuItem>
        ))}
      </MuiSelect>
      {(errorMessage && error) && <p className={styles.error}>{errorMessage}</p>}
    </FormControl>
  );
};

export default Select;
