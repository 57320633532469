import { useFormik } from 'formik';
import { useEffect, useState } from 'react';

import { useAtomValue } from 'jotai';
import * as Yup from 'yup';
import useSWR from 'swr';
import Select from '../UIKit/Select/Select';
import styles from './UserForm.module.scss';
import apiClient from '../../apiClient.ts';
import Loader from '../Loader/Loader';
import Input from '../UIKit/Input/Input';
import Button, { ButtonVariants } from '../UIKit/Button/Button';
import ToggleSwitch from '../UIKit/Switch/Switch.tsx';
import Drawer from '../UIKit/Drawer/Drawer.tsx';
import TwoFactorAuth from '../TwoFactorAuth/TwoFactorAuth.tsx';
import { NotificationStatus, notify } from '../../store/notifications.ts';

import { languagesAtom } from '../../store/lang.ts';
import { LS_LANG_KEY } from '../../constants.ts';
import { ClientMemberResource } from '../pages/Users/UsersList/types.ts';
import { hasGlobalPermission, usePermissions } from '../../usePermission.ts';
import { Permissions } from '../pages/Login/user.props.ts';
import type { Questions } from './types';
import { useCustomTranslation } from '../../useAppTranslate.tsx';

import PlusSVG from '../../public/media/plus.svg';
import InfoSVG from '../../public/media/info.svg';

type UserRole = {
  id: number;
  caption: string;
  description: string;
  scope?: string | null;
};

enum QuestionName {
  SALUTATION = 'salutation',
  DATE_FORMAT = 'dateformat',
  NUMBER_FORMAT = 'numberformat',
}

type UserFormProps = {
  user?: ClientMemberResource | null,
  submitForm: (values: Record<string, any>) => void,
  submitButtonText: string,
  editProfile?: boolean,
  outerErrors?: Record<string, string>,
  userHasPassword?: boolean,
  isSubmitting?: boolean,
  accountSettingsPage?: boolean
};

const swrConfig = {
  keepPreviousData: true,
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

const defaultEditRoleValue = '0';

const UserForm = ({
  user, submitForm, submitButtonText, editProfile = false, outerErrors, userHasPassword, isSubmitting, accountSettingsPage,
}: UserFormProps) => {
  const { t, i18n } = useCustomTranslation('userForm');
  const languages = useAtomValue(languagesAtom);
  const [is2FADrawerOpen, setIs2FADrawerOpen] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState('');

  const { hasPermission } = usePermissions();

  const [displayRole, setDisplayRole] = useState<UserRole | null>(null);

  const { data: questions, isLoading: areQuestionsLoading } = useSWR(['questions/user', i18n.language], ([url]) => apiClient
    .get<{ data: Questions[] }>(url).then(({ response }) => response.data), swrConfig);

  const { data: roles, isLoading: areRolesLoading } = useSWR(
    !accountSettingsPage ? ['roles/client', i18n.language, hasGlobalPermission(Permissions.ADMIN)] : null,
    ([url]) => apiClient.get<{ data: UserRole[] }>(url)
      .then(({ response }) => response.data),
    swrConfig,
  );

  const salutations = questions?.find(question => question.name === QuestionName.SALUTATION);
  const dateFormats = questions?.find(question => question.name === QuestionName.DATE_FORMAT);
  const numberFormats = questions?.find(question => question.name === QuestionName.NUMBER_FORMAT);

  const currentLanguage = localStorage.getItem(LS_LANG_KEY);

  const {
    handleSubmit, values, handleChange, handleBlur, touched, errors, setValues, setFieldValue, setErrors,
  } = useFormik({
    initialValues: {
      salutation: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      company: '',
      department: '',
      role: '',
      dateFormat: '',
      numberFormat: '',
      systemLanguage: currentLanguage ?? '',
      platformRole: '',
    },
    validationSchema: Yup.object({
      salutation: Yup.string(),
      firstName: Yup.string().trim().required(t('First name is required')).max(100, t('Must be 100 characters at most')),
      lastName: Yup.string().trim().required(t('Last name is required')).max(100, t('Must be 100 characters at most')),
      email: Yup.string().trim().test(
        'is-valid-email',
        t('Must be a valid email'),
        value => !!value && /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value),
      )
        .required(t('Email is required')),
      phone: Yup.string().typeError(t('Must be a valid phone number')).optional()
        .test(
          'is-valid-number',
          t('Must be a valid phone number'),
          value => (value ? /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(value) : true),
        ),
      company: Yup.string().trim().required(t('Company is required')).max(100, t('Must be 100 characters at most')),
      department: Yup.string().optional().max(100, t('Must be 100 characters at most')),
      role: Yup.string().optional().max(100, t('Must be 100 characters at most')),
      dateFormat: Yup.string().optional(),
      numberFormat: Yup.string().optional(),
      systemLanguage: Yup.string().required(t('System language is required')),
      platformRole: Yup.string().required(t('Platform role is required')),
    }),
    onSubmit: async (submitValues) => {
      const body: Record<string, any> = {
        locale: submitValues.systemLanguage,
        profile: {
          first_name: submitValues.firstName,
          last_name: submitValues.lastName,
          phone: submitValues.phone,
          company: submitValues.company,
          department: submitValues.department,
          role: submitValues.role,
        },
        answers: {
          [dateFormats!.id]: [submitValues.dateFormat],
          [numberFormats!.id]: [submitValues.numberFormat],
        },
        email: submitValues.email,
        role: displayRole ? defaultEditRoleValue : submitValues.platformRole,
      };

      if (submitValues.salutation) {
        body.answers[salutations!.id] = [submitValues.salutation];
      }

      if (accountSettingsPage) {
        delete body.role;
      }
      await submitForm(body);

      if (editProfile && currentLanguage !== submitValues.systemLanguage) {
        i18n.services.backendConnector.backend.options.customHeaders = { 'Accept-Language': submitValues.systemLanguage };
        i18n.changeLanguage(submitValues.systemLanguage);
      }
    },
  });

  useEffect(() => {
    if (!questions) return;

    const defaultDateFormat = dateFormats ? (dateFormats.answers[0].id).toString() : '';
    const defaultNumberFormat = numberFormats ? (numberFormats.answers[0].id).toString() : '';

    if (user) {
      const {
        answers = {}, profile, email, locale,
      } = user;

      const getAnswerValue = (id?: number) => {
        if (!id) return null;

        return answers[id] && Array.isArray(answers[id]) ? answers[id][0].toString() : null;
      };

      const userRole = user.roles?.[0] ?? {};
      const hasManagePermission = hasPermission(Permissions.USER_MANAGE);

      if ((userRole.scope === null && hasManagePermission) || userRole.scope !== null) {
        setDisplayRole(null);
      } else {
        setDisplayRole(userRole);
      }

      setValues({
        salutation: getAnswerValue(salutations?.id) ?? '',
        firstName: profile?.first_name ?? '',
        lastName: profile?.last_name ?? '',
        email: email ?? '',
        phone: profile?.phone ?? '',
        company: profile?.company ?? '',
        department: profile?.department ?? '',
        role: profile?.role ?? '',
        dateFormat: getAnswerValue(dateFormats?.id) ?? defaultDateFormat,
        numberFormat: getAnswerValue(numberFormats?.id) ?? defaultNumberFormat,
        systemLanguage: locale ?? '',
        platformRole: userRole.id ? (userRole.id).toString() : defaultEditRoleValue,
      });
    } else {
      setFieldValue('dateFormat', defaultDateFormat);
      setFieldValue('numberFormat', defaultNumberFormat);
    }
  }, [user, areRolesLoading, areQuestionsLoading]);

  useEffect(() => {
    if (!outerErrors) return;

    setErrors(outerErrors);
  }, [outerErrors]);

  const hasAdminPermission = hasPermission(Permissions.ADMIN);

  const [isPasswordResetPending, setIsPasswordResetPending] = useState(false);

  const resetUserPassword = async () => {
    setIsPasswordResetPending(true);
    try {
      const { statusCode, response } = await apiClient.post<{ data: string, message?: string }>('user/forgot-password', {
        body: JSON.stringify({ email: values.email }),
      });

      if (statusCode === 200) {
        notify({
          status: NotificationStatus.SUCCESS,
          text: { title: t('Success!'), body: t(response.message || 'A password reset link has been sent to the user\'s email.') },
        });
      } else {
        throw new Error(response.message ?? 'An error occurred while resetting the password.');
      }
    } catch (e) {
      notify({ status: NotificationStatus.ERROR, text: { body: t(e.message) } });
      console.error(e);
    } finally {
      setIsPasswordResetPending(false);
    }
  };

  return (areRolesLoading || areQuestionsLoading) ? (
    <div className={styles.loader}>
      <Loader size={32} />
    </div>
  ) : (
    <>
      <form
        className={styles.form}
        onSubmit={handleSubmit}
      >
        <div className={styles.form__block}>
          <Select
            label={t('Salutation')}
            options={salutations?.answers
              ? salutations.answers.map(salutation => ({ caption: salutation.caption, value: salutation.id.toString() })) : []}
            value={values.salutation}
            setValue={handleChange}
            onBlur={handleBlur}
            labelId='salutation'
            name='salutation'
            error={!!(touched.salutation && errors.salutation)}
            errorMessage={errors.salutation}
          />
          <Input
            value={values.firstName}
            setValue={handleChange}
            onBlur={handleBlur}
            id='first-name'
            label={t('First name')}
            name='firstName'
            error={!!(touched.firstName && errors.firstName)}
            errorMessage={errors.firstName}
          />
          <Input
            value={values.lastName}
            setValue={handleChange}
            onBlur={handleBlur}
            id='last-name'
            label={t('Last name')}
            name='lastName'
            error={!!(touched.lastName && errors.lastName)}
            errorMessage={errors.lastName}
          />
          <Input
            value={values.email}
            setValue={handleChange}
            onBlur={handleBlur}
            id='email'
            label={t('Email')}
            name='email'
            error={!!(touched.email && errors.email)}
            errorMessage={errors.email}
          />
          <Input
            value={values.phone}
            setValue={e => {
              const isValidInput = /^[+]?[0-9]*$/.test(e.target.value);
              isValidInput && handleChange(e);
            }}
            onBlur={handleBlur}
            id='phone'
            label={t('Mobile number')}
            name='phone'
            error={!!(touched.phone && errors.phone)}
            errorMessage={errors.phone}
          />
          <Input
            value={values.company}
            setValue={handleChange}
            onBlur={handleBlur}
            id='company'
            label={t('Company')}
            name='company'
            error={!!(touched.company && errors.company)}
            errorMessage={errors.company}
          />
          <Input
            value={values.department}
            setValue={handleChange}
            onBlur={handleBlur}
            id='department'
            label={t('Department')}
            name='department'
            error={!!(touched.department && errors.department)}
            errorMessage={errors.department}
          />
          <Input
            value={values.role}
            setValue={handleChange}
            onBlur={handleBlur}
            id='role'
            label={t('Role')}
            name='role'
            error={!!(touched.role && errors.role)}
            errorMessage={errors.role}
          />
        </div>
        <h4 className={styles.form__subtitle}>{t('Display')}</h4>
        <div className={styles.form__block}>
          <Select
            label={t('Date format')}
            options={dateFormats?.answers
              ? dateFormats.answers.map(format => ({ caption: format.caption, value: format.id.toString() })) : []}
            value={values.dateFormat}
            setValue={handleChange}
            onBlur={handleBlur}
            labelId='dateFormat'
            name='dateFormat'
            error={!!(touched.dateFormat && errors.dateFormat)}
            errorMessage={errors.dateFormat}
          />
          <Select
            label={t('Number format')}
            options={numberFormats?.answers
              ? numberFormats.answers.map(format => ({ caption: format.caption, value: format.id.toString() })) : []}
            value={values.numberFormat}
            setValue={handleChange}
            onBlur={handleBlur}
            labelId='numberFormat'
            name='numberFormat'
            error={!!(touched.numberFormat && errors.numberFormat)}
            errorMessage={errors.numberFormat}
          />
          <Select
            label={t('System language')}
            options={languages ? languages.map(language => ({ caption: language.caption, value: language.locale })) : []}
            value={values.systemLanguage}
            setValue={handleChange}
            onBlur={handleBlur}
            labelId='systemLanguage'
            name='systemLanguage'
            error={!!(touched.systemLanguage && errors.systemLanguage)}
            errorMessage={errors.systemLanguage}
          />
          {!accountSettingsPage && (
          <Select
            label={t('Platform role')}
            options={displayRole
              ? [{ ...displayRole, value: displayRole.id.toString() }]
              : roles?.length ? roles.map(role => ({ caption: role.caption, value: role.id.toString() })) : []}
            value={values.platformRole}
            setValue={handleChange}
            onBlur={handleBlur}
            disabled={displayRole !== null}
            labelId='platformRole'
            name='platformRole'
            error={!!(touched.platformRole && errors.platformRole)}
            errorMessage={errors.platformRole}
          />
          )}
        </div>
        {accountSettingsPage && <h4 className={styles.form__subtitle}>{t('Two-factor authentication')}</h4>}
        {accountSettingsPage && (
          !userHasPassword ? (
            <div className={styles.noPassword}>
              <div className={styles.noPassword__text}>
                <p>{t('Account password is not set')}</p>
                <p>{t('To use two-factor authentication, you will need to add a password for the account')}</p>
              </div>
              <Button
                variant={ButtonVariants.OUTLINED}
                iconSize={{ width: 16, height: 16 }}
                link='/new-password'
                className={styles.noPassword__button}
                icon={(
                  <svg>
                    <use
                      xlinkHref={`${PlusSVG}#plusSVG`}
                      href={`${PlusSVG}#plusSVG`}
                    />
                  </svg>
                )}
              >
                {t('Add password')}
              </Button>
            </div>
          ) : (
            <div className={styles.form__twoFA}>
              <div className={styles.form__twoFA__text}>
                <p>{t('Enable two-factor authentication')}</p>
                <div className={styles.form__twoFA__text_colored}>
                  <svg>
                    <use
                      xlinkHref={`${InfoSVG}#infoSVG`}
                      href={`${InfoSVG}#infoSVG`}
                    />
                  </svg>
                  <p>
                    {t('Available two-factor authentication method: Authenticator app')}
                  </p>
                </div>
              </div>
              <ToggleSwitch
                checked={user?.['2fa']}
                onClick={() => setIs2FADrawerOpen(prev => !prev)}
              />
            </div>
          )
        )}
        {!!hasAdminPermission && !accountSettingsPage && user && (
          <Button
            onClick={resetUserPassword}
            className={styles.form__resetPassword}
            variant={ButtonVariants.SECONDARY}
            disabled={isPasswordResetPending}
          >
            {t('Reset password')}
          </Button>
        )}
        <div className={styles.form__footer}>
          <Button
            type='submit'
            className={styles.form__submit}
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            {submitButtonText}
          </Button>
        </div>
      </form>
      <Drawer
        isOpen={is2FADrawerOpen}
        setIsOpen={setIs2FADrawerOpen}
        className={styles.authDrawer}
        title={drawerTitle}
      >
        <TwoFactorAuth
          is2FAEnable={!!user?.['2fa']}
          setDrawerTitle={setDrawerTitle}
          closeDrawer={() => setIs2FADrawerOpen(false)}
        />
      </Drawer>
    </>

  );
};

export default UserForm;
