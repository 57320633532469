// import Accordion from '@mui/material/Accordion';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import AccordionSummary from '@mui/material/AccordionSummary';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import apiClient from '../../apiClient.ts';
// import AngleDownSVG from '../../public/media/angle-down.svg';
import { notify } from '../../store/notifications.ts';
import Button from '../UIKit/Button/Button.tsx';
import CheckboxItem from '../UIKit/CheckboxItem/CheckboxItem.tsx';
import Modal from '../UIKit/Modal/Modal.tsx';
import styles from './DownloadTemplateModal.module.scss';
import { useCustomTranslation } from '../../useAppTranslate.tsx';

export const downloadTemplate = async (ids: number[], projectId: number) => {
  try {
    const { response } = await apiClient.post(`projects/${projectId}/deliverables/download-templates`, {
      body: JSON.stringify({ templates: ids }),
    });
    let filename = 'Templates';

    const blob = await response.blob();
    const a = document.createElement('a');
    const blobUrl = window.URL.createObjectURL(blob);
    a.href = blobUrl;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(blobUrl);
  } catch (e) {
    throw new Error(e.message);
  }
};

export type SelectedTemplate = {
  id: number;
  link: string | null;
  filename: string
};

type DownloadTemplateModalProps = {
  selectedTemplates: SelectedTemplate[];
  setSelectedTemplates: (value: SelectedTemplate[] | null) => void
};
const DownloadTemplateModal = ({
  selectedTemplates, setSelectedTemplates,
}: DownloadTemplateModalProps) => {
  const { t } = useCustomTranslation();
  const { projectId } = useParams();

  const downloadFiles = async (ids: number[]) => {
    try {
      await downloadTemplate(ids, +projectId!);
    } catch (e) {
      console.error(e.message);
      notify({ text: { body: e.message } });
    }
  };
  const [downloadedFiles, setDownloadedFiles] = useState<number[]>([]);
  const selectedTemplateFiles = selectedTemplates?.filter(template => template.link === null);
  const isAllFilesDownloaded = selectedTemplateFiles.length === downloadedFiles.length;
  useEffect(() => () => setDownloadedFiles([]), [selectedTemplates]);

  return (
    <Modal
      className={styles.downloadModal}
      closeModal={() => setSelectedTemplates(null)}
    >
      <header className={styles.downloadModal__header}>
        <h5 className={styles.downloadModal__title}>{t('Download template')}</h5>
      </header>
      {selectedTemplateFiles.length > 0 && (
        <div
          className={styles.sectionAccordion}
        >
          {/* <AccordionSummary */}
          {/*  expandIcon={( */}
          {/*    <svg> */}
          {/*      <use */}
          {/*        xlinkHref={`${AngleDownSVG}#angleDownSVG`} */}
          {/*        href={`${AngleDownSVG}#angleDownSVG`} */}
          {/*      /> */}
          {/*    </svg> */}
          {/*  )} */}
          {/*  className={styles.sectionAccordion__title} */}
          {/*  id='template-files' */}
          {/* > */}
          {/*  {t('Pick files to download')} */}
          {/* </AccordionSummary> */}
          {/* <AccordionDetails> */}
          <CheckboxItem
            value={isAllFilesDownloaded}
            indeterminate={downloadedFiles.length > 0 && !isAllFilesDownloaded}
            label={t(`${isAllFilesDownloaded ? 'Deselect' : 'Select'} all`)}
            onChange={() => {
              setDownloadedFiles(() => (
                isAllFilesDownloaded ? [] : selectedTemplateFiles.map(template => template.id)
              ));
            }}
            labelClassName={styles.downloadAllCheckbox}
          />
            {selectedTemplateFiles.map((template) => (
              <div key={template.id}>
                <CheckboxItem
                  value={downloadedFiles.includes(template.id)}
                  label={template.filename}
                  onChange={() => {
                    setDownloadedFiles(prev => (
                      prev.includes(template.id) ? prev.filter(id => id !== template.id) : [...prev, template.id]
                    ));
                  }}
                  labelClassName={styles.downloadCheckbox}
                />
              </div>
            ))}
          <Button
            onClick={() => downloadFiles(downloadedFiles)}
            disabled={downloadedFiles.length === 0}
            className={styles.downloadModal__button}
          >
            {t('Download selected files')}
          </Button>
          {/* </AccordionDetails> */}
        </div>
      )}
      {/* {selectedTemplates.filter(template => template.link !== null).length > 0 && ( */}
      {/*  <Accordion */}
      {/*    defaultExpanded */}
      {/*    className={styles.sectionAccordion} */}
      {/*  > */}
      {/*    <AccordionSummary */}
      {/*      expandIcon={( */}
      {/*        <svg> */}
      {/*          <use */}
      {/*            xlinkHref={`${AngleDownSVG}#angleDownSVG`} */}
      {/*            href={`${AngleDownSVG}#angleDownSVG`} */}
      {/*          /> */}
      {/*        </svg> */}
      {/*      )} */}
      {/*      className={styles.sectionAccordion__title} */}
      {/*      id='template-links' */}
      {/*    > */}
      {/*      {t('Attached links')} */}
      {/*    </AccordionSummary> */}
      {/*    <AccordionDetails> */}
      {/*      <div className={styles.downloadModal__links}> */}
      {/*        {selectedTemplates.filter(template => template.link !== null).map((template) => ( */}
      {/*          <a */}
      {/*            href={template.link!} */}
      {/*            className={styles.downloadModal__link} */}
      {/*            target='_blank' */}
      {/*            rel='noreferrer' */}
      {/*          > */}
      {/*            {template.link} */}
      {/*          </a> */}
      {/*        ))} */}
      {/*      </div> */}
      {/*    </AccordionDetails> */}
      {/*  </Accordion> */}
      {/* )} */}
    </Modal>
  );
};

export default DownloadTemplateModal;
