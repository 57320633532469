import '@wangeditor/editor/dist/css/style.css';
import React, { useEffect, useState } from 'react';
import { Editor, Toolbar } from '@wangeditor/editor-for-react';
import {
  i18nChangeLanguage, IDomEditor, IEditorConfig, IToolbarConfig,
} from '@wangeditor/editor';
import classNames from 'classnames';
import styles from './TextEditor.module.scss';
import { useCustomTranslation } from '../../../useAppTranslate';

i18nChangeLanguage('en');

type TextEditorProps = {
  placeholder?: string,
  value?: string,
  setValue: (html: string) => void,
  error?: boolean,
  errorMessage?: string,
  readOnly?: boolean
};

const TextEditor = ({
  placeholder, value, setValue, error, errorMessage, readOnly,
}: TextEditorProps) => {
  const { t } = useCustomTranslation('global');
  const [editor, setEditor] = useState<IDomEditor | null>(null);

  const toolbarConfig: Partial<IToolbarConfig> = {
    toolbarKeys: ['undo', 'redo', '|', 'bold', 'italic', 'underline', 'through', '|', 'bulletedList', 'numberedList', 'insertLink'],
  };

  const editorConfig: Partial<IEditorConfig> = {
    placeholder: placeholder ?? t('Enter information'),
    autoFocus: false,
    readOnly,
  };

  useEffect(() => {
    if (editor !== null && value) {
      editor.setHtml(value);
    }

    // Timely destroy editor, important!
    return () => {
      if (editor == null) return;
      editor.destroy();
      setEditor(null);
    };
  }, [editor]);

  return (
    <div>
      <div className={classNames(styles.wrapper, {
        [styles.error]: errorMessage && error,
        'field-error': error,
        [styles.readOnly]: readOnly,
      })}
      >
        <Toolbar
          editor={editor}
          defaultConfig={toolbarConfig}
          mode='default'
          className={styles.toolbar}
        />
        <Editor
          defaultConfig={editorConfig}
          value={value}
          onCreated={setEditor}
          onChange={e => {
            const text = e.getText().trim();
            setValue(!text.length ? '' : e.getHtml());
          }}
          mode='default'
          className={styles.editor}
        />
      </div>
      {(errorMessage && error) && <p className={styles.errorMessage}>{errorMessage}</p>}
    </div>
  );
};

export default TextEditor;
