import { useAtomValue } from 'jotai/index';
import { Navigate } from 'react-router-dom';
import { userAtom } from '../../../store/auth.ts';

const Home = () => {
  const user = useAtomValue(userAtom);
  const to = user ? '/' : '/login';

  return (
    <Navigate
      to={to}
      replace
    />
  );
};

export default Home;
