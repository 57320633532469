import { atom, createStore } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export type Locale = {
  caption: string,
  id: number,
  locale: string,
  is_active: number,
  is_publish: number,
  latest_update: string | null,
  icon: string,
  websocket_url: string
};

export type SimpleLocale = Pick<Locale, 'caption' | 'id' | 'icon' | 'locale'>;

export type TranslationKeys = { [ns: string]: Record<string, string> };

const missingKeysStore = createStore();
const namespacesAtom = atom<TranslationKeys>({});
missingKeysStore.set(namespacesAtom, {});

const languagesAtom = atomWithStorage<null | SimpleLocale[]>('langs', null);

export { languagesAtom, missingKeysStore, namespacesAtom };
