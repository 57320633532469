import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { setLocale } from 'yup';

import apiClient from './apiClient';
import { API_URL, LS_LANG_KEY } from './constants';
import { namespacesAtom, missingKeysStore, TranslationKeys } from './store/lang';

const DEFAULT_LOCALE = 'en';

function debounceAsync<T extends (
  ...args: any[]) => Promise<any>>(
  func: T,
  waitFor: number,
): (...args: Parameters<T>) => Promise<ReturnType<T> | undefined> {
  let timeout: NodeJS.Timeout | null = null;

  return (...args: Parameters<T>): Promise<ReturnType<T> | undefined> => new Promise((resolve, reject) => {
    if (timeout !== null) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      func(...args)
        .then(resolve)
        .catch(reject);
    }, waitFor);
  });
}

const postMissedTranslationKeys = async () => {
  try {
    await apiClient.withoutAuth().post('translations/scoped', {
      body: JSON.stringify({ values: missingKeysStore.get(namespacesAtom) }),
    });

    // Clean store after sending missed keys
    missingKeysStore.set(namespacesAtom, {});
  } catch (e) {
    console.error(e);
  }
};

const debouncedPostMissedTranslationKeys = debounceAsync(postMissedTranslationKeys, 3000);

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ['global'],
    defaultNS: 'global',
    detection: {
      caches: ['cookie', 'localStorage'],
    },
    backend: {
      loadPath: `${API_URL}/translations/scoped?scope={{ns}}`,
      customHeaders: {
        ...(localStorage.getItem(LS_LANG_KEY) ? { 'Accept-Language': localStorage.getItem(LS_LANG_KEY) } : {}),
      },
      parse: (data: string) => {
        const parsedObject = JSON.parse(data);
        return parsedObject.data;
      },
    },
    interpolation: { escapeValue: false },
    fallbackLng: false,
    saveMissing: true,
    saveMissingTo: 'current',
    missingKeyHandler: (langs, ns, key, fallbackValue) => {
      // If a translation key is absent from the specified namespace in the English dictionary,
      // we transmit the missing keys to the backend.
      if (langs[0] === DEFAULT_LOCALE) {
        const prevMissingKeys: TranslationKeys = missingKeysStore.get(namespacesAtom);
        const currentMissingKeys = { ...prevMissingKeys, [ns]: { ...prevMissingKeys?.[ns], [key]: fallbackValue } };
        missingKeysStore.set(namespacesAtom, currentMissingKeys);
        debouncedPostMissedTranslationKeys();
      }
    },
  });

i18n.loadNamespaces('validation').then(() => {
  const t = i18n.getFixedT(null, 'validation');
  const tGlobal = i18n.getFixedT(null, 'global');

  const makeFirstLetterUppercase = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
  const trans = (key:string, defaultValue:string) => (params:{ path: string }) => t(
    key,
    defaultValue,
    { ...params, attribute: tGlobal(makeFirstLetterUppercase(params.path)) },
  );

  setLocale({
    mixed: {
      required: trans('required', '{{attribute}} is required'),
    },
    string: {
      max: trans('max.string', '{{attribute}} must be at most {{max}} characters'),
      min: trans('min.string', '{{attribute}} must be at least {{min}} characters'),
    },
  });
});

export default i18n;
