import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import apiClient from '../apiClient.ts';
import { Permissions } from '../components/pages/Login/user.props.ts';
import { ProjectResource } from '../components/pages/Projects/types.ts';
import { checkPermissionsLoader } from './check-permissions.loader.ts';

export const projectTrackingLoader = async ({ params }: LoaderFunctionArgs<Record<string, string>>) => {
  try {
    const checkResult = await checkPermissionsLoader(params, Permissions.PROJECT_TRACKING);

    if (!checkResult) {
      const { response } = await apiClient.get<{ data: ProjectResource }>(
        `clients/${params.clientId}/projects/${params.projectId}?with=jiraIntegration`,
      );
      return response?.data ? response : redirect('/not-found');
    } else {
      return checkResult;
    }
  } catch (e) {
    console.error(e.message);
  }
};
