import classnames from 'classnames';
import styles from './Loader.module.scss';

const Loader = ({ className, size = 48 }: { className?: string, size?: number }) => (
  <div
    style={{ width: size, height: size }}
    className={classnames(className, styles.loader)}
  />
);

export default Loader;
