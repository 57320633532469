export function arrayAt<T>(arr: T[], index: number): T | undefined {
  // Create a new variable for the adjusted index
  let adjustedIndex = index;

  // Handle negative indices
  if (adjustedIndex < 0) {
    adjustedIndex = arr.length + adjustedIndex;
  }

  // Return undefined if index is out of bounds
  if (adjustedIndex < 0 || adjustedIndex >= arr.length) {
    return undefined;
  }

  return arr[adjustedIndex];
}
