import classNames from 'classnames';
import styles from './ColorPalette.module.scss';

const ColorPalette = () => (
  <div className={styles.palette}>
    <div className={styles.color}>
      <p className={styles.color__name}>primary-50</p>
      <div
        className={classNames(styles.color__value, styles.primary50)}
      />
    </div>
    <div className={styles.color}>
      <p className={styles.color__name}>primary-100</p>
      <div
        className={classNames(styles.color__value, styles.primary100)}
      />
    </div>
    <div className={styles.color}>
      <p className={styles.color__name}>primary-200</p>
      <div
        className={classNames(styles.color__value, styles.primary200)}
      />
    </div>
    <div className={styles.color}>
      <p className={styles.color__name}>primary-300</p>
      <div
        className={classNames(styles.color__value, styles.primary300)}
      />
    </div>
    <div className={styles.color}>
      <p className={styles.color__name}>primary-400</p>
      <div
        className={classNames(styles.color__value, styles.primary400)}
      />
    </div>
    <div className={styles.color}>
      <p className={styles.color__name}>primary-500</p>
      <div
        className={classNames(styles.color__value, styles.primary500)}
      />
    </div>
    <div className={styles.color}>
      <p className={styles.color__name}>primary-600</p>
      <div
        className={classNames(styles.color__value, styles.primary600)}
      />
    </div>
    <div className={styles.color}>
      <p className={styles.color__name}>primary-700</p>
      <div
        className={classNames(styles.color__value, styles.primary700)}
      />
    </div>
    <div className={styles.color}>
      <p className={styles.color__name}>primary-800</p>
      <div
        className={classNames(styles.color__value, styles.primary800)}
      />
    </div>
    <div className={styles.color}>
      <p className={styles.color__name}>primary-900</p>
      <div
        className={classNames(styles.color__value, styles.primary900)}
      />
    </div>
    <div className={styles.color}>
      <p className={styles.color__name}>secondary-50</p>
      <div
        className={classNames(styles.color__value, styles.secondary50)}
      />
    </div>
    <div className={styles.color}>
      <p className={styles.color__name}>secondary-100</p>
      <div
        className={classNames(styles.color__value, styles.secondary100)}
      />
    </div>
    <div className={styles.color}>
      <p className={styles.color__name}>secondary-200</p>
      <div
        className={classNames(styles.color__value, styles.secondary200)}
      />
    </div>
    <div className={styles.color}>
      <p className={styles.color__name}>secondary-300</p>
      <div
        className={classNames(styles.color__value, styles.secondary300)}
      />
    </div>
    <div className={styles.color}>
      <p className={styles.color__name}>secondary-400</p>
      <div
        className={classNames(styles.color__value, styles.secondary400)}
      />
    </div>
    <div className={styles.color}>
      <p className={styles.color__name}>secondary-500</p>
      <div
        className={classNames(styles.color__value, styles.secondary500)}
      />
    </div>
    <div className={styles.color}>
      <p className={styles.color__name}>secondary-600</p>
      <div
        className={classNames(styles.color__value, styles.secondary600)}
      />
    </div>
    <div className={styles.color}>
      <p className={styles.color__name}>secondary-700</p>
      <div
        className={classNames(styles.color__value, styles.secondary700)}
      />
    </div>
    <div className={styles.color}>
      <p className={styles.color__name}>secondary-800</p>
      <div
        className={classNames(styles.color__value, styles.secondary800)}
      />
    </div>
    <div className={styles.color}>
      <p className={styles.color__name}>secondary-900</p>
      <div
        className={classNames(styles.color__value, styles.secondary900)}
      />
    </div>
    <div className={styles.color}>
      <p className={styles.color__name}>dark</p>
      <div
        className={classNames(styles.color__value, styles.dark)}
      />
    </div>
    <div className={styles.color}>
      <p className={styles.color__name}>grey</p>
      <div
        className={classNames(styles.color__value, styles.grey)}
      />
    </div>
    <div className={styles.color}>
      <p className={styles.color__name}>white</p>
      <div
        className={classNames(styles.color__value, styles.white)}
      />
    </div>
    <div className={styles.color}>
      <p className={styles.color__name}>red</p>
      <div
        className={classNames(styles.color__value, styles.red)}
      />
    </div>
    <div className={styles.color}>
      <p className={styles.color__name}>green</p>
      <div
        className={classNames(styles.color__value, styles.green)}
      />
    </div>
    <div className={styles.color}>
      <p className={styles.color__name}>blue</p>
      <div
        className={classNames(styles.color__value, styles.blue)}
      />
    </div>
    <div className={styles.color}>
      <p className={styles.color__name}>orange</p>
      <div
        className={classNames(styles.color__value, styles.orange)}
      />
    </div>
    <div className={styles.color}>
      <p className={styles.color__name}>bg main</p>
      <div
        className={classNames(styles.color__value, styles.bgMain)}
      />
    </div>
  </div>
);

export default ColorPalette;
