import { useCallback, useMemo, useState } from 'react';
import { TFunction } from 'i18next';
import { ClientMemberResource, RoleResource } from '../Users/UsersList/types';
import styles from './ProjectTeamManagment.module.scss';

import DeleteSVG from '../../../public/media/delete-icon.svg';
import EditSVG from '../../../public/media/edit-icon.svg';
import KeySVG from '../../../public/media/key.svg';
import ViewSVG from '../../../public/media/view.svg';

import Button, { ButtonVariants } from '../../UIKit/Button/Button';
import Loader from '../../Loader/Loader';

type EditMemberAccessFormProps = {
  onChangeRole: (memberId: number, newRole: RoleResource) => Promise<void>,
  onDeleteAccess: (member: { id: number; name: string }) => void,
  member: ClientMemberResource | undefined;
  t: TFunction<string, undefined>;
  roles: RoleResource[];
};

const icons: Record<string, { icon: string, id: string }> = {
  edit: {
    icon: EditSVG,
    id: 'editSVG',
  },
  view: {
    icon: ViewSVG,
    id: 'viewSVG',
  },
  key: {
    icon: KeySVG,
    id: 'keySVG',
  },
};

const EditMemberAccessForm = ({
  onChangeRole, t, roles, member, onDeleteAccess,
}: EditMemberAccessFormProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const memberRole = useMemo<RoleResource | undefined>(
    () => member?.roles.find(role => role.scope === 'Project'),
    [member?.id, member?.roles],
  );

  const changeRoleHandler = (role: RoleResource) => (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (member && role && e.currentTarget.checked) {
      setLoading(true);
      onChangeRole(member?.id, role).finally(() => {
        setLoading(false);
      });
    }
  };

  const deleteHandler = useCallback(() => {
    if (member) {
      onDeleteAccess(member);
    }
  }, [member]);

  return (
    <section className={styles.editAccessModal}>
      <ul className={styles.rolesList}>
        {roles.map(role => (
          <li>
            <label
              htmlFor={`member-role-${role.id}`}
              className={styles.role}
            >
              <svg className={styles.role__icon}>
                <use
                  xlinkHref={`${role?.icon ? icons[role?.icon].icon : EditSVG}#${role?.icon ? icons?.[role?.icon]?.id : 'editSVG'}`}
                  href={`${role?.icon ? icons[role?.icon].icon : EditSVG}#${role?.icon ? icons?.[role?.icon]?.id : 'editSVG'}`}
                />
              </svg>
              <p className={styles.role__caption}>{role.caption}</p>
              <span className={styles.role__selectIndicator}>
                {memberRole?.id === role.id && (
                <span className={styles.role__selectIndicator__active} />
                )}
              </span>
              <input
                checked={memberRole?.id === role.id}
                type='checkbox'
                name='member-role'
                id={`member-role-${role.id}`}
                onChange={changeRoleHandler(role)}
                hidden
              />
            </label>
          </li>
        ))}
      </ul>
      <Button
        variant={ButtonVariants.SECONDARY}
        icon={(
          <svg>
            <use
              xlinkHref={`${DeleteSVG}#deleteSVG`}
              href={`${DeleteSVG}#deleteSVG`}
            />
          </svg>
              )}
        iconSize={{ width: 20, height: 20 }}
        className={styles.deleteButton}
        onClick={deleteHandler}
      >
        {t('Delete access')}
      </Button>
      {loading && (
        <div className={styles.editAccessModal__loader}>
          <Loader />
        </div>
      )}
    </section>
  );
};

export default EditMemberAccessForm;
