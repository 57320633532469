import { useEffect, useState } from 'react';
import classnames from 'classnames';
import InvalidSVG from '../../../../public/media/close.svg';
import ValidSVG from '../../../../public/media/checked.svg';
import styles from './PasswordStrength.module.scss';
import { evaluatePasswordStrength } from '../../../../utils';
import { useCustomTranslation } from '../../../../useAppTranslate';

type Rule = {
  title: string,
  isValid: boolean,
  name: string
};

const PasswordStrength = ({ password, rules }: { password: string, rules: Rule[] }) => {
  const { t } = useCustomTranslation();
  const [strength, setStrength] = useState<string>('');

  useEffect(() => {
    setStrength(password ? evaluatePasswordStrength(password) : '');
  }, [password]);

  return (
    <div className={styles.passwordStrength}>
      <div className={styles.strength}>
        <div className={classnames(styles.strength__lines, styles[strength])}>
          <div aria-hidden='true' />
          <div aria-hidden='true' />
          <div aria-hidden='true' />
          <div aria-hidden='true' />
        </div>
        <div className={styles.strength__info}>
          <span className={styles.strength__title}>{t('Password strength')}</span>
          <span className={classnames(styles.strength__status, styles[strength])}>{strength}</span>
        </div>
      </div>
      <div className={styles.rules}>
        {rules.map(({ title, isValid, name }) => (
          <p
            className={classnames(styles.rules__rule, {
              [styles.rules__valid]: isValid,
            })}
            key={name}
          >
            {isValid ? (
              <svg>
                <use
                  xlinkHref={`${ValidSVG}#checkedSVG`}
                  href={`${ValidSVG}#checkedSVG`}
                />
              </svg>
            ) : (
              <svg>
                <use
                  xlinkHref={`${InvalidSVG}#closeSVG`}
                  href={`${InvalidSVG}#closeSVG`}
                />
              </svg>
            ) }
            {t(title)}
          </p>
        ))}
      </div>
    </div>
  );
};

export default PasswordStrength;
