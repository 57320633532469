import { useState } from 'react';
import { useSetAtom } from 'jotai';
import Drawer from '../../UIKit/Drawer/Drawer.tsx';
import Button, { ButtonVariants } from '../../UIKit/Button/Button.tsx';
import { ReactComponent as PlusSVG } from '../../../public/media/plus.svg';

import styles from './AdminSidebarFooter.module.scss';
import apiClient from '../../../apiClient.ts';
import { ClientProps } from '../client.props.ts';
import { clientsAtom } from '../../../store/clients.ts';
import ClientForm from '../../ClientForm/ClientForm.tsx';
import { notify } from '../../../store/notifications.ts';
import { useCustomTranslation } from '../../../useAppTranslate.tsx';

const AdminSidebarFooter = () => {
  const { t } = useCustomTranslation('global');
  const setAllClients = useSetAtom(clientsAtom);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const fetchClients = async () => {
    const { response: { data } } = await apiClient.get< { data: ClientProps[] } >('clients');
    return data;
  };

  const addClient = async (body: Record<string, any>) => {
    try {
      const { statusCode } = await apiClient.post<{ data: ClientProps & { message: string } }>('clients', {
        body: JSON.stringify(body),
      });

      if (statusCode === 201) {
        const allClients = await fetchClients();
        allClients.length && setAllClients(allClients);

        setIsDrawerOpen(false);
      } else {
        throw new Error();
      }
    } catch (e) {
      notify();
    }
  };

  return (
    <div className={styles.newClientButton}>
      <Drawer
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        trigger={(
          <Button
            variant={ButtonVariants.SECONDARY}
            className={styles.button}
            icon={<PlusSVG />}
            iconSize={{ width: 18, height: 18 }}
            onClick={() => setIsDrawerOpen(true)}
          >
            {t('Add new client')}
          </Button>
        )}
        title={t('Add new client')}
      >
        <ClientForm
          submitButtonText={t('Add client')}
          submitForm={addClient}
        />
      </Drawer>
    </div>
  );
};

export default AdminSidebarFooter;
