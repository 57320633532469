export function stringToColor(str: string): string {
  let hash = 0;
  let charCode = 0;
  for (let i = 0; i < str.length; i += 1) {
    charCode = str.charCodeAt(i);
    // Amplify differences by incorporating the character's position
    hash = (hash * 31) + charCode + i; // Use prime number 31 for multiplication
    // eslint-disable-next-line no-bitwise
    hash &= hash; // Convert to 32bit integer
  }

  // Ensure hash is a positive integer
  hash = Math.abs(hash);

  // Convert the hash to a 6-digit hexadecimal color
  let color = '#';
  const baseColor = [0, 0, 0];
  for (let i = 0; i < 3; i += 1) {
    // eslint-disable-next-line no-bitwise
    baseColor[i] = (hash >> (i * 8)) & 0xFF;
  }

  // Adjust colors to ensure vibrancy
  const vibrantColor = baseColor.map(component => {
    const floor = 50; // Avoid too dark colors
    const ceiling = 205; // Avoid too light colors
    const adjustedComponent = floor + (component % (ceiling - floor));
    return adjustedComponent;
  });

  // Convert each component to a hex string and concatenate
  vibrantColor.forEach(component => {
    color += (`00${component.toString(16)}`).slice(-2);
  });

  return color;
}
