import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import classnames from 'classnames';
import styles from './OptionsGroup.module.scss';

type OptionsGroupProps = {
  value: string,
  options: { caption: string, value: string }[],
  setValue: (value: string) => void,
  error?: boolean,
  errorMessage?: string,
  disabled?: boolean,
  classNames?: Record<'group' | 'button', string>,
};

const OptionsGroup = ({
  value, options, setValue, error, errorMessage, disabled, classNames,
}: OptionsGroupProps) => (
  <div>
    <ToggleButtonGroup
      value={value}
      onChange={(e, newValue) => {
        if (newValue !== null) {
          setValue(newValue);
        }
      }}
      disabled={disabled}
      exclusive
      className={classnames(styles.optionsGroup, classNames?.group, {
        [styles.error]: error,
        'field-error': error,
        [styles.disabled]: disabled,
      })}
    >
      {options.map(valueItem => (
        <ToggleButton
          className={classnames(styles.button, classNames?.button)}
          value={valueItem.value}
          key={valueItem.value}
          disabled={disabled}
        >
          {valueItem.caption}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
    {(errorMessage && error) && <p className={styles.error}>{errorMessage}</p>}
  </div>
);

export default OptionsGroup;
