import { Params, redirect } from 'react-router-dom';
import apiClient from '../apiClient.ts';
import { Permissions } from '../components/pages/Login/user.props.ts';
import { checkUserPermission } from './check-permissions.loader.ts';
import { GENERAL_FEATURE_FLAG_KEY, isUserHasFeatureAccess } from './main.loader.ts';

export type CustomerQuestionAnswer = {
  id: number,
  caption: string,
};
export type CustomerQuestion = {
  id: number,
  caption: string,
  max: number,
  required: boolean,
  answers: CustomerQuestionAnswer[]
};

export const getCustomerQuestions = async () => {
  try {
    const { response: { data } } = await apiClient.get<{ data: CustomerQuestion[] }>('questions/customer');
    return data;
  } catch (e) {
    console.error(e);
  }
};

export const customersLoader = async (params?: Params<string>, neededPermission?: Permissions, isFeature?: boolean) => {
  if (isFeature && !isUserHasFeatureAccess(GENERAL_FEATURE_FLAG_KEY)) {
    return redirect('/404');
  }
  const hasPermission = neededPermission ? await checkUserPermission(neededPermission, (params?.clientId ?? params?.id)) : true;
  if (!hasPermission) {
    return redirect('/access-denied');
  }

  const customerQuestions = await getCustomerQuestions();

  return { questions: customerQuestions };
};
