import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import apiClient from '../apiClient.ts';

const verifyToken = async (email: string, token: string) => {
  try {
    const { statusCode } = await apiClient.post('user/verify-reset-token', {
      body: JSON.stringify({
        email,
        token,
      }),
    });
    if (statusCode === 422) {
      return false;
    } else {
      return true;
    }
  } catch (e) {
    console.error(e.message);
  }
};

export const newPasswordLoader = async ({ request }: LoaderFunctionArgs<Record<string, string>>) => {
  const isNewPassword = new URL(request.url).searchParams.get('new');
  const token = new URL(request.url).searchParams.get('token');
  const email = new URL(request.url).searchParams.get('email')?.replace(/\s/g, '+');

  if (isNewPassword === 'true') {
    const isLinkValid = await verifyToken(email!, token!);
    if (!isLinkValid) {
      return redirect('/');
    }
  }

  return true;
};
