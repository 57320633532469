import classNames from 'classnames';
import useSWR from 'swr';
import { useEffect, useState } from 'react';

import { useOutletContext, useParams } from 'react-router-dom';
import { useAtomValue } from 'jotai';

import ComfortScore from '../../../../ComfortScore/ComfortScore.tsx';
import Drawer from '../../../../UIKit/Drawer/Drawer.tsx';
import ScoreItem from '../../Score/Score.tsx';

import apiClient from '../../../../../apiClient.ts';
import { ScoreResponse, Scores } from '../../NewProjectDeliverables/types.ts';
import { NewProjectContext } from '../../types.ts';
import { StatusState } from '../../../../UIKit/StatusLabel/types.ts';
import { useCustomTranslation } from '../../../../../useAppTranslate.tsx';

import styles from './SummaryDashboard.module.scss';

const SummaryScores = () => {
  const { projectAtom } = useOutletContext<Pick<NewProjectContext, 'projectAtom'>>();
  const project = useAtomValue(projectAtom);
  const { t, i18n } = useCustomTranslation();
  const { projectId } = useParams();
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [formattedScores, setFormattedScores] = useState<Scores | null>(null);
  const [comfortScore, setComfortScore] = useState(0);

  const modifiedScoresData = (scoresData: ScoreResponse[]) => scoresData
    .reduce((result: Scores, score: ScoreResponse) => {
      result[String(score.id)] = score;
      return result;
    }, {});

  const { data: scoresData, isValidating, isLoading } = useSWR(
    [`projects/${projectId}/scores?page=all`, i18n.language, project?.status?.state],
    ([url]) => (project?.status?.state !== StatusState.PENDING ? apiClient
      .get<{ data: ScoreResponse[] }>(url)
      .then(({ response }) => response.data) : null),
    {
      keepPreviousData: false,
      revalidateOnFocus: false,
      revalidateOnMount: true,
    },
  );

  useEffect(() => {
    if (scoresData) {
      const scores = modifiedScoresData(scoresData);
      setFormattedScores(scores);
      const { totalSelected, totalValues } = Object.values(scores).reduce((acc, curr) => {
        acc.totalSelected += curr?.values?.selected ?? 0;
        acc.totalValues += curr?.values?.total ?? 0;
        return acc;
      }, { totalSelected: 0, totalValues: 0 });
      const ratio = (totalSelected / totalValues) * 100;
      setComfortScore(Math.round(ratio));
    }
  }, [scoresData]);

  return (
    <section className={classNames(styles.section, styles.score, {
      [styles.section_validating]: isValidating && !isLoading,
    })}
    >
      <ComfortScore
        value={comfortScore}
        className={styles.score__comfortScore}
        hideAction
      />
      <button
        type='button'
        className={classNames(styles.score__moreButton, 'link')}
        onClick={() => setIsDetailsOpen(true)}
      >
        {t('More details')}
      </button>
      <Drawer
        className={styles.score__drawer}
        isOpen={isDetailsOpen}
        setIsOpen={setIsDetailsOpen}
        title={t('Summary Scores')}
      >
        <div className={styles.scores}>
          <div className={styles.scores__top}>
            <ComfortScore
              value={comfortScore}
              hideAction
              className={styles.scores__item}
            />
            {formattedScores
          && Object.values(formattedScores)
            .slice(0, 3)
            .map((item) => (
              <ScoreItem
                value={item.values?.total ? ((item.values.selected / item.values.total) * 100) * (item?.values?.scale ?? 1) : 0}
                title={item.caption}
                key={item.id}
                icon={item.icon}
                className={styles.scores__item}
              />
            ))}
          </div>
          <div className={styles.scores__bottom}>
            {formattedScores && Object.values(formattedScores).slice(3).map(item => (
              <ScoreItem
                value={item.values?.total ? ((item.values.selected / item.values.total) * 100) * (item?.values?.scale ?? 1) : 0}
                title={item.caption}
                key={item.id}
                icon={item.icon}
                className={styles.scores__item}
              />
            ))}
          </div>
        </div>
      </Drawer>
    </section>
  );
};

export default SummaryScores;
