import {
  Outlet, useLoaderData, useParams,
} from 'react-router-dom';
import classNames from 'classnames';

import { Suspense } from 'react';
import AdminSidebar from '../../AdminSidebar/AdminSidebar';
import Header from '../../Header/Header';
import { ClientProps } from '../../AdminSidebar/client.props.ts';

import styles from './Archive.module.scss';
import SubHeader from '../../SubHeader/SubHeader.tsx';
import { useRoutePrefix } from '../../../usePermission.ts';
import { useCustomTranslation } from '../../../useAppTranslate.tsx';

const Archive = () => {
  const { isGlobalAdmin, clients } = useLoaderData() as { isGlobalAdmin: boolean; clients: ClientProps[] };
  const { t } = useCustomTranslation('global');
  const prefix = useRoutePrefix();
  const { id: clientId } = useParams();

  return (
    <div className={styles.page}>
      {isGlobalAdmin && <AdminSidebar clients={clients} />}
      <main className={styles.wrapper}>
        <Header showLogo={!isGlobalAdmin} />
        <div className={classNames(styles.inner, styles.noPadding)}>
          <SubHeader
            title={t('Project archive')}
            fallbackLink={`${prefix}/client/${clientId}/projects`}
          />
          <div className={styles.block}>
            <div className={styles.content}>
              <Suspense>
                <Outlet />
              </Suspense>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Archive;
