import { useState, useEffect, useRef } from 'react';

type UseTimerReturnType = {
  valueOfTimer: number;
  numberOfTimer: number;
  startLongTimer: (duration?: number) => void;
  startCheckAccessTimer: (interval: number) => void;
  resetTimers: () => void;
};

const useTimer = (
  onShortTimePassed: () => void,
  onLongTimePassed: () => void,
  onCheckEditAccess: () => void,
  longDuration: number,
  endValue: number = 100,
  endNumber: number = 15,
  frameInterval: number = 16,
  shortDuration: number = 15000,
): UseTimerReturnType => {
  const [valueOfTimer, setValueOfTimer] = useState<number>(0);
  const [numberOfTimer, setNumberOfTimer] = useState<number>(endNumber);
  const shortTimerRef = useRef<number | null>(null);
  const longTimerRef = useRef<number | null>(null);
  const checkAccessIntervalRef = useRef<number | null>(null);

  const startShortTimer = () => {
    clearInterval(shortTimerRef.current!);
    const startTime = Date.now();
    shortTimerRef.current = window.setInterval(() => {
      const elapsed = Date.now() - startTime;
      const progress = Math.min(elapsed / shortDuration, 1);
      setValueOfTimer(2 + (endValue - 2) * progress);
      setNumberOfTimer(endNumber - endNumber * progress);

      if (progress >= 1) {
        clearInterval(shortTimerRef.current!);
        onShortTimePassed();
      }
    }, frameInterval);
  };

  const startLongTimer = (duration: number = longDuration) => {
    clearTimeout(longTimerRef.current!);
    longTimerRef.current = window.setTimeout(() => {
      onLongTimePassed();
      startShortTimer();
    }, duration);
  };

  const startCheckAccessTimer = (interval: number) => {
    clearInterval(checkAccessIntervalRef.current!);
    checkAccessIntervalRef.current = window.setInterval(onCheckEditAccess, interval);
  };

  const resetTimers = () => {
    clearInterval(shortTimerRef.current!);
    clearTimeout(longTimerRef.current!);
    clearInterval(checkAccessIntervalRef.current!);
    setValueOfTimer(0);
    setNumberOfTimer(endNumber);
  };

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      resetTimers();
    };
  }, []);

  return {
    valueOfTimer,
    numberOfTimer,
    startLongTimer,
    startCheckAccessTimer,
    resetTimers,
  };
};

export default useTimer;
