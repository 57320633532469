import React from 'react';
import Modal from '../../../UIKit/Modal/Modal.tsx';
import styles from './DeleteQualityGateModal.module.scss';
import DeleteIllustrationSVG from '../../../../public/media/delete-illustration.svg';
import CalendarSVG from '../../../../public/media/summary/quality-gates/calendar.svg';
import StatusLabel from '../../../UIKit/StatusLabel/StatusLabel.tsx';
import Button, { ButtonVariants } from '../../../UIKit/Button/Button.tsx';
import { GateResource } from '../types.ts';
import { useCustomTranslation } from '../../../../useAppTranslate.tsx';

type ModalProps = {
  closeModal: () => void,
  gate: GateResource,
  onDeleteClick: (id: number) => void
};

const DeleteQualityGateModal = ({ closeModal, gate, onDeleteClick }: ModalProps) => {
  const { t } = useCustomTranslation();
  const deliverablesCount = gate.deliverables ? gate.deliverables.length : gate?.deliverables_count ? gate?.deliverables_count : 0;

  return (
    <Modal
      closeModal={closeModal}
      rootClassName={styles.modal}
    >
      <div className={styles.modal__content}>
        <svg className={styles.modal__illustration}>
          <use
            xlinkHref={`${DeleteIllustrationSVG}#deleteIllustrationSVG`}
            href={`${DeleteIllustrationSVG}#deleteIllustrationSVG`}
          />
        </svg>
        <div>
          <h4>
            {t('Are you sure you want to remove the quality gate?')}
            {deliverablesCount > 0
              ? ` ${t('Zeemless recommends to reassign all deliverables to another gate first')}` : ''}
          </h4>
          <div className={styles.modal__gateDetails}>
            <div>
              <p className={styles.modal__gateDetails_caption}>{gate.caption}</p>
              <span>
                <svg>
                  <use
                    xlinkHref={`${CalendarSVG}#calendarSVG`}
                    href={`${CalendarSVG}#calendarSVG`}
                  />
                </svg>
                {gate.due_date}
              </span>
            </div>
            <StatusLabel status={{ caption: gate.status.caption, value: gate.status.value, state: gate.status.state }} />
          </div>
        </div>
        <div className={styles.modal__buttons}>
          <Button
            type='button'
            onClick={closeModal}
            variant={ButtonVariants.SECONDARY}
          >
            {t('Cancel')}
          </Button>
          <Button
            type='button'
            onClick={() => onDeleteClick(gate.id)}
          >
            {t('Yes, delete')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteQualityGateModal;
