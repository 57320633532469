import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { useAtomValue, useSetAtom } from 'jotai';
import { mutate } from 'swr';
import { TFunction } from 'i18next';
import * as Yup from 'yup';

import Input from '../../UIKit/Input/Input';
import Button, { ButtonVariants } from '../../UIKit/Button/Button';

import apiClient from '../../../apiClient';
import { NotificationStatus, notify } from '../../../store/notifications';
import { twoFARecoveryCode, userAtom } from '../../../store/auth';
import { useCustomTranslation } from '../../../useAppTranslate';

import InfoSVG from '../../../public/media/info.svg';

import styles from './DisableTwoFactorAuthForm.module.scss';

enum TwoFAActions {
  DISABLE = 'disable',
  REGENERATE_CODE = 'regenerate',
}

const disable2FA = async (body: { password: string }, successCallback: () => void, t: TFunction<'translation', undefined>) => {
  try {
    const { response, statusCode } = await apiClient.post<{ message?: string }>('user/2fa/disable', { body: JSON.stringify(body) });
    if (statusCode === 200) {
      notify({
        text: {
          body: response?.message ? t(response.message) : t('Successfully disabled two-factor authentication.'),
          title: t('Success'),
        },
        status: NotificationStatus.SUCCESS,
      });
      successCallback();
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error) {
    notify({ text: { body: error.message } });
    console.error(error);
  }
};

const getRecoveryCode = async (
  body: { password: string },
  setSecretCode: (code: string[]) => void,
  t: TFunction<'translation', undefined>,
) => {
  try {
    const { response, statusCode } = await apiClient.post<{ data: string[]; message?: string }>('user/2fa/regenerate-recovery-codes', {
      body: JSON.stringify(body),
    });

    if (statusCode === 200) {
      setSecretCode(response.data);
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error) {
    notify({ text: { body: t(error.message) } });
    console.error(error);
  }
};

const DisableTwoFactorAuthForm = ({ closeDrawer }: { closeDrawer: () => void }) => {
  const { t } = useCustomTranslation();
  const setRecoveryCode = useSetAtom(twoFARecoveryCode);
  const user = useAtomValue(userAtom);

  const onDisable2FAClick = async (formValues: { password: string }) => {
    disable2FA(
      formValues,
      () => {
        // the content of the TwoFactorAuth component depends on the state that mutates here.
        // to prevent the flickering of the component, we close the drawer and then mutate the state after 300ms
        closeDrawer();
        setTimeout(() => {
          mutate((key: any[]) => key.includes('user'), { ...user?.user, '2fa': false }, { revalidate: false });
        }, 300);
      },
      t,
    );
  };

  const onRecoveryCodesClick = async (formValues: { password: string }) => {
    getRecoveryCode(formValues, setRecoveryCode, t);
  };

  const {
    values, handleChange, handleBlur, touched, errors, resetForm, handleSubmit, setFieldValue,
  } = useFormik({
    initialValues: {
      password: '',
      action: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required(),
      action: Yup.string().oneOf([TwoFAActions.DISABLE, TwoFAActions.REGENERATE_CODE]).required(),
    }),
    onSubmit: ({ password, action }) => (action === TwoFAActions.DISABLE
      ? onDisable2FAClick({ password })
      : onRecoveryCodesClick({ password })
    ),
  });

  useEffect(() => {
    () => resetForm();
  }, []);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await setFieldValue('action', TwoFAActions.DISABLE);
    handleSubmit();
  };

  return (
    <form
      className={styles.disableForm}
      onSubmit={onSubmit}
    >
      <p className={styles.disableForm__subtitle}>
        {t('Use this section to disable your one-time password authenticator. You can also generate new recovery codes.')}
      </p>
      <div className={styles.disableForm__clue}>
        <svg>
          <use
            xlinkHref={`${InfoSVG}#infoSVG`}
            href={`${InfoSVG}#infoSVG`}
          />
        </svg>
        <p>{t('If you lose recovery codes you can generate new ones, invalidating all previous codes.')}</p>
      </div>
      <Input
        value={values.password}
        setValue={handleChange}
        onBlur={handleBlur}
        id='password'
        label={t('Current password')}
        name='password'
        type='password'
        error={!!(touched.password && errors.password)}
        errorMessage={errors.password}
      />
      <footer className={styles.disableForm__footer}>
        <Button
          type='submit'
          className={styles.disableForm__btn}
        >
          {t('Disable two-factor authentication')}
        </Button>
        <Button
          variant={ButtonVariants.SECONDARY}
          onClick={async (e) => {
            e.preventDefault();
            await setFieldValue('action', TwoFAActions.REGENERATE_CODE);
            handleSubmit();
          }}
          className={styles.disableForm__btn}
        >
          {t('Regenerate recovery codes')}
        </Button>
      </footer>
    </form>
  );
};

export default DisableTwoFactorAuthForm;
