import React, { Suspense } from 'react';

const PortfolioContent = React.lazy(() => import('./ProjectTrackingContent.tsx'));

const ProjectTracking = () => (
  <Suspense>
    <PortfolioContent />
  </Suspense>
);

export default ProjectTracking;
