import { redirect } from 'react-router-dom';
import { hasGlobalPermission } from '../usePermission.ts';
import { Permissions } from '../components/pages/Login/user.props.ts';
import apiClient from '../apiClient.ts';
import { ClientProps } from '../components/AdminSidebar/client.props.ts';
import { getAuthorizeToken } from '../utils';

async function getClients() {
  try {
    const { response: { data } } = await apiClient.get< { data: ClientProps[] } >('clients');
    return data;
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function clientLoader() {
  const authorizeToken = getAuthorizeToken();

  if (!authorizeToken) {
    return redirect('/login');
  }

  const clients = await getClients();
  const hasClientsViewPermission = hasGlobalPermission(Permissions.ADMIN);

  return { isGlobalAdmin: hasClientsViewPermission, clients };
}
