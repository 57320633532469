import { Deliverable, DeliverableStatus } from '../NewProjectDeliverables/types';

type TransformIdsToString<T> = {
  [K in keyof T]: T[K] extends number | null
    ? // eslint-disable-next-line @typescript-eslint/no-unused-vars
    K extends `${infer Prefix}id` | `${infer Prefix}Id` | 'id'
      ? string | null
      : T[K]
    : T[K] extends number
      ? // eslint-disable-next-line @typescript-eslint/no-unused-vars
      K extends `${infer Prefix}id` | `${infer Prefix}Id` | 'id'
        ? string
        : T[K]
      : T[K];
};

export type TaskResource = {
  id: number;
  name: string;
  duration: number;
  start: number;
  finish: number;
  parent_id: number | null;
  is_long_running: boolean;
  is_consist_of_multiple_sprints: boolean;
  icon: string;
  is_domain: boolean;
  start_date: string;
  finish_date: string;
  min_duration: number;
  task_at_risk: false;
  sprints_count: number;
  status: {
    value: number;
    caption: string;
  };
  number: number | null;
  caption: {
    [locale: string]: string;
  };
  parent: null | number;
  links: Array<{
    id: number;
    caption: string;
    link: string;
  }>;
  manual_status: boolean;
  jira_link: string;
  attached_link: null;
  last_history: {
    id: number;
    data: string;
    created_at: string;
  };
  has_children: boolean;
};

type RelatedTask = TaskResource & {
  level: number;
  domain_id: number;
};

export type Predprocessor = {
  type: {
    value: number;
    caption: string;
  };
  offset: number;
  related_task_id: number;
  related_task: RelatedTask;
};

export type TaskType = Omit<TaskResource, 'parent_id' | 'is_domain'> & { parent_id: number, is_domain: false };

export type TaskWithPredecessors = TaskType & {
  predecessors: Predprocessor[];
  has_children: boolean;
  is_quality_gate: boolean;
  level: number;
  domain_id: number;
  order: number;
  is_critical_path: boolean;
  critical_path_number: number | null;
  deliverables: Pick<Deliverable, 'caption' | 'id' | 'order'>[];
};

export type TaskResourceWithStringIds = TransformIdsToString<TaskResource>;

type RelatedTaskWithStringIds = TransformIdsToString<RelatedTask>;

export type PredprocessorWithStringIds = {
  type: {
    value: number;
    caption: string;
  };
  offset: number;
  related_task_id: string;
  related_task: RelatedTaskWithStringIds;
};

export type TaskWithPredecessorsWithStringIds = TransformIdsToString<Omit<TaskWithPredecessors, 'predecessors'>> & {
  predecessors: PredprocessorWithStringIds[];
};

export type TaskListAdapterType = Pick<
TaskWithPredecessorsWithStringIds,
| 'id'
| 'parent_id'
| 'domain_id'
| 'name'
| 'min_duration'
| 'duration'
| 'start_date'
| 'finish_date'
| 'number'
| 'is_quality_gate'
| 'level'
| 'is_long_running'
| 'is_consist_of_multiple_sprints'
| 'is_domain'
| 'icon'
| 'caption'
| 'has_children'
| 'order'
> & {
  predecessors: {
    related_task_id: string;
    type: { value: string; caption: string };
    offset: number;
  }[];
};

export type FormattedDeliverables = {
  domains: Record<number, { id: number; caption: string; deliverables: Deliverable[] }>;
  internal: number;
  external: number;
  total: number;
  rowData: Deliverable[];
};

export type EnumToStringValues<T extends { [index: string]: string | number }> = T[keyof T];

export type DeliverablesFilterValue = EnumToStringValues<typeof DeliverableStatus> | 'all';

export type SkillResource = {
  id: number;
  deliverables_count: number;
  caption: string;
};

export type StakeholderResource = {
  id: number;
  caption: string;
  date: string | null;
};

export type ToolIntegrationResource = {
  id: number | null;
  type: {
    value: Tool;
    caption: string;
    icon: string;
  };
  host: string | null;
  username: string | null;
  access_token: string | null;
};

export enum ProjectJiraIntegrationStatus {
  NEW = 0,
  PENDING = 1,
  CREATED = 2,
  TASKS_SYNC = 3,
  FAILED_SYNC = 4,
}

export enum Tool {
  JIRA = 1,
}
