const toCamelCase = (str: string): string => str.toLowerCase().replace(/[-_][a-z]/g, (group) => group.slice(-1).toUpperCase());

const getKey = (key: string): string => {
  const keyParts = key.split('.');
  return keyParts[keyParts.length - 1];
};

export const getFormattedServerErrors = (errors: Record<string, string[]>): Record<string, string> => {
  const formattedErrors: Record<string, string> = {};
  Object.entries(errors).forEach((error) => {
    const key = toCamelCase(getKey(error[0]));
    const message = error[1][0];

    if (!formattedErrors[key]) {
      formattedErrors[key] = '';
    }

    formattedErrors[key] = message;
  });

  return formattedErrors;
};
