import React from 'react';
import { Tooltip } from '@mui/material';

import classNames from 'classnames';
import styles from './ListItemTooltip.module.scss';

type ListFormat = 'list' | 'enum';

const OptionsListFilter = ({ options, format = 'enum' }: { options: string[], format?: ListFormat }) => (
  <div className={classNames(styles.optionList, {
    [styles.optionList_list]: format === 'list',
  })}
  >
    {options.map((option, i, arr) => (
      <span key={option}>
        {option}
        {i !== arr.length - 1 ? ', ' : ''}
      </span>
    ))}
  </div>
);

const ListItemTooltip = ({
  title, options, format = 'enum', hideSpoiler, icon,
}: { title: string, options: string[], format?: ListFormat, hideSpoiler?: boolean, icon?: JSX.Element }) => (
  <div className={styles.wrapper}>
    <Tooltip
      arrow
      title={(
        <div className={styles.tooltip}>
          <p className={styles.tooltip__title}>{title}</p>
          <p className={styles.tooltip__text}>
            <OptionsListFilter
              {...{ options, format }}
            />
          </p>
        </div>
      )}
      placement='bottom-start'
    >
      {icon ?? <span className={styles.counter}>{options.length}</span>}
    </Tooltip>
    {!hideSpoiler && (
    <p className={styles.options}>
      <OptionsListFilter {...{ options }} />
    </p>
    )}
  </div>
);

export default ListItemTooltip;
