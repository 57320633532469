import { useEffect, useRef } from 'react';

export const useElementHeightCssVariable = (cssVariableName: string, dependencies?: any[]) => {
  const elementWrapperRef = useRef<HTMLDivElement | null>(null);

  const setElementHeight = () => {
    const height = elementWrapperRef?.current?.offsetHeight;
    const marginTop = elementWrapperRef?.current && parseInt(getComputedStyle(elementWrapperRef?.current).marginTop, 10);
    const marginBottom = elementWrapperRef?.current && parseInt(getComputedStyle(elementWrapperRef?.current).marginBottom, 10);
    const totalHeight = Number(height) + Number(marginTop) + Number(marginBottom);

    if (height) {
      document.documentElement.style.setProperty(cssVariableName, `${totalHeight}px`);
    } else {
      document.documentElement.style.setProperty(cssVariableName, '0px');
    }
  };

  useEffect(() => {
    setElementHeight();
    window.addEventListener('resize', setElementHeight);

    return () => {
      window.removeEventListener('resize', setElementHeight);
      document.documentElement.style.setProperty(cssVariableName, '0px');
    };
  }, dependencies ?? []);

  return elementWrapperRef;
};
