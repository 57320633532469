import useSWR, { SWRResponse } from 'swr';
import {
  useBeforeUnload, useNavigate, useOutletContext, useParams,
} from 'react-router-dom';
import { FormikValues, useFormik } from 'formik';
import * as Yup from 'yup';

import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import dayjs from 'dayjs';
import { NumericFormat } from 'react-number-format';
import classnames from 'classnames';
import {
  Chip, InputAdornment, ListSubheader, MenuItem, TextField, Tooltip,
} from '@mui/material';
import { GridSearchIcon } from '@mui/x-data-grid';

import apiClient from '../../../../apiClient.ts';
import type { Questions } from '../../../UserForm/types';
import Loader from '../../../Loader/Loader.tsx';
import Input from '../../../UIKit/Input/Input.tsx';
import DatePicker from '../../../UIKit/DatePicker/DatePicker.tsx';
import Button from '../../../UIKit/Button/Button.tsx';
import CheckboxItem from '../../../UIKit/CheckboxItem/CheckboxItem.tsx';

import styles from './NewProjectParameters.module.scss';
import { userAtom } from '../../../../store/auth.ts';
import Select from '../../../UIKit/Select/Select.tsx';
import Autocomplete from '../../../UIKit/Autocomplete/Autocomplete.tsx';
import TextEditor from '../../../UIKit/TextEditor/TextEditor.tsx';
import { CustomerResource } from '../../Customers/types';
import { useDebounce } from '../../../../useDebounce.ts';
import { ProjectResource } from '../../Projects/types.ts';
import { getFormattedServerErrors, scrollIntoView } from '../../../../utils';
import { Permissions, ProjectPermissions } from '../../Login/user.props.ts';
import Drawer from '../../../UIKit/Drawer/Drawer.tsx';
import AddCustomerForm from '../../Customers/AddCustomerForm/AddCustomerForm.tsx';
import { Status, StatusState } from '../../../UIKit/StatusLabel/types.ts';
import { NewProjectContext, Redirect } from '../types.ts';
import { DEFAULT_DATE_FORMAT, DEFAULT_NUMBER_FORMAT, NUMBER_FORMAT } from '../../../../constants.ts';
import { useAutoSave } from '../useAutoSave.ts';
import { eventEmitter, EventType } from '../../../../eventEmitter.ts';
import { notify } from '../../../../store/notifications.ts';
import { usePermissions } from '../../../../usePermission.ts';
import { projectEditAccess, projectIdAtom } from '../../../../store/project.ts';
import CloseSVG from '../../../../public/media/close.svg';
import InfoSVG from '../../../../public/media/info.svg';
import AiSVG from '../../../../public/media/ai.svg';
import { useCustomTranslation } from '../../../../useAppTranslate.tsx';

type Template = {
  id: number;
  caption: string;
  status: number;
  creator: string;
  inactive?: boolean;
};

export enum QuestionName {
  PROJECT_METHODOLOGY = 'projectMethodology',
  SPRINT_LENGTH = 'sprintLength',
  PROJECT_EMPLOYEES = 'numProjectEmployees',
  SYSTEMS_INVOLVED = 'numSystemsInvolved',
  PROCESSES_AFFECTED = 'businessProcessesAffected',
}

enum SubmitType {
  DRAFT = 'draft',
  NEXT_STEP = 'next-step',
}

const swrConfig = {
  keepPreviousData: true,
  revalidateOnFocus: false,
};

let initialFormValues: FormikValues = {};

const formatTemplates = (templates: Template[] | undefined, t: (key: string) => string) => {
  if (templates) {
    return templates.map((template) => ({
      caption: `${template.caption}${template?.inactive ? ` (${t('inactive')})` : ''}`,
      value: template.id.toString(),
      disabled: template?.inactive,
    }));
  } else {
    return [];
  }
};

const isDomainGroupSelected = (selectedDomains: string[] | undefined, domains: Questions | undefined, group: string) => {
  const groupDomains = domains?.answers.filter(domain => domain.group_id === +group);
  return selectedDomains && groupDomains ? groupDomains.every(domain => selectedDomains?.includes(`${domain.id}`)) : false;
};
const isDomainGroupIndeterminate = (selectedDomains: string[] | undefined, domains: Questions | undefined, group: string) => {
  const groupDomains = domains?.answers.filter(domain => domain.group_id === +group);
  return selectedDomains && groupDomains ? groupDomains.some(domain => selectedDomains?.includes(`${domain.id}`)) : false;
};

const handleAddDomainGroup = (selectedDomains: string[] | Array<string | string[]> | undefined) => {
  const selectedGroup = selectedDomains?.find(element => Array.isArray(element));
  if (Array.isArray(selectedGroup) && selectedDomains) {
    const isGroupElementAlreadySelected = selectedGroup?.every(groupDomain => selectedDomains?.includes(groupDomain));
    const result = isGroupElementAlreadySelected
      ? selectedDomains?.filter(domain => !Array.isArray(domain) && !selectedGroup.includes(domain))
      : selectedDomains;
    return Array.from(new Set(result.flat()));
  } else {
    return selectedDomains;
  }
};

const NewProjectParameters = () => {
  const { clientId, projectId } = useParams();
  const navigate = useNavigate();
  const {
    projectAtom,
    progressAtoms,
    setPollingSettings,
    setManualChangesConfirmationSettings,
    isAiLoadingScreenVisible,
    setIsAiLoadingScreenVisible,
    getEditAccess,
  } = useOutletContext<NewProjectContext>();
  const updateProgress = useSetAtom(progressAtoms.parameters);
  const [project, setProject] = useAtom(projectAtom);
  const storedProjectEditAccess = useAtomValue(projectEditAccess);
  const isAbleToEdit = project?.permissions?.includes(ProjectPermissions.UPDATE);
  let isReadOnly: boolean;
  if (projectId) {
    isReadOnly = (!isAbleToEdit && project?.permissions?.includes(ProjectPermissions.VIEW))
    || !storedProjectEditAccess?.isEditingAvailableShowOnUI || project?.schedule_edited;
  } else {
    isReadOnly = (!isAbleToEdit && project?.permissions?.includes(ProjectPermissions.VIEW));
  }

  const [storedProjectId, setProjectId] = useAtom(projectIdAtom);

  const { t, i18n } = useCustomTranslation();
  const userData = useAtomValue(userAtom);
  const { hasPermission } = usePermissions();
  const prefix = hasPermission(Permissions.ADMIN) ? Redirect.MANAGE : Redirect.DASHBOARD;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [hasDraftStatus, setHasDraftStatus] = useState(true);
  const [projectData, setProjectData] = useState<{ id: number, status: Status, submitType: SubmitType } | null>(null);
  const [isProjectLoaded, setIsProjectLoaded] = useState(false);
  const [wasChangesMade, setWasChangesMade] = useState(false);
  const [isProjectProcessing, setIsProjectProcessing] = useState(false);

  const [filteredDomains, setFilteredDomains] = useState<Questions['answers']>([]);
  const [domainSearch, setDomainSearch] = useState<string>('');

  const debouncedSearch = useDebounce<string>(search, 300);

  const [mandatoryFields, setMandatoryFields] = useState<Record<string, boolean>>({
    name: true,
    begin: true,
    end: true,
    type: true,
    methodology: true,
    sprintLength: false,
    customer: true,
    domains: true,
    budget: true,
    systemsInvolved: true,
    employees: true,
    description: true,
  });
  const numberFormat = userData?.user.numberFormat ? NUMBER_FORMAT[userData?.user.numberFormat] : DEFAULT_NUMBER_FORMAT;

  const transformToNumber = (value: string) => +value
    .replaceAll(numberFormat.thousandSeparator, '')
    .replaceAll(numberFormat.decimalSeparator, '.');

  useBeforeUnload(useCallback((e) => {
    if (wasChangesMade) {
      e.preventDefault();
    }
  }, [wasChangesMade]));

  const {
    data: questions,
    isLoading: areQuestionsLoading,
  } = useSWR(['questions/project', i18n.language], ([url]) => apiClient
    .get<{ data: Questions[] }>(url).then(({ response }) => response.data), swrConfig);

  const {
    data: templates,
    isLoading: areTemplatesLoading,
  } = useSWR([`clients/${clientId}/availableTemplates`, i18n.language], ([url]) => apiClient
    .get<{ data: Template[] }>(url).then(({ response }) => response.data), {
    ...swrConfig,
    use: [
      (useSWRNext) => (key, fetcher, config) => {
        const hasPermissionToFetch = project?.permissions?.includes(ProjectPermissions.UPDATE)
          || project?.permissions?.includes(ProjectPermissions.MANAGE);
        const shouldLoadTemplates = !project || hasPermissionToFetch;

        if (shouldLoadTemplates) {
          const swr = useSWRNext(key, fetcher, config);
          // Add project template to the list of available templates if it's inactive
          const needAddProjectTemplate = project?.template?.status.state === StatusState.INACTIVE;
          return (
            swr.data && needAddProjectTemplate ? { ...swr, data: [project.template, ...(swr.data as Template[])] } : swr
          ) as SWRResponse;
        }

        return ({ data: project?.template ? [project?.template] : [] }) as SWRResponse;
      },
    ],
  });

  const { data: customers, mutate } = useSWR(
    [`clients/${clientId}/customers`, debouncedSearch, i18n.language, project?.id],
    async ([url, searchValue]) => {
      const params = searchValue ? new URLSearchParams({ search: searchValue }).toString() : '';

      const { response } = await apiClient
        .get<{ data: CustomerResource[] }>(url + (params ? `?${params}` : ''));

      return project?.customer?.deleted ? [project.customer, ...response.data] : response.data;
    },
    {
      ...swrConfig,
    },
  );

  useEffect(
    // eslint-disable for better readability
    // eslint-disable-next-line arrow-body-style
    () => {
      return () => {
        setPollingSettings(prev => ({ ...prev, callback: null }));
      };
    },
    [],
  );

  const methodologies = questions?.find(question => question.name === QuestionName.PROJECT_METHODOLOGY);
  const sprints = questions?.find(question => question.name === QuestionName.SPRINT_LENGTH);
  const domains = questions?.find(question => question.name === QuestionName.PROCESSES_AFFECTED);
  const systemsNumbers = questions?.find(question => question.name === QuestionName.SYSTEMS_INVOLVED);
  const employeesNumbers = questions?.find(question => question.name === QuestionName.PROJECT_EMPLOYEES);

  const questionsMap = {
    ...(methodologies ? { [methodologies.id]: 'methodology' } : ''),
    ...(sprints ? { [sprints.id]: 'sprintLength' } : ''),
    ...(domains ? { [domains.id]: 'domains' } : ''),
    ...(systemsNumbers ? { [systemsNumbers.id]: 'systemsInvolved' } : ''),
    ...(employeesNumbers ? { [employeesNumbers.id]: 'employees' } : ''),
  };

  const {
    validateForm,
    setTouched,
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    setErrors,
    setFieldValue,
    setValues,
  }: FormikValues = useFormik({
    initialValues: {
      name: '',
      begin: null,
      end: null,
      type: '',
      methodology: '',
      sprintLength: '',
      // salesForce: '',
      customer: null,
      domains: [],
      budget: '',
      systemsInvolved: '',
      employees: '',
      description: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().trim().required(t('Project name is required')).max(100, t('Must be 100 characters at most')),
      begin: Yup.date().nullable().transform((value) => {
        if (dayjs(value).isValid()) {
          return value;
        }
      })
        .min(dayjs('01/01/2000').toDate(), t('Start date can not be set earlier than 01.01.2000'))
        .max(dayjs('01/01/2500').toDate(), t('The end date cannot be later than 01.01.2500'))
        .required(t('Start date is required')),
      end: Yup.date().nullable().transform((value) => {
        if (dayjs(value).isValid()) {
          return value;
        }
      })
        .test('end-min-value', t('Start date can not be set earlier than 01.01.2000'), (value) => !dayjs(value)
          .isBefore(dayjs('01/01/2000')))
        .test('end-min-value', t('The end date cannot be later than 01.01.2500'), (value) => !dayjs(value)
          .isAfter(dayjs('01/01/2500')))
        .test('end-before-start', t('End date cannot be before start date'), (value, context) => !dayjs(value)
          .isBefore(dayjs(context.parent.begin)))
        .required(t('End date is required'))
        .when('begin', ([begin], schema) => {
          if (begin) {
            const min = dayjs(begin).add(1, 'month');
            const max = dayjs(begin).add(10, 'year');
            return schema
              .min(min.toDate(), t('Difference between start date and end date cannot be less than a month'))
              .max(max.toDate(), t('Duration cannot be longer than 10 years'));
          }

          return schema;
        }),
      type: Yup.string().required(t('Project type is required')),
      methodology: Yup.string().required(t('Project methodology is required')),
      sprintLength: Yup.string().optional()
        .when('methodology', ([methodology], schema) => {
          const ids = sprints?.depends.map(({ answer }) => answer) ?? [];

          if (methodology && ids.includes(+methodology)) {
            setMandatoryFields({ ...mandatoryFields, sprintLength: true });
            return schema.required(t('Sprint length is required'));
          }

          setMandatoryFields({ ...mandatoryFields, sprintLength: false });
          return schema;
        }),
      // salesForce: Yup.string().optional().max(100, t('Must be 100 characters at most')),
      customer: Yup.object().shape({
        value: Yup.string().required(),
        id: Yup.string().required(),
      }).required(t('Customer is required')),
      domains: Yup.array().of(Yup.string()).min(1, t('Processes / Business is required')),
      budget: Yup.string().required(t('Budget is required'))
        .test('min-budget-value', t('Budget can not be equal to 0'), (value) => transformToNumber(value) !== 0)
        .test('max-budget-value', t('Budget can not be more than 10 billion'), (value) => transformToNumber(value) <= 10_000_000_000),
      systemsInvolved: Yup.string().required(t('Number of systems involved is required')),
      employees: Yup.string().required(t('Number of project employees is required')),
      description: Yup.string().required(t('Description is required'))
        .max(5000, t('Description field should not be greater than 5000 characters')),
    }),
    onSubmit: () => {
    },
  });

  useEffect(() => {
    if (areQuestionsLoading) return;

    const value = Object.keys(mandatoryFields).reduce((acc, key) => {
      if (mandatoryFields[key]) {
        acc.total += 1;
      }

      if (values[key]) {
        acc.filled += 1;
      }

      return acc;
    }, { total: 0, filled: 0 });

    updateProgress((value.filled / value.total) * 100);
  }, [values, areQuestionsLoading]);

  // This useEffect is used to fill project data from server on route /client/:id/project/:id
  useEffect(() => {
    if (areQuestionsLoading) return;
    setIsProjectLoaded(false);

    if (!project && !hasPermission(Permissions.PROJECT_CREATE)) {
      return navigate('/');
    }

    if (!project) return;

    const hasRequiredPermissions = project.permissions.includes(ProjectPermissions.UPDATE)
      || project.permissions.includes(ProjectPermissions.VIEW);

    if (!hasRequiredPermissions) {
      return navigate('/');
    }

    const {
      caption,
      begin,
      end,
      customer,
      budget,
      template,
      answers,
      status,
      description,
    } = project;

    setHasDraftStatus(status.state === StatusState.WARNING);

    const getAnswerValue = (id?: number, multiple = false) => {
      if (!id) return null;
      if (!answers[id] || !Array.isArray(answers[id])) return null;

      return multiple ? answers[id].map((answer: number) => `${answer}`) : `${answers[id][0]}`;
    };
    const initial = {
      name: caption ?? '',
      begin: begin ? dayjs(begin) : null,
      end: end ? dayjs(end) : null,
      type: template ? template.id : '',
      methodology: getAnswerValue(methodologies?.id) ?? '',
      sprintLength: getAnswerValue(sprints?.id) ?? '',
      // salesForce: salesforce ?? '',
      customer: customer ? {
        icon: (
          <div className={classnames(styles.form__customerIcon, {
            [styles.inactive]: customer.deleted,
          })}
          >
            {customer.name[0]}
          </div>
        ),
        id: customer.id,
        value: customer.name + (customer.deleted ? ` (${t('deleted')})` : ''),
        title: `ID #${customer.id}`,
      } : null,
      domains: getAnswerValue(domains?.id, true) ?? [],
      budget: budget ? `${budget}${numberFormat.decimalSeparator}00` : '',
      systemsInvolved: getAnswerValue(systemsNumbers?.id) ?? '',
      employees: getAnswerValue(employeesNumbers?.id) ?? '',
      description: description ?? '',
    };
    setValues(initial);
    initialFormValues = { ...initial };

    setIsProjectLoaded(true);
  }, [project, areQuestionsLoading]);

  useEffect(() => {
    if (!isProjectLoaded) return;

    // ReactNode like icon can not be stringified
    const valuesObj = JSON.stringify({ ...values, customer: { ...values.customer, icon: null } });
    const initialValuesObj = JSON.stringify({
      ...initialFormValues,
      customer: { ...initialFormValues.customer, icon: null },
    });

    setWasChangesMade(valuesObj !== initialValuesObj);
  }, [values, isProjectLoaded]);

  const debouncedChanges = useDebounce<boolean>(values, 1500);

  useEffect(() => {
    wasChangesMade && !storedProjectEditAccess?.isViewMode && getEditAccess();
  }, [wasChangesMade, debouncedChanges, storedProjectEditAccess?.isViewMode]);

  const isDependFieldVisible = (fieldQuestion?: Questions): boolean => {
    if (!fieldQuestion) return false;
    if (!fieldQuestion.depends) return true;

    for (let i = 0; i < fieldQuestion.depends.length; i += 1) {
      const { question, answer } = fieldQuestion.depends[i];
      const name = questionsMap[question];

      if ((values as Record<string, any>)[name] === answer.toString()) {
        return true;
      }
    }

    return false;
  };

  const isFormValid = async (submitValues: typeof values) => {
    const touchedFields = Object.keys(submitValues)
      .reduce((fields: Record<string, boolean>, key) => {
        fields[key] = true;
        return fields;
      }, {});
    await setTouched(touchedFields, false);
    const validateErrors = await validateForm();

    return Object.keys(validateErrors).length === 0;
  };

  const submitProject = async (submitValues: typeof values, removeManualSchedule = false, isAutoSave?: boolean) => {
    setIsProjectProcessing(true);
    const body: Record<string, number | any> = {
      caption: submitValues.name,
      begin: submitValues.begin ? dayjs(submitValues.begin).format('YYYY-MM-DD') : null,
      end: submitValues.end ? dayjs(submitValues.end).format('YYYY-MM-DD') : null,
      project_type_id: submitValues.type ? submitValues.type : null,
      customer_id: submitValues?.customer?.id,
      budget: submitValues.budget ? Math.round(transformToNumber(submitValues.budget)) : null,
      answers: {},
    };

    if (submitValues.methodology) {
      body.answers[methodologies!.id] = [+submitValues.methodology];
    }

    if (submitValues.sprintLength) {
      body.answers[sprints!.id] = [+submitValues.sprintLength];
    }

    // if (submitValues.salesForce) {
    //   body.salesforce = submitValues.salesForce;
    // }

    if (submitValues.domains) {
      body.answers[domains!.id] = submitValues.domains.map((domain: string) => +domain);
    }

    if (submitValues.systemsInvolved) {
      body.answers[systemsNumbers!.id] = [+submitValues.systemsInvolved];
    }

    if (submitValues.employees) {
      body.answers[employeesNumbers!.id] = [+submitValues.employees];
    }

    if (submitValues.description) {
      body.description = submitValues.description;
    }

    const url = projectId
      ? `clients/${clientId}/projects/${projectId}`
      : `clients/${clientId}/projects`;
    const method = projectId ? 'PUT' : 'POST';
    const { statusCode, response } = await apiClient
      .request<{ data: ProjectResource, errors?: Record<string, string[]> }>(url, method, {
      body: JSON.stringify({ ...body, remove_manual_schedule: removeManualSchedule }),
    });

    if (statusCode === 422 && response.errors) {
      setErrors(getFormattedServerErrors(response.errors));
    }

    if (![200, 201].includes(statusCode)) {
      throw new Error(t('Something went wrong while submitting project'));
    }

    method === 'PUT' && setProject({ ...project, ...response.data, status: { ...response.data?.status, state: StatusState.PENDING } });
    setProjectId(response?.data?.id);

    setPollingSettings(prev => ({
      ...(prev ?? {}),
      isTriggered: true,
      ...(!isAutoSave ? {
        callback: async (pollingResponse) => {
          const projId = pollingResponse?.data?.id ?? storedProjectId;
          const route = (projectData && projectData?.submitType === SubmitType.DRAFT) ? `${prefix}/client/${clientId}/projects`
            : `/d/client/${clientId}/project/${projId}/questions`;
          setIsProjectProcessing(false);
          const PROJECT_PAGE_PATH = `client/${clientId}/project/${projId}`;
          const NEW_PROJECT_PAGE_PATH = `d/client/${clientId}/new-project`;
          const VALID_ROUTES = [PROJECT_PAGE_PATH, NEW_PROJECT_PAGE_PATH];
          const currentLocation = window.location.href;
          if (VALID_ROUTES.some(validRoute => currentLocation.includes(validRoute))) {
            return navigate(route);
          }
        },
      } : { callback: null }),
    }));
    return response.data;
  };

  const onSaveChanges = async (submitValues: typeof values, removeManualSchedule: boolean) => {
    setManualChangesConfirmationSettings({ isVisible: false, callback: null });
    try {
      const res = await submitProject(submitValues, removeManualSchedule, false);
      setProjectData({ id: res.id, status: res?.status, submitType: SubmitType.NEXT_STEP });
    } catch (e) {
      console.error(e);
    }
  };

  const formikValuesRef = useRef<FormikValues>();
  const wasChangesMadeRef = useRef<boolean>();

  formikValuesRef.current = values;
  wasChangesMadeRef.current = wasChangesMade;

  useAutoSave({
    prefix: 'project',
    key: `parameters-${projectId}`,
    isActive: isProjectLoaded && !isProjectProcessing,
    callback: async () => {
      if (!wasChangesMade || isProjectProcessing) return;

      try {
        const isValid = await isFormValid(formikValuesRef.current);

        if (!isValid) {
          scrollIntoView('field-error');
          return;
        }

        project?.has_manual_schedule
          ? setManualChangesConfirmationSettings({ isVisible: true, callback: (resetChanges) => onSaveChanges(values, resetChanges) })
          : await submitProject(formikValuesRef.current, false, true);
      } catch (e) {
        console.error(e);
      }
    },
  });

  useEffect(() => {
    if (areQuestionsLoading || !isProjectLoaded) return;
    eventEmitter.removeAllListeners(EventType.LOGOUT);

    eventEmitter.once(EventType.LOGOUT, async ({ callback }: { callback: () => Promise<void> }) => {
      if (wasChangesMadeRef.current && projectId) {
        const isValid = await isFormValid(formikValuesRef.current);

        if (isValid && project?.status?.state !== StatusState.PENDING && !isProjectProcessing) {
          try {
            project?.has_manual_schedule && wasChangesMade
              ? setManualChangesConfirmationSettings({ isVisible: true, callback: (resetChanges) => onSaveChanges(values, resetChanges) })
              : await submitProject(formikValuesRef.current, false, true);
          } catch (e) {
            notify();
          }
        }
      }
      await callback();
    });

    return () => {
      eventEmitter.removeAllListeners(EventType.LOGOUT);
    };
  }, [areQuestionsLoading, isProjectLoaded, isProjectProcessing]);

  const moveToNextStep = async (submitValues: typeof values) => {
    if (isReadOnly) {
      navigate(`/d/client/${clientId}/project/${projectId}/questions`);
    } else {
      const isValid = await isFormValid(submitValues);

      if (!isValid) {
        scrollIntoView('field-error');

        return;
      }

      project?.has_manual_schedule && wasChangesMade
        ? setManualChangesConfirmationSettings({ isVisible: true, callback: (resetChanges) => onSaveChanges(values, resetChanges) })
        : await onSaveChanges(submitValues, false);

      setIsProjectProcessing(false);
    }
  };

  const addCustomerCallback = ({ name, id }: CustomerResource) => {
    setFieldValue('customer', {
      icon: <div className={styles.form__customerIcon}>{name[0]}</div>, id, value: name, title: `ID #${id}`,
    });
    mutate();
  };

  let isDeletingBudgetValue = false;
  const handleBudgetChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let value = e.target.value.replaceAll(/([^\d.,])|(^[.,]?)/g, '');
    const caret = e.target.selectionStart ?? 0;
    const element = e.target;

    const integerValueLength = value
      .split(numberFormat.decimalSeparator)[0]
      .replaceAll(numberFormat.thousandSeparator, '').length;
    let step = 0;

    if (isDeletingBudgetValue) {
      step = (integerValueLength > 0 && integerValueLength % 3 === 0) ? -1 : 0;
    } else {
      step = (integerValueLength > 3 && integerValueLength % 3 === 1) ? 1 : 0;
    }

    window.requestAnimationFrame(() => {
      element.selectionStart = caret + step;
      element.selectionEnd = caret + step;
    });

    setFieldValue('budget', value);
  };

  const handleBudgetBlur = (e: React.FocusEvent) => {
    if (values.budget) {
      const formatted = values.budget.indexOf(numberFormat.decimalSeparator) < 0
        ? `${values.budget}${numberFormat.decimalSeparator}00`
        : `${Math.round(transformToNumber(values.budget))}${numberFormat.decimalSeparator}00`;
      setFieldValue('budget', formatted);
    }

    handleBlur(e);
  };

  const handlerBudgetFocus = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (values.budget.indexOf(numberFormat.decimalSeparator) > 0) {
      const formatted = values.budget.replaceAll(`${numberFormat.decimalSeparator}00`, '');
      const element = e.target;
      setFieldValue('budget', formatted);

      window.requestAnimationFrame(() => {
        element.selectionStart = 999;
        element.selectionEnd = 999;
      });
    }
  };

  const nextStepDisabled = isReadOnly && project.step <= 1;

  const getAiHelp = async () => {
    try {
      setIsAiLoadingScreenVisible(true);

      const body = {
        project_name: values?.name,
        customer_id: values?.customer?.id,
        description: values?.description,
      };
      const { statusCode, response } = await apiClient
        .post(`clients/${clientId}/projects/prefilling-questions-first-step`, {
          body: JSON.stringify(body),
        });

      if (statusCode === 200) {
        Object.entries(response).forEach(([questionId, answers]) => {
          const fieldName = questionsMap[Number(questionId)];

          if (fieldName) {
            if (questionId === '13') { // Processes / Business Domains field that can have multiple values
              const answerIds: Array<string> = answers.map((value: number) => value.toString());
              setFieldValue(fieldName, handleAddDomainGroup(answerIds)?.sort());
            } else {
              const answerId: string = answers?.[0]?.toString();
              setFieldValue(fieldName, answerId);
            }
          }
        });
      } else {
        throw new Error(t('Requests have exceeded the token rate limit. Please retry after a few minutes.'));
      }
    } catch (e) {
      notify({ text: { body: e?.message } });
      console.error(e);
    } finally {
      setIsAiLoadingScreenVisible(false);
    }
  };

  const calculateLengthWithoutTags = (input: string) => {
    const div = document.createElement('div');
    div.innerHTML = input;
    const cleanStr = div.textContent || div.innerText || '';
    return cleanStr.length;
  };

  const descriptionLength = useMemo(() => {
    if (values?.description) {
      return calculateLengthWithoutTags(values.description);
    }
    return 0;
  }, [values?.description]);

  if (isAiLoadingScreenVisible) {
    return (
      <div className={styles.aiLoadingWrapper}>
        <Loader />
        <p className={styles.aiLoadingWrapper__text}>
          {t('Pro tip: You can change the color of ZEEMLESS\' appearance to fit your corporate identity.')}
        </p>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <h3>{t('Project parameters')}</h3>
      {(areQuestionsLoading || areTemplatesLoading || projectData?.status?.state === StatusState.PENDING) ? (
        <div className={styles.loader}>
          <Loader size={32} />
          {projectData?.status?.state === StatusState.PENDING && <p>{t('Saving draft')}</p>}
        </div>
      ) : (
        <>
          <form
            className={styles.form}
          >
            <Input
              value={values.name}
              setValue={handleChange}
              onBlur={handleBlur}
              id='name'
              label={t('Enter project name')}
              name='name'
              error={!!(touched.name && errors.name)}
              errorMessage={errors.name}
              disabled={isReadOnly}
            />
            <div className={styles.form__block}>
              <DatePicker
                label={t('Enter project start')}
                format={userData?.user.dateFormat ?? DEFAULT_DATE_FORMAT}
                value={values.begin}
                setValue={date => setFieldValue('begin', date)}
                error={!!(touched.begin && errors.begin)}
                errorMessage={errors.begin}
                disabled={isReadOnly}
              />
              <span className={styles.form__divider} />
              <DatePicker
                label={t('Enter end of the project')}
                format={userData?.user.dateFormat ?? DEFAULT_DATE_FORMAT}
                value={values.end}
                setValue={date => setFieldValue('end', date)}
                error={!!(touched.end && errors.end)}
                errorMessage={errors.end}
                disabled={isReadOnly}
              />
            </div>
            <Select
              label={t('Project type')}
              options={formatTemplates(templates, t)}
              value={values.type}
              setValue={handleChange}
              onBlur={handleBlur}
              disabled={!hasDraftStatus || isReadOnly}
              labelId='type'
              name='type'
              error={!!(touched.type && errors.type)}
              errorMessage={errors.type}
            />
            <Select
              label={t('Project methodology')}
              options={methodologies?.answers
                ? methodologies.answers.map(methodology => ({
                  caption: methodology.caption,
                  value: methodology.id.toString(),
                })) : []}
              value={values.methodology}
              setValue={handleChange}
              onBlur={handleBlur}
              labelId='methodology'
              name='methodology'
              error={!!(touched.methodology && errors.methodology)}
              errorMessage={errors.methodology}
              disabled={isReadOnly}
            />
            {isDependFieldVisible(sprints) && (
              <Select
                label={t('Sprint length')}
                options={sprints?.answers
                  ? sprints.answers.map(sprint => ({ caption: sprint.caption, value: sprint.id.toString() })) : []}
                value={values.sprintLength}
                setValue={handleChange}
                onBlur={handleBlur}
                labelId='sprintLength'
                name='sprintLength'
                error={!!(touched.sprintLength && errors.sprintLength)}
                errorMessage={errors.sprintLength}
                disabled={isReadOnly}
              />
            )}
            {/* {hasPermission(Permissions.SALEFORCES) && ( */}
            {/*  <Input */}
            {/*    value={values.salesForce} */}
            {/*    setValue={handleChange} */}
            {/*    onBlur={handleBlur} */}
            {/*    id='salesForce' */}
            {/*    label={t('Salesforce opportunity ID')} */}
            {/*    name='salesForce' */}
            {/*    error={!!(touched.salesForce && errors.salesForce)} */}
            {/*    errorMessage={errors.salesForce} */}
            {/*    disabled={isReadOnly} */}
            {/*  /> */}
            {/* )} */}
            <Autocomplete
              label={t('Customer')}
              selected={values.customer}
              onChange={(customer) => setFieldValue('customer', customer)}
              options={customers?.length ? customers.map(({ name, id, deleted }) => (
                {
                  icon: (
                    <div className={classnames(styles.form__customerIcon, {
                      [styles.inactive]: deleted,
                    })}
                    >
                      {name[0]}
                    </div>
                  ),
                  id,
                  value: name + (deleted ? ` (${t('deleted')})` : ''),
                  title: `ID #${id}`,
                  inactive: deleted,
                }
              )) : []}
              customSearch={setSearch}
              error={!!(touched.customer && errors.customer)}
              errorMessage={errors.customer}
              ctaButton={hasPermission(Permissions.CUSTOMER_CREATE) ? (
                <Button
                  type='button'
                  className={styles.form__cta}
                  onClick={() => setIsDrawerOpen(true)}
                >
                  {t('Add new customer')}
                </Button>
              ) : null}
              disabled={isReadOnly}
            />
            <Select
              label={t('Processes / Business Domains')}
              value={values.domains}
              setValue={(domainValues) => {
                // It is required to sort multiselect value in order to compare initial values and changes made
                if (domainValues) {
                  setFieldValue('domains', handleAddDomainGroup(domainValues as Array<string> | Array<string | Array<string>>)?.sort());
                }
              }}
              multiple
              onBlur={e => {
                setDomainSearch('');
                handleBlur(e);
              }}
              labelId='domains'
              name='domains'
              error={!!(touched.domains && errors.domains)}
              errorMessage={errors.domains}
              disabled={isReadOnly}
              paperClassName={styles.form__domainSelectPaper}
              className={styles.form__domainSelect}
              menuProps={{ autoFocus: false }}
              renderValue={(selected) => (
                <div className={styles.form__selectedDomainsWrapper}>
                  {Array.isArray(selected) && selected.map((value) => (
                    <Chip
                      key={value}
                      label={(domains?.answers || []).find(domain => domain.id.toString() === value)?.caption}
                      onDelete={() => {
                        setFieldValue('domains', values.domains.filter((domain: string) => domain !== value));
                      }}
                      onMouseDown={(e) => {
                        e.stopPropagation();
                      }}
                      deleteIcon={(
                        <svg
                          height={16}
                          width={16}
                        >
                          <use
                            height={16}
                            width={16}
                            xlinkHref={`${CloseSVG}#closeSVG`}
                            href={`${CloseSVG}#closeSVG`}
                          />
                        </svg>
                      )}
                    />
                  ))}
                </div>
              )}
            >
              <ListSubheader>
                <TextField
                  size='small'
                  autoFocus
                  placeholder={t('Search')}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <GridSearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={domainSearch}
                  onChange={(e) => {
                    setDomainSearch(e.target.value);
                    setFilteredDomains(domains?.answers
                      .filter(domain => domain.caption.toLowerCase().includes(e.target.value.toLowerCase())) || []);
                  }}
                  onKeyDown={(e) => {
                    if (e.key !== 'Escape') {
                      // Prevents autoselecting item while typing (default Select behaviour)
                      e.stopPropagation();
                    }
                  }}
                />
              </ListSubheader>
              {((domainSearch ? filteredDomains : domains?.answers)?.map((option) => (
                <MenuItem
                  className={classnames(styles.form__menuItem, { [styles.form__menuItem_groupHeading]: !option.group_id })}
                  key={option.id}
                  value={option.group_id
                    ? option.id.toString()
                    : domains?.answers
                      .filter(item => item.group_id === option.id)
                      .map(item => item.id.toString())}
                >
                  <CheckboxItem
                    value={option.group_id
                      ? values.domains.includes(option.id.toString())
                      : isDomainGroupSelected(values.domains, domains, option.id.toString())}
                    indeterminate={
                      option.group_id
                        ? false
                        : (isDomainGroupIndeterminate(values.domains, domains, option.id.toString())
                          && !isDomainGroupSelected(values.domains, domains, option.id.toString()))
                    }
                  />
                  {option.caption}
                </MenuItem>
              )))}
              {domainSearch && !filteredDomains.length && <span className={styles.form__noResults}>{t('No results')}</span>}
            </Select>
            <div className={styles.form__inputTooltipWrapper}>
              <NumericFormat
                thousandSeparator={numberFormat.thousandSeparator}
                decimalSeparator={numberFormat.decimalSeparator}
                valueIsNumericString={false}
                allowedDecimalSeparators={[',', '.']}
                allowNegative={false}
                customInput={Input}
                value={values.budget}
                setValue={handleBudgetChange}
                onBlur={handleBudgetBlur}
                onFocus={handlerBudgetFocus}
                id='budget'
                label={t('Budget')}
                name='budget'
                error={!!(touched.budget && errors.budget)}
                errorMessage={errors.budget}
                inputProps={{ min: 0, autoComplete: 'off' }}
                disabled={isReadOnly}
              />
              <Tooltip
                arrow
                title={t('Budget for the entire configured scope')}
                placement='bottom-start'
                enterTouchDelay={0}
              >
                <svg className={styles.deliverable__infoIcon}>
                  <use
                    xlinkHref={`${InfoSVG}#infoSVG`}
                    href={`${InfoSVG}#infoSVG`}
                  />
                </svg>
              </Tooltip>
            </div>
            <Select
              label={t('Number of systems involved')}
              options={systemsNumbers?.answers
                ? systemsNumbers.answers.map(systemsNumber => ({
                  caption: systemsNumber.caption,
                  value: systemsNumber.id.toString(),
                })) : []}
              value={values.systemsInvolved}
              setValue={handleChange}
              onBlur={handleBlur}
              labelId='systemsInvolved'
              name='systemsInvolved'
              error={!!(touched.systemsInvolved && errors.systemsInvolved)}
              errorMessage={errors.systemsInvolved}
              disabled={isReadOnly}
            />
            <Select
              label={t('Number of project employees')}
              options={employeesNumbers?.answers
                ? employeesNumbers.answers
                  .map(employeesNumber => ({
                    caption: employeesNumber.caption,
                    value: employeesNumber.id.toString(),
                  })) : []}
              value={values.employees}
              setValue={handleChange}
              onBlur={handleBlur}
              labelId='employees'
              name='employees'
              error={!!(touched.employees && errors.employees)}
              errorMessage={errors.employees}
              disabled={isReadOnly}
            />
          </form>
          {/* moved out of form because of that chinese texteditor which can't set proper width in grid mode */}
          <div className={styles.form__footer}>
            <div className={styles.form__subtitleBlock}>
              <h5 className={styles.form__subtitle}>{t('Description')}</h5>
              <Tooltip
                arrow
                title={(
                  <div className={styles.tooltip}>
                    <p className={styles.tooltip__title}>{t('Get AI Help')}</p>
                    {/* eslint-disable-next-line max-len */}
                    <p className={styles.tooltip__text}>{t('Our AI tools can help filling out this page for you. To get this done please fill the “Project name” and “Customer” fields and add a project description (min. 250 characters)')}</p>
                  </div>
                )}
                placement='bottom-start'
                enterTouchDelay={0}
              >
                <span>
                  <Button
                    type='button'
                    icon={(
                      <svg>
                        <use
                          xlinkHref={`${AiSVG}#aiSVG`}
                          href={`${AiSVG}#aiSVG`}
                        />
                      </svg>
                )}
                    iconSize={{ width: 12, height: 12 }}
                    className={styles.form__subtitleButton}
                    onClick={getAiHelp}
                    disabled={descriptionLength <= 250 || !values?.customer || !values?.name || isReadOnly}
                  >
                    {t('Get AI Help')}
                  </Button>
                </span>
              </Tooltip>
            </div>
            <TextEditor
              value={values.description}
              setValue={(html) => setFieldValue('description', html)}
              error={!!(touched.description && errors.description)}
              errorMessage={errors.description}
              readOnly={isReadOnly}
            />
          </div>
          <div className={styles.form__buttons}>
            <Button
              type='button'
              onClick={() => moveToNextStep(values)}
              disabled={nextStepDisabled || isProjectProcessing}
              loading={isProjectProcessing}
            >
              {t('Next steps')}
            </Button>
          </div>
        </>
      )}
      <Drawer
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        title={t('Add new customer')}
        className={styles.drawer}
      >
        <AddCustomerForm
          closeModal={() => setIsDrawerOpen(false)}
          clientId={clientId}
          addCustomerCallback={addCustomerCallback}
        />
      </Drawer>
    </div>
  );
};

export default NewProjectParameters;
