import { atom } from 'jotai';
import { atomWithStore } from 'jotai-zustand';
import { createStore, StoreApi } from 'zustand/vanilla';

export type CustomLogoStoreType = {
  logoUrl: string;
  id?: number;
};

export type PreviewLogoType = {
  logo: FileWithPreview;
  id?: number;
};

export const previousColorPalette = atom<{ [key: string]: string }>({});

export const clientPreviewId: StoreApi<number | null> = createStore(() => null);
export const clientPreviewIdAtom = atomWithStore(clientPreviewId);

export interface FileWithPreview extends File {
  preview: string;
}
export const previewLogoAtom = atom<PreviewLogoType | null>(null);

export const customLogoStore: StoreApi<CustomLogoStoreType | null> = createStore(() => null);
export const customLogoAtom = atomWithStore(customLogoStore);
