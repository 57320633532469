import { useSetAtom } from 'jotai';
import { FormikValues, useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import useSWR from 'swr';
import { useNavigate, useParams } from 'react-router-dom';
import InfoSVG from '../../../../../public/media/info.svg';
import { activeTask } from '../../../NewProject/ManageDeliverables/manageDeliverables.atom.ts';
import styles from './DeliverableForm.module.scss';
import Button from '../../../../UIKit/Button/Button.tsx';
import Select from '../../../../UIKit/Select/Select.tsx';
import apiClient from '../../../../../apiClient.ts';
import Loader from '../../../../Loader/Loader.tsx';
import { Deliverable } from '../../../NewProject/NewProjectDeliverables/types.ts';
import { Permissions } from '../../../Login/user.props.ts';
import { usePermissions } from '../../../../../usePermission.ts';
import { useCustomTranslation } from '../../../../../useAppTranslate.tsx';

type DeliverableFormProps = {
  submit: (value: FormikValues) => void
};

export enum DeliverableType {
  EXISTING = 1,
  NEW = 2,
}

const AddDeliverableForm = ({ submit }: DeliverableFormProps) => {
  const { t, i18n } = useCustomTranslation();
  const { projectId, clientId } = useParams();
  const setCurrentTask = useSetAtom(activeTask);
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();

  const { data, isLoading } = useSWR(
    [
      `projects/${projectId}/deliverables?quality_gate&page=all`,
      i18n.language,
      projectId,
    ],
    ([url]) => apiClient
      .get<{ data: Deliverable[] }>(url).then(({ response }) => response),
    {
      revalidateOnFocus: false,
      keepPreviousData: false,
    },
  );

  const deliverables = data?.data ?? [];

  const {
    values,
    handleSubmit,
    isSubmitting,
    handleChange,
    handleBlur,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      type: DeliverableType.EXISTING,
      deliverable: '',
      titleEn: '',
      titleDe: '',
      dueDate: null,
    },
    validationSchema: Yup.object({
      deliverable: Yup.string()
        .when(['type'], (type, schema) => (+type === 1 ? schema.required(t('Deliverable is required')) : schema)),
      titleEn: Yup.string()
        .trim()
        .max(100, t('Must be 100 characters at most'))
        .when(['type'], (type, schema) => (+type === 2 ? schema.required(t('English title is required')) : schema)),
      titleDe: Yup.string()
        .trim()
        .max(100, t('Must be 100 characters at most'))
        .when(['type'], (type, schema) => (+type === 2 ? schema.required(t('German title is required')) : schema)),
      dueDate: Yup.date().nullable().transform((value) => {
        if (dayjs(value).isValid()) {
          return value;
        }
      })
        .min(dayjs('01/01/2000').toDate(), t('Start date can not be set earlier than 01.01.2000'))
        .max(dayjs('01/01/2500').toDate(), t('The end date cannot be later than 01.01.2500'))
        .when(['type'], (type, schema) => (+type === 2 ? schema.required(t('Due date is required')) : schema)),
    }),
    onSubmit: submit,
  });

  return (
    <form
      onSubmit={handleSubmit}
      className={styles.form}
    >
      <div className={styles.form__inner}>
        {isLoading ? (
          <div className={styles.form__block_loader}>
            <Loader size={24} />
          </div>
        ) : (
          <Select
            paperClassName={styles.form__block_select}
            label={t('Select deliverables')}
            options={deliverables.map(({ caption, id }) => ({ caption, value: `${id}` }))}
            value={values.deliverable}
            setValue={handleChange}
            onBlur={handleBlur}
            labelId='deliverable'
            name='deliverable'
            error={!!(touched.deliverable && errors.deliverable)}
            errorMessage={errors.deliverable}
          />
        )}
        {hasPermission(Permissions.DELIVERABLE_UPDATE) && (
        <div className={styles.newDeliverable}>
          <svg className={styles.newDeliverable__infoIcon}>
            <use
              xlinkHref={`${InfoSVG}#infoSVG`}
              href={`${InfoSVG}#infoSVG`}
            />
          </svg>
          <p>
            {t('In order to add a new deliverable that does not exist yet, create it first via')}
            {' '}
            <button
              style={{ textTransform: i18n.language === 'de' ? 'none' : 'lowercase' }} // translation fix
              type='button'
              onClick={() => {
                setCurrentTask(null);
                navigate(`/d/client/${clientId}/project/${projectId}/manage-deliverables`);
              }}
            >
              {t('Manage deliverables')}
            </button>
            .
          </p>
        </div>
        )}
      </div>
      <div className={styles.form__footer}>
        <Button
          type='submit'
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          {t('Confirm')}
        </Button>
      </div>
    </form>
  );
};

export default AddDeliverableForm;
