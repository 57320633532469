import classNames from 'classnames';
import { GridFilterInputValueProps } from '@mui/x-data-grid';
import { useAtom, useAtomValue } from 'jotai';
import { ChangeEvent, useState } from 'react';

import Input from '../../Input/Input';
import CheckboxItem from '../../CheckboxItem/CheckboxItem';

import { filters, tableFiltersOptions } from '../../../../store/table';
import { useCustomTranslation } from '../../../../useAppTranslate';

import styles from '../Table.module.scss';

function OptionsListFilter(props: GridFilterInputValueProps & { filterName: string }) {
  const { t } = useCustomTranslation('global');
  const { item, applyValue, filterName } = props;
  const filtersOptions = useAtomValue(tableFiltersOptions);
  const [search, setSearch] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<null | Record<string, any>[]>();
  const [currentFilters, setCurrentFilters] = useAtom(filters);

  const onCheckboxChange = (
    e: ChangeEvent<HTMLInputElement>,
    optionValue: number,
    options: Record<string, any>[],
    selectedOptions: number[] | null,
  ) => {
    if (e.target.checked) {
      applyValue({ ...item, value: [...(selectedOptions ?? []), optionValue] });
    } else {
      const newValue = !selectedOptions
        ? options.map((option) => option.id ?? option.value).filter((id) => id !== optionValue)
        : selectedOptions.filter((id) => id !== optionValue);
      applyValue({ ...item, value: newValue });
    }
  };

  const savedItem = currentFilters?.items?.find((itm) => itm?.field === props?.item.field);

  return (
    <div className={classNames(styles.filter__body, styles.filter_options)}>
      <p className={styles.filter__title}>Filters</p>
      <Input
        id='filter_search'
        label={t('Search')}
        value={search}
        className={styles.filter__search}
        setValue={(e) => {
          setSearch(e.target.value);
          const value = e.target.value.toLowerCase();
          setFilteredOptions(filtersOptions[filterName].filter((option: any) => option.caption.toLowerCase().includes(value)));
        }}
      />
      <div className={styles.filter__list}>
        {(filteredOptions ?? filtersOptions[filterName])?.map((option: any) => {
          const optionValue = option?.value ?? option.id;
          return (
            <CheckboxItem
              key={optionValue}
              value={savedItem?.value ? savedItem.value?.includes(optionValue) : true}
              onChange={(e) => onCheckboxChange(
                e,
                optionValue,
                filtersOptions[filterName],
                currentFilters?.items?.find((scope) => scope?.field === props?.item.field)?.value,
              )}
              label={option?.caption}
              fontSize={12}
            />
          );
        })}
      </div>
      <button
        type='button'
        className={styles.filter__clearBtn}
        onClick={() => {
          const filtered = currentFilters?.items?.filter((scope) => scope?.field !== item.field) || [];
          setCurrentFilters({
            ...currentFilters,
            items: filtered,
          });
          applyValue({ ...item, value: null });
        }}
      >
        {t('Clear filter')}
      </button>
    </div>
  );
}

export default OptionsListFilter;
