import { GridFilterInputValueProps } from '@mui/x-data-grid';

import classNames from 'classnames';
import { useAtom } from 'jotai';

import Input from '../../Input/Input';

import SeacrhSVG from '../../../../public/media/search.svg';
import CloseSVG from '../../../../public/media/close.svg';

import { filters } from '../../../../store/table';
import { useCustomTranslation } from '../../../../useAppTranslate';

import styles from '../Table.module.scss';

function SearchFilter(props: GridFilterInputValueProps) {
  const { t } = useCustomTranslation('global');
  const { item, applyValue } = props;
  const [currentFilters, setCurrentFilters] = useAtom(filters);

  const title = props?.apiRef.current?.getColumn(props?.item?.field).headerName?.toLowerCase() || '';
  return (
    <div className={styles.filter__body}>
      <p className={styles.filter__title}>
        {t(`${title} searching`)}
      </p>
      <div className={styles.filter__search}>
        <svg className={styles.filter__searchIcon}>
          <use
            xlinkHref={`${SeacrhSVG}#searchSVG`}
            href={`${SeacrhSVG}#searchSVG`}
          />
        </svg>
        <Input
          label=''
          id='filterSearch'
          value={currentFilters?.items?.find((itm) => itm?.field === item.field)?.value ?? ''}
          setValue={(e) => {
            applyValue({ ...item, value: e.target.value });
          }}
          className={classNames(styles.filter__input, styles.filter__input_search)}
        />
        <button
          type='button'
          className={styles.filter__clearBtn_small}
          onClick={() => {
            const filtered = currentFilters?.items?.filter((scope) => scope?.field !== item.field) || [];
            setCurrentFilters({
              ...currentFilters,
              items: filtered,
            });
            applyValue({ ...item, value: null });
          }}
        >
          <svg className={styles.filter__clearIcon}>
            <use
              xlinkHref={`${CloseSVG}#closeSVG`}
              href={`${CloseSVG}#closeSVG`}
            />
          </svg>
        </button>
      </div>
    </div>
  );
}

export default SearchFilter;
