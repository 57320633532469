import { LoaderFunctionArgs } from 'react-router-dom';
import { getProjectDetails } from './new-project.loader.ts';

export const newProjectQuestionsLoader = async ({ params }: LoaderFunctionArgs<Record<string, string>>) => {
  let project = null;

  if (params.clientId && params.projectId) {
    project = await getProjectDetails(params.clientId, params.projectId);
  }

  return { project };
};
