import { resetColorPalette } from './actions/clientCiLoader.ts';
import { generatePalette } from './colorPaletteGenerator.ts';
import { ClientProps } from './components/AdminSidebar/client.props.ts';

export const updateCI = (client: ClientProps, setCustomLogo: (logo: { logoUrl: string, id: number } | null) => void) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { id, primary_accent, logo } = client;
  const currentPrimaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-500');
  if (primary_accent) {
    if (currentPrimaryColor !== primary_accent) {
      const primaryColorPalette = generatePalette(primary_accent, 'primary');
      Object.entries(primaryColorPalette).forEach(([key, value]) => {
        document.documentElement.style.setProperty(key, value);
      });
    }
  } else {
    resetColorPalette();
  }
  setCustomLogo(logo ? { id, logoUrl: logo } : null);
};
