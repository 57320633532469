import React, { ReactElement, startTransition } from 'react';
import {
  NavLink, useLocation, useNavigate, useParams,
} from 'react-router-dom';
import { CircularProgressbarWithChildren as CircleProgressBar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { PrimitiveAtom } from 'jotai/vanilla/atom';
import { useMediaQuery } from '@mui/material';
import { ProjectResource } from '../../Projects/types.ts';
import styles from './NewProjectTabs.module.scss';

import ToggleSVG from '../../../../public/media/Toggle.svg';
import InfoSVG from '../../../../public/media/info.svg';
import QuestionSVG from '../../../../public/media/question.svg';
import SummarySVG from '../../../../public/media/Summary.svg';
import AngleDownSVG from '../../../../public/media/angle-down.svg';
import { ProgressAtomsType } from '../types.ts';
import Button, { ButtonIconPosition, ButtonVariants } from '../../../UIKit/Button/Button.tsx';
import { usePermissions } from '../../../../usePermission.ts';
import { Permissions } from '../../Login/user.props.ts';
import { MOBILE_MEDIA_QUERY, MOBILE_SMALL_MEDIA_QUERY } from '../../../../constants.ts';
import { useCustomTranslation } from '../../../../useAppTranslate.tsx';

const CircleProgress = ({ atom, children }: { atom: PrimitiveAtom<number>, children: ReactElement }) => {
  const value = useAtomValue(atom);
  return (
    <CircleProgressBar
      value={value}
      background
    >
      {children}
    </CircleProgressBar>
  );
};

const Tab = ({
  children, url, disabled, isChildrenActive,
}: { children: React.ReactNode, url: string, disabled: boolean, isChildrenActive?: boolean }) => {
  const classes = {
    [styles.disabled]: disabled,
    [styles.inactive]: url === 'summary' && disabled,
    [styles.summary]: url === 'summary',
  };

  return disabled ? (
    <div className={classNames(styles.tab, { ...classes })}>
      {children}
    </div>
  ) : (
    <NavLink
      end
      className={({ isActive }) => classNames(styles.tab, {
        [styles.active]: isActive || isChildrenActive,
        ...classes,
      })}
      to={url}
    >
      {children}
    </NavLink>
  );
};

const NewProjectTabs = ({ project, progressAtoms }: { project: ProjectResource, progressAtoms: ProgressAtomsType }) => {
  const { t } = useCustomTranslation('global');
  const navigate = useNavigate();
  const { clientId } = useParams();
  const { pathname } = useLocation();
  const { hasPermission } = usePermissions();

  const isMobileDevice = useMediaQuery(MOBILE_MEDIA_QUERY);
  const isMobileSmallDevice = useMediaQuery(MOBILE_SMALL_MEDIA_QUERY);

  const split = pathname.split('/');

  const isNewProjectRoute = pathname.includes('/new-project');
  const isSummaryChildren = ['quality-gates'].includes(split[split.length - 1]) || ['schedule'].includes(split[split.length - 1]);
  const backLink = hasPermission(Permissions.ADMIN) ? `/m/client/${clientId}/projects` : `/d/client/${clientId}/projects`;

  const tabs = [
    {
      url: '',
      atom: progressAtoms.parameters,
      disabled: false,
      title: 'Project parameters',
      icon: { path: InfoSVG, id: 'infoSVG' },
    },
    {
      url: 'questions',
      atom: progressAtoms.questions,
      disabled: isNewProjectRoute,
      title: 'Project specific questions',
      icon: { path: QuestionSVG, id: 'questionSVG' },
    },
    {
      url: 'deliverables',
      atom: progressAtoms.deliverables,
      disabled: isNewProjectRoute || project?.step < 2,
      title: 'Select deliverables',
      icon: { path: ToggleSVG, id: 'toggleSVG' },
    },
  ].map(tab => ({
    ...tab,
    onClick: (e: Event) => {
      e.preventDefault();
      startTransition(() => navigate(tab.url));
    },
  }));

  const projectSummary = {
    url: 'summary',
    disabled: isNewProjectRoute || project?.step < 3,
    title: isMobileSmallDevice ? 'Summary' : 'Project summary',
    icon: { path: SummarySVG, id: 'summarySVG' },
    isChildrenActive: isSummaryChildren,
    end: true,
    onClick: (e: Event) => {
      e.preventDefault();
      startTransition(() => navigate('summary'));
    },
  };

  return (
    <header className={styles.header}>
      <Button
        type='button'
        className={styles.backButton}
        variant={ButtonVariants.SECONDARY}
        onClick={() => navigate(backLink)}
        icon={(
          <svg>
            <use
              xlinkHref={`${AngleDownSVG}#angleDownSVG`}
              href={`${AngleDownSVG}#angleDownSVG`}
            />
          </svg>
        )}
        iconPosition={ButtonIconPosition.CENTER}
        iconSize={{ width: 18, height: 18 }}
      />
      <div className={styles.tabs}>
        <div className={styles.tabs__content}>
          <div className={styles.steps}>
            {tabs.map(tab => (
              <Tab
                key={tab.url}
                {...tab}
              >
                <div className={styles.tab__status}>
                  {tab.atom ? (
                    <CircleProgress atom={tab.atom}>
                      <svg className={styles.tab__status__icon}>
                        <use
                          xlinkHref={`${tab.icon.path}#${tab.icon.id}`}
                          href={`${tab.icon.path}#${tab.icon.id}`}
                        />
                      </svg>
                    </CircleProgress>
                  ) : (
                    <svg className={styles.tab__status__icon}>
                      <use
                        xlinkHref={`${tab.icon.path}#${tab.icon.id}`}
                        href={`${tab.icon.path}#${tab.icon.id}`}
                      />
                    </svg>
                  )}
                </div>
                {!isMobileDevice && <p className={styles.tab__title}>{t(tab.title)}</p>}
              </Tab>
            ))}
          </div>
          {!isMobileDevice && <div className={styles.rectangle} />}
          <Tab
            key={projectSummary.url}
            {...projectSummary}
          >
            <div className={styles.tab__status}>
              <svg className={styles.tab__status__icon}>
                <use
                  xlinkHref={`${projectSummary.icon.path}#${projectSummary.icon.id}`}
                  href={`${projectSummary.icon.path}#${projectSummary.icon.id}`}
                />
              </svg>
            </div>
            <p className={styles.tab__title}>{t(projectSummary.title)}</p>
          </Tab>
        </div>
      </div>
    </header>
  );
};

export default NewProjectTabs;
