import { useBlocker, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useShortPolling } from '../../../useShortPolling.ts';

const startSession = (key: string, duration: number) => {
  const session = sessionStorage.getItem(key);

  if (!session) {
    sessionStorage.setItem(key, `${duration}`);
  }
};
const getSessionValue = (key: string) => {
  const value = sessionStorage.getItem(key);
  return value ? +value : null;
};
const updateSessionValue = (key: string, left: number) => {
  sessionStorage.setItem(key, `${left}`);
};
const removeSessionItem = (key: string) => {
  sessionStorage.removeItem(key);
};

let isRouteChanged = false;
export const useAutoSave = ({
  prefix,
  key,
  isActive = false,
  callback = async () => {},
  duration = 15 * 60 * 1000,
  interval = 1000,
}: {
  isActive: boolean,
  prefix: string,
  key: string,
  callback: () => Promise<void>,
  duration?: number,
  interval?: number,
}) => {
  const sessionKey = `${prefix}:${key}`;
  const [isEnabled, setIsEnabled] = useState(false);
  const location = useLocation();
  useBlocker(
    ({ currentLocation, nextLocation }) => {
      isRouteChanged = currentLocation.pathname !== nextLocation.pathname;
      return false;
    },
  );

  useEffect(() => {
    if (!isActive) return;

    startSession(sessionKey, duration);
    setIsEnabled(isActive);
  }, [isActive]);

  const navigationCallback = async () => {
    await callback();
    removeSessionItem(sessionKey);
    isRouteChanged = false;
  };

  useEffect(() => {
    if (isRouteChanged) {
      navigationCallback();
    }

    return () => {
      Object.keys(sessionStorage).forEach((storageKey) => {
        if (storageKey !== sessionKey && storageKey.startsWith(prefix)) {
          removeSessionItem(storageKey);
        }
      });
    };
  }, [location.pathname, isRouteChanged]);

  useShortPolling(async () => {
    const value = getSessionValue(sessionKey);

    if (!value) return;

    const left = value - interval;

    if (left === 0) {
      removeSessionItem(sessionKey);

      await callback();
      startSession(sessionKey, duration);
    } else {
      updateSessionValue(sessionKey, left);
    }
  }, { isEnabled, interval });
};
