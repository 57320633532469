import { useAtom, useAtomValue } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useResetAtom } from 'jotai/utils';
import { useMatch, useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

import LogoutSVG from '../../public/media/logout.svg';
import { clientPreviewIdAtom, previousColorPalette } from '../../store/clientCI.ts';
import Button, { ButtonVariants } from '../UIKit/Button/Button';
import LangSelector from '../LangSelector/LangSelector';
import Modal from '../UIKit/Modal/Modal';
import {
  clientPermissionsAtom, globalPermissionsAtom, latestReleaseAtom, releaseBannerAtom, userAtom, userClientAtom,
} from '../../store/auth.ts';
import apiClient from '../../apiClient.ts';
import UserDropdown from './UserDropdown/UserDropdown';
import AvatarPlaceholderSVG from '../../public/media/avatar-placeholder.svg';
import styles from './Header.module.scss';
import GlobalSearch from './GlobalSearch/GlobalSearch.tsx';
import NotificationCounter from '../NotificationCounter/NotificationCounter.tsx';
import { tablesSortConfig } from '../../store/table.ts';
import { eventEmitter, EventType } from '../../eventEmitter.ts';
import { notify } from '../../store/notifications.ts';
import SupportResourcesMenu from './SupportResourcesMenu/SupportResourcesMenu.tsx';
import { useCustomTranslation } from '../../useAppTranslate.tsx';

type HeaderContentProps = {
  isModalOpened: boolean,
  setIsModalOpened: (value: boolean) => void
};
const HeaderContent = ({ isModalOpened, setIsModalOpened }: HeaderContentProps) => {
  const isProjectParametersPage = useMatch('d/client/:clientId/project/:projectId');
  const isProjectQuestionsPage = useMatch('d/client/:clientId/project/:projectId/questions');

  const [isLogoutAllowed, setIsLogoutAllowed] = useState(false);

  const { t } = useCustomTranslation('global');
  const navigate = useNavigate();

  const userData = useAtomValue(userAtom);
  const resetUser = useResetAtom(userAtom);
  const resetReleaseBanner = useResetAtom(releaseBannerAtom);
  const resetGlobalPermissions = useResetAtom(globalPermissionsAtom);
  const resetClientPermissions = useResetAtom(clientPermissionsAtom);
  const resetTablesSortConfig = useResetAtom(tablesSortConfig);
  const resetUserClient = useResetAtom(userClientAtom);
  const [clientPreviewId, setClientPreviewId] = useAtom(clientPreviewIdAtom);
  const prevColorPalette = useAtomValue(previousColorPalette);
  const latestRelease = useAtomValue(latestReleaseAtom);

  const logout = async () => {
    try {
      const { statusCode, response } = await apiClient.post<{ message?: string }>('auth/logout');

      if (statusCode === 200) {
        setIsModalOpened(false);

        resetUser();
        resetGlobalPermissions();
        resetClientPermissions();
        resetTablesSortConfig();
        resetUserClient();
        resetReleaseBanner();

        return navigate('/login');
      } else {
        notify({ text: { title: t('Logout error'), body: response.message ?? '' } });
      }
    } catch (e) {
      notify();
    }
  };

  useEffect(() => {
    if (!isLogoutAllowed) return;

    logout().finally(() => setIsLogoutAllowed(false));
  }, [isLogoutAllowed]);

  const handleLogout = async () => {
    if (isProjectParametersPage || isProjectQuestionsPage) {
      eventEmitter.emit(EventType.LOGOUT, { callback: () => setIsLogoutAllowed(true) });
    } else {
      setIsLogoutAllowed(true);
    }
    if (clientPreviewId !== null) {
      Object.entries(prevColorPalette).forEach(([key, value]) => {
        document.documentElement.style.setProperty(key, value);
      });
      setClientPreviewId(null);
    }
  };

  const isSmallerThanTabletLandscapeScreen = useMediaQuery('(max-width: 1024px)');

  return (
    <>
      <div className={styles.content}>
        {userData?.token && (
          <GlobalSearch />
        )}
        <NotificationCounter
          count={latestRelease?.unread ?? 0}
          ariaLabel={t('View all notifications')}
        />
        <LangSelector />
        {userData?.token && (
          <>
            {!isSmallerThanTabletLandscapeScreen && <SupportResourcesMenu />}
            <UserDropdown
              user={userData.user}
              t={t}
            />
            <button
              type='button'
              className={styles.logout}
              onClick={() => setIsModalOpened(true)}
            >
              <svg className={styles.logoutButton}>
                <use
                  xlinkHref={`${LogoutSVG}#logoutSVG`}
                  href={`${LogoutSVG}#logoutSVG`}
                />
              </svg>
            </button>
          </>
        )}
      </div>

      {isModalOpened && (
        <Modal
          closeModal={() => setIsModalOpened(false)}
          rootClassName={styles.modal}
        >
          <div>
            <div className={styles.user}>
              <svg className={styles.user__placeholder}>
                <use
                  xlinkHref={`${AvatarPlaceholderSVG}#avatarPlaceholderSVG`}
                  href={`${AvatarPlaceholderSVG}#avatarPlaceholderSVG`}
                />
              </svg>
              {userData?.user.name && (<h5>{userData?.user.name}</h5>)}
              <p>{userData?.user.email}</p>
            </div>
            <div className={styles.info}>
              <h5>{t('Do you really want to log out of the system?')}</h5>
              <div className={styles.buttons}>
                <Button
                  variant={ButtonVariants.SECONDARY}
                  onClick={() => setIsModalOpened(false)}
                >
                  {t('Cancel')}
                </Button>
                <Button onClick={handleLogout}>{t('Log out')}</Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default HeaderContent;
