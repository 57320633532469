import { useEffect } from 'react';
import { Provider as JotaiProvider } from 'jotai';
import { createTheme, ThemeProvider } from '@mui/material/styles';

type ProvidersProps = {
  children: React.ReactNode,
};
export default function Providers({ children }: ProvidersProps) {
  const theme = {
    palette: {
      text: {
        primary: '#052129',
      },
      secondary: {
        main: '#9FA8AB',
      },
      error: {
        main: '#EB6060',
      },
    },
    typography: {
      fontFamily: 'Inter, sans-serif',
    },
  };
  const muiTheme = createTheme(theme);

  const setRealViewportSize = () => {
    let vh = window.innerHeight * 0.01;
    let vw = document.body.clientWidth * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    document.documentElement.style.setProperty('--vw', `${vw}px`);
  };
  useEffect(() => {
    setRealViewportSize();
    window.addEventListener('resize', setRealViewportSize);

    return () => {
      window.removeEventListener('resize', setRealViewportSize);
    };
  }, []);

  return (
    <ThemeProvider theme={muiTheme}>
      <JotaiProvider>
        {children}
      </JotaiProvider>
    </ThemeProvider>
  );
}
