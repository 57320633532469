import React from 'react';
import classNames from 'classnames';
import { useMediaQuery } from '@mui/material';
import LogoSVG from '../../../public/media/logo-and-name-upper.svg';
import styles from './NoAccess.module.scss';
import Button from '../../UIKit/Button/Button.tsx';
import AccessDeniedSVG from '../../../public/media/access-denied.svg';
import { MOBILE_MEDIA_QUERY } from '../../../constants.ts';
import { useCustomTranslation } from '../../../useAppTranslate.tsx';

const NoAccess = () => {
  const { t } = useCustomTranslation();
  const isMobileDevice = useMediaQuery(MOBILE_MEDIA_QUERY);

  return (
    <div className={classNames(styles.wrapper)}>
      <div className={styles.notFound}>
        <div className={styles.header}>
          <svg>
            <use
              xlinkHref={`${LogoSVG}#logoUpperSVG`}
              href={`${LogoSVG}#logoUpperSVG`}
            />
          </svg>
        </div>
        <div className={styles.body}>
          <svg>
            <use
              width={isMobileDevice ? '315px' : undefined}
              xlinkHref={`${AccessDeniedSVG}#accessDeniedSVG`}
              href={`${AccessDeniedSVG}#accessDeniedSVG`}
            />
          </svg>
          <h1>{t('Access Denied')}</h1>
          <p>{t('Sorry, it seems you are not permitted to see this.')}</p>
          <Button
            type='button'
            link='/'
          >
            {t('Back to home')}
          </Button>
        </div>
        <p className={styles.footer}>{t('ZEEMLESS. Copyright {{year}}.', { year: new Date().getFullYear() })}</p>
      </div>
    </div>
  );
};

export default NoAccess;
