import classNamesHandler from 'classnames';
import React, { useState } from 'react';
import styles from './FloatingActionButton.module.scss';
import Button, { ButtonVariants } from '../Button/Button';
import AngleRightSVG from '../../../public/media/angle_right.svg';

type FloatingActionButtonProps = {
  children: React.ReactNode;
  icon: React.ReactNode;
  classNames?: {
    main?: string;
    hideButton?: string;
    hideButton_hidden?: string;
    content?: string;
  };
};

const FloatingActionButton = ({ children, icon, classNames }: FloatingActionButtonProps) => {
  const [isHidden, setIsHidden] = useState(false);

  return (
    <div className={classNamesHandler(styles.preview, classNames?.main, {
      [styles.hidden]: isHidden,
      ...(classNames?.hideButton_hidden ? { [classNames?.hideButton_hidden]: isHidden } : {}),
    })}
    >
      <button
        type='button'
        className={classNamesHandler(styles.showPreviewButton, classNames?.hideButton)}
        onClick={() => setIsHidden(false)}
      >
        {icon}
      </button>
      <div className={classNamesHandler(styles.preview__content, classNames?.content)}>
        {children}
        <Button
          variant={ButtonVariants.SECONDARY}
          className={styles.hidePreviewButton}
          onClick={() => setIsHidden(true)}
        >
          <svg className={styles.angleSVG}>
            <use
              xlinkHref={`${AngleRightSVG}#angleRightSVG`}
              href={`${AngleRightSVG}#angleRightSVG`}
            />
          </svg>
        </Button>
      </div>
    </div>
  );
};

export default FloatingActionButton;
