// this store is actually independent, so it's not jotai with zustand bindings,
// but rather tiny zustand store with jotai bindings, so we can map it in a consistent way
// sure I checked and bundle will not really grow because of this change
import { atomWithStore } from 'jotai-zustand';
import { createStore } from 'zustand/vanilla';
import { v4 as makeId } from 'uuid';
import i18n from 'i18next';
import { ReactElement } from 'react';

export enum NotificationStatus {
  ERROR = 'error',
  SUCCESS = 'success',
}
export enum NotificationPlacement {
  TOP = 'top',
  BOTTOM = 'bottom',
}
export enum NotificationOptionView {
  ALERT = 'alert',
  INFO_POPUP = 'info-popup',
}

export enum NotificationPopupIcon {
  PENDING = 'pending',
  SUCCESS = 'success',
  INFO = 'info',
}

export type NotificationBase = {
  text: {
    title?: string,
    body?: string | ReactElement,
  },
  duration?: number, // time in seconds
  id: string,
  status?: NotificationStatus,
  placement: NotificationPlacement,
};

export type NotificationAlert = {
  type: NotificationOptionView.ALERT,
};

export type NotificationInfoPopup = {
  type: NotificationOptionView.INFO_POPUP,
  withoutCloseButton?: boolean,
  icon?: NotificationPopupIcon,
};

export type Notification = NotificationBase & (NotificationAlert | NotificationInfoPopup);

export type NotificationsState = {
  notifications: Notification[]
};

// made it a function instead of just object so i18n is loaded at the moment its called, otherwise empty strings
const getNotificationDefaultOptions = (): Pick<Notification, 'text' | 'duration' | 'placement' | 'type'> => ({
  text: {
    title: i18n.t('An error occurred'),
    body: i18n.t('An error occurred while running the application. '
      + 'Please wait or repeat the last step again. '
      + 'If the problem persists please contact support.'),
  },
  duration: 5,
  placement: NotificationPlacement.TOP,
  type: NotificationOptionView.ALERT,
});

const notificationsStore = createStore<NotificationsState>(() => ({
  notifications: [],
}));

export const notificationsAtom = atomWithStore<NotificationsState>(notificationsStore);

export function removeNotification(notificationId: string) {
  notificationsStore.setState(
    state => ({
      notifications: state.notifications.filter(notification => notification.id !== notificationId),
    }),
  );
}

export function notify(options?: Partial<Omit<NotificationBase, 'id'> & (NotificationAlert | NotificationInfoPopup)>): Notification['id'] {
  const id: string = makeId();
  const newNotification: Notification = {
    ...getNotificationDefaultOptions(), ...options, text: { ...getNotificationDefaultOptions().text, ...(options?.text || {}) }, id,
  };

  notificationsStore.setState(state => ({ notifications: [newNotification, ...state.notifications] }));

  newNotification?.duration && setTimeout(
    () => removeNotification(newNotification.id),
    newNotification.duration * 1000,
  );

  return id;
}
