import { TextField } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import styles from './Input.module.scss';

type InputConditionalProps =
  | {
    readOnly: true,
    setValue?: never;
  }
  | {
    readOnly?: never;
    setValue: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
    onKeyDown?: (e: React.KeyboardEvent) => void;
    onFocus?: (e: React.FocusEvent) => void;
  };

type InputProps = {
  value: string | number;
  type?: string;
  id: string;
  label?: string;
  required?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  className?: string;
  size?: 'small' | 'medium';
  name?: string;
  inputProps?: Record<string, any>;
  placeholder?: string;
  multiline?: boolean,
  maxRows?: number,
} & InputConditionalProps;

const Input = ({
  value,
  type = 'text',
  id,
  label,
  required = false,
  fullWidth = false,
  error = false,
  disabled = false,
  className,
  size,
  name,
  errorMessage,
  inputProps,
  placeholder,
  multiline,
  maxRows,
  ...props
}: InputProps) => (
  <div>
    <TextField
      value={value}
      type={type}
      error={error}
      disabled={disabled}
      className={classNames(styles.input, className, {
        [styles.disabled]: disabled,
        'field-error': error,
      })}
      id={id}
      label={label}
      variant='outlined'
      required={required}
      fullWidth={fullWidth}
      size={size}
      name={name}
      inputProps={inputProps}
      placeholder={placeholder}
      {...('readOnly' in props
        ? { readOnly: props.readOnly }
        : 'setValue' in props
          ? {
            onChange: props.setValue,
            onBlur: props.onBlur,
            onFocus: props.onFocus,
            onKeyDown: props.onKeyDown,
          }
          : {})}
      {...(type === 'textarea' ? { maxRows, multiline } : {})}
    />
    {errorMessage && error && <p className={styles.error}>{errorMessage}</p>}
  </div>
);

export default Input;
