import { useEffect, useState } from 'react';
import classNames from 'classnames';
import GradientProgressBar from '../../../GradientProgressBar/GradientProgressBar';

import SavingPotentialSVG from '../../../../public/media/scores/Saving potential.svg';
import ESGSVG from '../../../../public/media/scores/ESG.svg';
import RiskSVG from '../../../../public/media/scores/Risk.svg';
import DataSVG from '../../../../public/media/scores/Data.svg';
import QualityAssuranceSVG from '../../../../public/media/scores/Quality Assurance.svg';
import SecuritySVG from '../../../../public/media/scores/Security.svg';
import InfrastructureSVG from '../../../../public/media/scores/Infastructure.svg';
import RegulatorySVG from '../../../../public/media/scores/Regulatory.svg';
import PeopleSVG from '../../../../public/media/scores/People.svg';
import ProcessesSVG from '../../../../public/media/scores/Processes.svg';
import SuppliersSVG from '../../../../public/media/scores/Suppliers.svg';
import ResourcesSVG from '../../../../public/media/scores/Resources.svg';

import styles from './Score.module.scss';

type ScoreItemProps = {
  value: number,
  title: string,
  className?: string,
  icon: string,
};

const icons = {
  data: DataSVG,
  infrastructure: InfrastructureSVG,
  processes: ProcessesSVG,
  qualityAssurance: QualityAssuranceSVG,
  regulatory: RegulatorySVG,
  suppliers: SuppliersSVG,
  security: SecuritySVG,
  people: PeopleSVG,
  resources: ResourcesSVG,
  savingPotential: SavingPotentialSVG,
  ESG: ESGSVG,
  operationalRisk: RiskSVG,
};

const ScoreItem = ({
  value, title, className, icon,
}: ScoreItemProps) => {
  const [scoreValue, setScoreValue] = useState(1);

  useEffect(() => {
    setTimeout(() => setScoreValue(value), 200);
  });

  return (
    <div className={classNames(styles.scoreItem, className)}>
      <div className={styles.scoreItem__circle}>
        <GradientProgressBar
          value={scoreValue}
          strokeWidth={15}
        >
          <img
            src={icons[icon as keyof typeof icons]}
            alt=''
            className={styles.scoreItem__icon}
          />
        </GradientProgressBar>
      </div>
      <p className={styles.scoreItem__text}>{title}</p>
    </div>
  );
};

export default ScoreItem;
