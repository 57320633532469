export interface IProjectRisk {
  id: number;
  project_id: number;
  caption: string;
  explanation: string | null;
  mitigation: string | null;
  accepted: boolean | null;
  manual: boolean;
  created_at: string;
  updated_at: string;
  owner: string;
  due_date: string;
}

export enum ProjectRisksActionType {
  DELETE = 'DELETE',
  EDIT = 'EDIT',
}
