import React, { useState } from 'react';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useDroppable } from '@dnd-kit/core';
import { useAtomValue } from 'jotai';
import dayjs from 'dayjs';
import { useNavigate, useParams, useRouteLoaderData } from 'react-router-dom';
import { KeyedMutator } from 'swr/_internal';
import styles from './QualityList.module.scss';
import Button, { ButtonVariants } from '../../../../../UIKit/Button/Button.tsx';
import StatusLabel from '../../../../../UIKit/StatusLabel/StatusLabel.tsx';
import CalendarSVG from '../../../../../../public/media/summary/quality-gates/calendar.svg';
import VoteSVG from '../../../../../../public/media/summary/quality-gates/vote.svg';
import QualityGateOptionsDropdown
  from '../../../../QualityGate/QualityGateOptionsDropdown/QualityGateOptionsDropdown.tsx';
import { ActiveGateType, GateAction, GateResource } from '../../../../QualityGate/types';
import GateDeliverable from '../GateDeliverable/GateDeliverable.tsx';
import { userAtom } from '../../../../../../store/auth.ts';
import { DEFAULT_DATE_FORMAT } from '../../../../../../constants.ts';
import apiClient from '../../../../../../apiClient.ts';
import { notify } from '../../../../../../store/notifications.ts';
import Drawer from '../../../../../UIKit/Drawer/Drawer.tsx';
import QualityGateForm from '../../../../QualityGate/QualityGateForm/QualityGateForm.tsx';
import { ProjectPermissions } from '../../../../Login/user.props.ts';
import DeleteQualityGateModal from '../../../../QualityGate/DeleteQualityGateModal/DeleteQualityGateModal.tsx';
import { ProjectResource } from '../../../../Projects/types.ts';
import { useCustomTranslation } from '../../../../../../useAppTranslate.tsx';

type QualityListProps = {
  gate: GateResource,
  mutate: KeyedMutator<{ data: GateResource[], permissions: string[] }>,
  permissions: string[]
  isReadOnly: boolean,
};

const QualityList = ({
  gate, mutate, permissions, isReadOnly,
}: QualityListProps) => {
  const { clientId, projectId } = useParams();
  const initProjectData = (useRouteLoaderData('project') as { project: ProjectResource })?.project;
  const { t } = useCustomTranslation();
  const navigate = useNavigate();

  const { setNodeRef } = useDroppable({ id: gate.id, data: { gateId: gate.id } });
  const userData = useAtomValue(userAtom);
  const [activeGate, setActiveGate] = useState<ActiveGateType>({ gate: null, actionType: null });

  const detailsRoute = `/d/client/${clientId}/project/${projectId}/gate/${gate.id}`;

  const deleteQualityGate = async (gateId: number) => {
    try {
      const { statusCode } = await apiClient.delete(`projects/${projectId}/quality-gates/${gateId}`);

      if (statusCode === 204) {
        mutate();
      } else {
        throw new Error();
      }
    } catch (e) {
      console.error(e);
      notify();
    } finally {
      setActiveGate({ gate: null, actionType: null });
    }
  };

  const editQualityGate = async (body: Record<string, any>, gateId?: number) => {
    if (!gateId) return;

    try {
      const { statusCode, response } = await apiClient.put<{ message?: string }>(`projects/${projectId}/quality-gates/${gateId}`, {
        body: JSON.stringify(body),
      });

      if (statusCode === 200) {
        mutate();
      } else {
        throw new Error(response?.message);
      }
    } catch (e) {
      console.error(e);
      notify(e?.message ? { text: { body: e.message } } : {});
    } finally {
      setActiveGate({ gate: null, actionType: null });
    }
  };

  return (
    <>
      <div className={styles.list}>
        <header className={styles.header}>
          <div>
            <h5>{gate.caption}</h5>
            <div className={styles.header__info}>
              <span>
                <svg>
                  <use
                    xlinkHref={`${CalendarSVG}#calendarSVG`}
                    href={`${CalendarSVG}#calendarSVG`}
                  />
                </svg>
                {dayjs(gate.due_date).format(userData?.user.dateFormat ?? DEFAULT_DATE_FORMAT)}
              </span>
              <StatusLabel status={{ caption: gate.status.caption, value: gate.status.value, state: gate.status.state }} />
            </div>
          </div>
          <div className={styles.header__buttons}>
            <Button
              className={styles.header__buttons_button}
              variant={ButtonVariants.SECONDARY}
              link={`${detailsRoute}/voting`}
            >
              <svg>
                <use
                  xlinkHref={`${VoteSVG}#voteSVG`}
                  href={`${VoteSVG}#voteSVG`}
                />
              </svg>
            </Button>
            <QualityGateOptionsDropdown
              permissions={permissions}
              onShowDetailsClick={() => navigate(detailsRoute)}
              onDeleteClick={() => setActiveGate({ gate, actionType: GateAction.DELETE })}
              onEditClick={() => setActiveGate({ gate, actionType: GateAction.EDIT })}
              isReadOnly={isReadOnly}
            />
          </div>
        </header>
        <SortableContext
          items={gate.deliverables ?? []}
          strategy={verticalListSortingStrategy}
        >
          <div
            className={styles.body}
            ref={setNodeRef}
          >
            {gate.deliverables?.length ? gate.deliverables.map((deliverable) => (
              <GateDeliverable
                key={deliverable.id}
                deliverable={deliverable}
                gateId={gate.id}
                gateDueDate={gate.due_date}
                isDragAllowed={permissions.includes(ProjectPermissions.UPDATE) && !isReadOnly}
              />
            )) : null}
          </div>
        </SortableContext>
      </div>
      {activeGate.gate && activeGate.actionType === GateAction.DELETE && (
        <DeleteQualityGateModal
          closeModal={() => setActiveGate({ gate: null, actionType: null })}
          gate={activeGate.gate}
          onDeleteClick={(gateId) => deleteQualityGate(gateId)}
        />
      )}
      <Drawer
        isOpen={!!(activeGate.gate && activeGate.actionType === GateAction.EDIT)}
        setIsOpen={() => setActiveGate({ gate: null, actionType: null })}
        title={t('Edit quality gate')}
      >
        <QualityGateForm
          submit={(values) => editQualityGate(values, activeGate.gate?.id)}
          activeGateId={activeGate.gate?.id}
          submitButtonText={t('Save changes')}
          projectTimeline={{ from: initProjectData?.begin, to: initProjectData?.end }}
        />
      </Drawer>
    </>
  );
};
export default QualityList;
