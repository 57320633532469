import classNames from 'classnames';
import styles from './Hint.module.scss';

type HintProps = {
  title?: string;
  description: string | React.ReactElement;
  className?: string;
  icon?: {
    id: string;
    component: string;
    size: { width: number; height: number };
    className?: string;
  };
};

const Hint = ({
  title, description, className, icon,
}: HintProps) => (
  <div className={classNames(styles.hint, className, {
    [styles.hint_withIcon]: icon,
    [styles.hint_withTitle]: title,
  })}
  >
    {icon && (
      <svg
        className={classNames(styles.hint__icon, icon?.className, {
          [styles.hint__icon_withTitle]: title,
        })}
      >
        <use
          xlinkHref={`${icon.component}#${icon.id}}`}
          href={`${icon.component}#${icon.id}`}
        />
      </svg>
    )}
    {title && <p className={styles.hint__title}>{title}</p>}
    <p className={styles.hint__description}>{description}</p>
  </div>
);

export default Hint;
