import dayjs from 'dayjs';
import classNames from 'classnames';
import useSWR from 'swr';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useRef, useState } from 'react';
import {
  getGridDateOperators,
  getGridNumericOperators,
  getGridStringOperators,
  GridActionsCellItem,
  GridColDef,
  GridSortItem,
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from '@mui/x-data-grid';
import { TFunction } from 'i18next';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Tooltip, useMediaQuery } from '@mui/material';

import Button, { ButtonVariants } from '../../UIKit/Button/Button';
import ClippedCell from '../../UIKit/Table/ClippedCell.tsx';
import EntityBaseModal, { TitlePosition } from '../../EntityBaseModal/EntityBaseModal.tsx';
import Loader from '../../Loader/Loader.tsx';
import Table from '../../UIKit/Table/Table';
import StatusLabel from '../../UIKit/StatusLabel/StatusLabel';

import apiClient from '../../../apiClient.ts';
import { usePermissions } from '../../../usePermission.ts';
import { ListResponse } from '../Users/UsersList/types.ts';
import { Permissions, ProjectPermissions } from '../Login/user.props.ts';
import { ProjectResource } from './types.ts';
import { userAtom } from '../../../store/auth.ts';
import { formatNumberAsEuro } from '../../../utils/format-number-to-euro.ts';
import { DEFAULT_NUMBER_FORMAT, MOBILE_MEDIA_QUERY, NUMBER_FORMAT } from '../../../constants.ts';
import { projectEditAccess } from '../../../store/project.ts';

import ArchiveSVG from '../../../public/media/archive-icon.svg';
import DeleteSVG from '../../../public/media/delete-icon.svg';
import DiagramIconSVG from '../../../public/media/diagram-icon.svg';
import EditSVG from '../../../public/media/edit-icon.svg';
import ViewSVG from '../../../public/media/view.svg';
import ListItemTooltip from '../Customers/CustomersList/ListItemTooltip.tsx';
import SettingsSvg from '../../../public/media/client-settings.svg';
import TeamSVG from '../../../public/media/team.svg';
import HistorySVG from '../../../public/media/history.svg';
import PlusSVG from '../../../public/media/plus.svg';
import TrackingOverviewSVG from '../../../public/media/tracking_overview.svg';
import AvatarPlaceholderSVG from '../../../public/media/avatar-placeholder.svg';

import ProjectHistory from './ProjectHistory/ProjectHistory.tsx';
import ListPlaceholder from '../../UIKit/ListPlaceholder/ListPlaceholder.tsx';
import NumberRangeFilter from '../../UIKit/Table/Filters/NumberRangeFilter.tsx';
import OptionsListFilter from '../../UIKit/Table/Filters/OptionsListFilter.tsx';
import DateRangeFilter from '../../UIKit/Table/Filters/DateRangeFilter.tsx';
import SearchFilter from '../../UIKit/Table/Filters/SearchFilter.tsx';
import { notify } from '../../../store/notifications.ts';

import { Status } from '../../UIKit/StatusLabel/types.ts';
import { filterPositionAnchorEl, tableFiltersOptions, tablesSortConfig } from '../../../store/table.ts';
import { useCustomTranslation } from '../../../useAppTranslate.tsx';

import styles from './Projects.module.scss';

const PROJECT_STATUS_FILTER = 'project/status';

enum ProjectListType {
  DEFAULT = 'projects',
  ARCHIVE = 'archive',
}

export type UserThatCurrentlyEditingInfo = {
  email: string;
  full_name: string;
  requested_at: string;
  user_id: number;
};

const getColumns = (
  t: TFunction<string, undefined>,
  onActionProjectClick: (project: { id: number; name: string }, type: ProjectAction) => void,
  onEditClick: (projectId: number) => void,
  onViewClick: (projectId: number) => void,
  onShowOverviewClick: (projectId: number) => void,
  onManageTeamClick: (projectId: number) => void,
  onHistoryClick: (projectId: number, name: string, isAllowedToRestore: boolean) => void,
  dateFormat: string,
  numberFormat: (typeof NUMBER_FORMAT)[string],
  hasTrackingOverviewPermission: boolean,
  isMobileDevice?: boolean,
): GridColDef[] => [
  {
    headerName: t('Project title'),
    field: 'caption',
    flex: 1,
    minWidth: 80,
    maxWidth: isMobileDevice ? 200 : undefined,
    sortable: true,
    hideable: false,
    renderCell: (params) => <ClippedCell value={params.row.caption} />,
    filterOperators: getGridStringOperators().map((operator) => ({
      ...operator,
      InputComponent: SearchFilter,
    })),
  },
  {
    headerName: t('Configuration status'),
    field: 'status',
    flex: 1,
    minWidth: 80,
    sortable: true,
    hideable: false,
    renderCell: (params) => (
      <StatusLabel
        status={params.row.status}
        size='large'
        className={styles.statusLabel}
      />
    ),
    filterOperators: getGridNumericOperators().map((operator) => ({
      ...operator,
      InputComponent: (props) => (
        <OptionsListFilter
          {...props}
          filterName={PROJECT_STATUS_FILTER}
        />
      ),
    })),
  },
  {
    headerName: t('Start date'),
    field: 'begin',
    flex: 1,
    minWidth: 80,
    sortable: true,
    hideable: true,
    renderCell: (params) => <p>{dayjs(params.row.begin).format(dateFormat)}</p>,
    filterOperators: getGridDateOperators().map((operator) => ({
      ...operator,
      InputComponent: DateRangeFilter,
    })),
  },
  {
    headerName: t('End date'),
    field: 'end',
    flex: 1,
    minWidth: 80,
    sortable: true,
    hideable: true,
    renderCell: (params) => <p>{dayjs(params.row.end).format(dateFormat)}</p>,
    filterOperators: getGridDateOperators().map((operator) => ({
      ...operator,
      InputComponent: DateRangeFilter,
    })),
  },
  {
    headerName: t('Budget'),
    field: 'budget',
    flex: 1,
    minWidth: 80,
    sortable: true,
    hideable: true,
    renderCell: (params) => <p>{formatNumberAsEuro(params.row.budget, numberFormat)}</p>,
    filterOperators: getGridNumericOperators().map((operator) => ({
      ...operator,
      title: t('Budget range'),
      InputComponent: NumberRangeFilter,
    })),
  },
  {
    headerName: t('Project owners'),
    field: 'owners',
    flex: 1,
    minWidth: 80,
    sortable: false,
    hideable: true,
    renderCell: ({ value }) => ((value as string[]).length > 1 ? (
      <ListItemTooltip
        options={value}
        title={t('Project owners')}
      />
    ) : (
      <ClippedCell value={value[0]} />
    )),
    filterOperators: getGridStringOperators().map((operator) => ({
      ...operator,
      InputComponent: SearchFilter,
    })),
  },
  {
    headerName: '',
    field: 'actions',
    flex: 0,
    minWidth: 32,
    maxWidth: 32,
    sortable: false,
    hideable: false,
    type: 'actions',
    getActions: (params) => [
      ...(params.row.permissions.includes(ProjectPermissions.UPDATE)
        ? [
          <GridActionsCellItem
            icon={(
              <svg className='tableRowActionIcon'>
                <use
                  xlinkHref={`${EditSVG}#editSVG`}
                  href={`${EditSVG}#editSVG`}
                />
              </svg>
            )}
            onClick={() => onEditClick(params.row.id)}
            label={t('Edit')}
            showInMenu
          />,
        ]
        : []),
      ...(!params.row.permissions.includes(ProjectPermissions.ARCHIVE)
        ? []
        : params.row.status.state !== 'inactive'
          ? [
            <GridActionsCellItem
              icon={(
                <svg className='tableRowActionIcon'>
                  <use
                    xlinkHref={`${ArchiveSVG}#archiveSVG`}
                    href={`${ArchiveSVG}#archiveSVG`}
                  />
                </svg>
              )}
              label={t('Move to archive')}
              onClick={() => onActionProjectClick({ id: params.row.id, name: params.row.caption }, 'archive')}
              showInMenu
            />,
          ]
          : [
            <GridActionsCellItem
              icon={(
                <svg className='tableRowActionIcon'>
                  <use
                    xlinkHref={`${ArchiveSVG}#archiveSVG`}
                    href={`${ArchiveSVG}#archiveSVG`}
                  />
                </svg>
              )}
              label={t('Restore from archive')}
              onClick={() => onActionProjectClick({ id: params.row.id, name: params.row.caption }, 'restore')}
              showInMenu
            />,
          ]),
      ...(params.row.permissions.includes(ProjectPermissions.MANAGE)
        ? [
          <GridActionsCellItem
            icon={(
              <svg className='tableRowActionIcon'>
                <use
                  xlinkHref={`${TeamSVG}#teamSVG`}
                  href={`${TeamSVG}#teamSVG`}
                />
              </svg>
            )}
            onClick={() => onManageTeamClick(params.row.id)}
            label={t('Manage project team')}
            showInMenu
          />,
        ]
        : []),
      ...(params.row.permissions.includes(ProjectPermissions.DELETE)
        ? [
          <GridActionsCellItem
            icon={(
              <svg className='tableRowActionIcon'>
                <use
                  xlinkHref={`${DeleteSVG}#deleteSVG`}
                  href={`${DeleteSVG}#deleteSVG`}
                />
              </svg>
            )}
            onClick={() => onActionProjectClick({ id: params.row.id, name: params.row.caption }, 'delete')}
            label={t('Delete')}
            showInMenu
          />,
        ]
        : []),
      ...(params.row.permissions.includes(ProjectPermissions.VIEW)
        ? [
          <GridActionsCellItem
            icon={(
              <svg className='tableRowActionIcon'>
                <use
                  xlinkHref={`${HistorySVG}#historySVG`}
                  href={`${HistorySVG}#historySVG`}
                />
              </svg>
            )}
            onClick={() => onHistoryClick(params.row.id, params.row.caption, params.row.permissions.includes(ProjectPermissions.RESTORE))}
            label={params.row.permissions.includes(ProjectPermissions.RESTORE) ? t('Restore earlier version') : t('View earlier versions')}
            showInMenu
          />,
        ]
        : []),
      ...(params.row.permissions.includes(ProjectPermissions.VIEW)) ? [
        <GridActionsCellItem
          icon={(
            <svg className='tableRowActionIcon'>
              <use
                xlinkHref={`${ViewSVG}#viewSVG`}
                href={`${ViewSVG}#viewSVG`}
              />
            </svg>
          )}
          onClick={() => onViewClick(params.row.id)}
          label={t('View')}
          showInMenu
        />,
      ] : [],
      ...(hasTrackingOverviewPermission) ? [
        <GridActionsCellItem
          icon={(
            <svg className='tableRowActionIcon'>
              <use
                xlinkHref={`${TrackingOverviewSVG}#trackingOverviewSVG`}
                href={`${TrackingOverviewSVG}#trackingOverviewSVG`}
              />
            </svg>
          )}
          onClick={() => onShowOverviewClick(params.row.id)}
          label={t('Project tracking overview')}
          showInMenu
        />,
      ] : [],
    ],
  },
];

type ProjectSectionProps = {
  id?: string;
  adminView?: boolean;
  t: TFunction<string, undefined>;
  isArchiveView?: boolean,
  noProjects?: boolean,
};

const Heading = ({
  adminView, id, t, isArchiveView, noProjects,
}: ProjectSectionProps) => {
  const { hasPermission } = usePermissions();
  return (
    <>
      <h1
        className={classNames(styles.title, {
          [styles.title_adminView]: adminView,
        })}
      >
        {adminView ? t('Projects') : t('My projects')}
      </h1>
      {!isArchiveView && (
        <>
          {hasPermission(Permissions.PROJECT_CREATE) && (
            <Button
              link={`/d/client/${id}/new-project`}
              className={classNames(styles.panel__button, styles.panel__button__createNewProject)}
              icon={(
                <svg>
                  <use
                    xlinkHref={`${PlusSVG}#plusSVG`}
                    href={`${PlusSVG}#plusSVG`}
                  />
                </svg>
              )}
              iconSize={{ width: 20, height: 20 }}
            >
              {t('Create new project')}
            </Button>
          )}
          {hasPermission(Permissions.PROJECT_ARCHIVE) && (
            <Button
              variant={ButtonVariants.SECONDARY}
              className={classNames(styles.panel__button, styles.archiveLink)}
              link={adminView ? `/m/client/${id}/projects/archive` : `/d/client/${id}/projects/archive`}
            >
              {t('Project archive')}
            </Button>
          )}
          {hasPermission(Permissions.PORTFOLIO) && (
            <Tooltip
              title={noProjects ? t('There are no active projects') : null}
              enterTouchDelay={0}
            >
              <span>
                <Button
                  className={styles.panel__button}
                  variant={ButtonVariants.SECONDARY}
                  link={`/d/client/${id}/projects/portfolio`}
                  disabled={noProjects}
                >
                  <svg className={styles.panel__button__diagramIcon}>
                    <use
                      xlinkHref={`${DiagramIconSVG}#diagramIconSVG`}
                      href={`${DiagramIconSVG}#diagramIconSVG`}
                    />
                  </svg>
                </Button>
              </span>
            </Tooltip>
          )}
        </>
      )}
    </>
  );
};

const CustomPanelButton = (props: any) => {
  const setFilterButtonEl = useSetAtom(filterPositionAnchorEl);
  return (
    <Button
      onClick={(e) => {
        const element = document.querySelector('.customPanelButtom');
        element && setFilterButtonEl(element);
        props?.onClick?.(e);
      }}
      variant={ButtonVariants.SECONDARY}
      className={classNames(styles.panel__button, 'customPanelButtom')}
    >
      <svg className={styles.panel__settingsIcon}>
        <use
          xlinkHref={`${SettingsSvg}#clientSettingsSVG`}
          href={`${SettingsSvg}#clientSettingsSVG`}
        />
      </svg>
    </Button>
  );
};

const CustomPanel = ({ adminView, t, id }: Omit<ProjectSectionProps, 'isArchiveView'>) => (
  <header className={styles.panel}>
    <GridToolbarContainer>
      <Heading
        {...{ adminView, t, id }}
      />
      <GridToolbarColumnsButton component={CustomPanelButton} />
    </GridToolbarContainer>
  </header>
);

type ProjectsProps = {
  adminView?: boolean;
  isArchiveView?: boolean;
};

const createRows = (rows: ProjectResource[]) => rows.map((project) => ({
  id: project.id,
  caption: project.caption,
  status: project.status,
  begin: project.begin,
  end: project.end,
  budget: project.budget,
  owners: project.owners.map((owner) => owner.name),
  permissions: project.permissions,
  withJiraIntegration: !!project.jiraIntegration,
}));

const PROJECT_MODAL_DEFAULT_VALUE = {
  isVisible: false,
  selectedProject: null,
  type: null,
};

const deleteProject = async (clientId: string, projectId: number) => {
  try {
    await apiClient.delete(`clients/${clientId}/projects/${projectId}`);
  } catch (e) {
    console.error(e);
  }
};

const changeProjectStatus = async (clientId: string, projectId: number, type: 'archive' | 'restore') => {
  try {
    await apiClient
      .put(`clients/${clientId}/projects/${projectId}/archive`, { body: JSON.stringify({ archive: type === 'archive' }) });
  } catch (e) {
    console.error(e);
  }
};

type ProjectAction = 'delete' | 'archive' | 'restore';

export type HistoryState = { id: number, name: string, isAllowedToRestore: boolean };

const Projects = ({ adminView, isArchiveView }: ProjectsProps) => {
  const { clientId, id: selectedClientId } = useParams();
  const { t, i18n } = useCustomTranslation();
  const navigate = useNavigate();
  const tableRef = useRef<any>(null);
  const id = clientId ?? selectedClientId;
  const userData = useAtomValue(userAtom);
  const dateFormat = userData?.user?.dateFormat || 'DD.MM.YYYY';
  const numberFormat = userData?.user?.numberFormat ? NUMBER_FORMAT[userData?.user?.numberFormat] : DEFAULT_NUMBER_FORMAT;

  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [sortSettings, setSortingSettings] = useAtom(tablesSortConfig);

  const [filterSettings, setFiltertSettings] = useState<Record<string, any> | null>(null);
  const [filtersOptions, setFiltersOption] = useAtom(tableFiltersOptions);
  const pageType = isArchiveView ? ProjectListType.ARCHIVE : ProjectListType.DEFAULT;

  const [showHistoryForProject, setShowHistoryForProject] = useState<HistoryState | null>(null);

  const isMobileDevice = useMediaQuery(MOBILE_MEDIA_QUERY);

  const [storedProjectEditAccess, setStoredProjectEditAccess] = useAtom(projectEditAccess);
  const {
    data, isLoading, isValidating, mutate,
  } = useSWR(
    [`clients/${id}/projects`, page, clientId, selectedClientId, sortSettings, i18n.language, isArchiveView, filterSettings],
    async ([url]) => {
      const params = new URLSearchParams({
        page: String(page),
        archived: Number(!!isArchiveView).toString(),
        filters: JSON.stringify(filterSettings),
        ...(sortSettings[pageType]
          ? {
            direction: sortSettings?.[pageType].sort!,
            order_by: sortSettings?.[pageType].field,
          }
          : {}),
        with: ['owners', 'jiraIntegration'].join(','),
      }).toString();
      const { response } = await apiClient.get<ListResponse<ProjectResource>>(`${url}?${params}`);
      setTotalPages(response.meta.last_page);
      response.meta.last_page < page && setPage(response.meta.last_page);
      return createRows(response?.data);
    },
    {
      keepPreviousData: true,
      revalidateIfStale: true,
      revalidateOnReconnect: false,
      revalidateOnFocus: false,
    },
  );

  useSWR(
    [`clients/${id}/projectStatuses`, i18n.language],
    async ([url]) => {
      const { response } = await apiClient.get<{ data: Status[] }>(url);
      return response?.data;
    },
    {
      onSuccess: (statuses) => {
        setFiltersOption({ ...filtersOptions, [PROJECT_STATUS_FILTER]: statuses });
      },
    },
  );

  const [projectModal, setProjectModal] = useState<{
    isVisible: boolean;
    selectedProject: { id: number; name: string } | null;
    type: null | ProjectAction;
  }>(PROJECT_MODAL_DEFAULT_VALUE);

  const BindedCustomPanel = useCallback(
    () => (
      <CustomPanel
        {...{
          adminView,
          t,
          id,
          isArchiveView,
        }}
      />
    ),
    [adminView, i18n.language, id],
  );

  const updateProjectHandler = async (seceltedClietId: string | undefined, projectId: number | undefined, type: null | ProjectAction) => {
    if (typeof projectId === 'number' && typeof seceltedClietId === 'string' && type !== null) {
      await (type === 'delete' ? deleteProject(seceltedClietId, projectId) : changeProjectStatus(seceltedClietId, projectId, type));
      mutate();
      setProjectModal(PROJECT_MODAL_DEFAULT_VALUE);
    }
  };

  const onActionProjectClick = (project: { id: number; name: string }, type: ProjectAction) => {
    if (type === 'restore') {
      updateProjectHandler(id, project?.id, type);
    } else {
      setProjectModal({ isVisible: true, selectedProject: project, type });
    }
  };

  const getModalContent = (type: ProjectAction | null, projectTitle?: string) => {
    switch (type) {
      case 'delete':
        return {
          title: t('Are you sure you want to delete the project'),
          confirmButtonText: t('Yes, delete'),
        };
      case 'archive':
        return {
          title: t('Are you sure you want to move to archive the project?', { project: projectTitle }),
          confirmButtonText: t('Yes, move to archive'),
        };
      default:
        return {
          title: '',
          confirmButtonText: '',
        };
    }
  };

  const onEditClick = async (projectId: number) => {
    try {
      const { statusCode, response } = await apiClient
        .get<{
        data: UserThatCurrentlyEditingInfo,
      }>(`projects/${projectId}/edit-mode`);

      if (statusCode === 200) {
        setStoredProjectEditAccess({
          isViewMode: false,
          isEditingAvailable: true,
          isEditingAvailableShowOnUI: true,
          isEditingUnavailableModalVisible: false,
          userThatCurrentlyEditing: {
            userId: response?.data?.user_id,
            name: response?.data?.full_name,
            email: response?.data?.email,
          },
          requestedAt: response?.data?.requested_at,
          projectId,
        });
        navigate(`/d/client/${clientId ?? selectedClientId}/project/${projectId}`);
      } else if (statusCode === 409) {
        setStoredProjectEditAccess({
          isViewMode: false,
          isEditingAvailable: false,
          isEditingAvailableShowOnUI: false,
          isEditingUnavailableModalVisible: true,
          userThatCurrentlyEditing: {
            userId: response?.data?.user_id,
            name: response?.data?.full_name,
            email: response?.data?.email,
          },
          requestedAt: response?.data?.requested_at,
          projectId,
        });
      } else {
        throw new Error();
      }
    } catch (e) {
      notify({ text: e.message ? { body: e.message } : {} });
      console.error(e);
    }
  };
  const onViewClick = (projectId: number) => {
    setStoredProjectEditAccess({
      isViewMode: true,
      isEditingAvailable: false,
      isEditingAvailableShowOnUI: false,
      isEditingUnavailableModalVisible: false,
      userThatCurrentlyEditing: {
        userId: null,
        name: null,
        email: null,
      },
      requestedAt: null,
      projectId,
    });
    navigate(`/d/client/${clientId ?? selectedClientId}/project/${projectId}`);
  };
  const onShowOverviewClick = (projectId: number) => navigate(`/d/client/${clientId ?? selectedClientId}/project/${projectId}/tracking`);
  const onHistoryClick = (projectId: number, name: string, isAllowedToRestore: boolean) => setShowHistoryForProject({
    id: projectId, name, isAllowedToRestore,
  });
  const onManageTeamClick = (projectId: number) => navigate(`/d/client/${clientId ?? selectedClientId}/projects/${projectId}/members`);

  const onSort = (model: GridSortItem | null) => {
    setSortingSettings({ ...sortSettings, [pageType]: model });
  };

  const { hasPermission } = usePermissions();
  const hasTrackingOverviewPermission = hasPermission(Permissions.PROJECT_TRACKING);

  const onConfirmHandler = () => {
    setStoredProjectEditAccess({
      isViewMode: false,
      isEditingAvailable: false,
      isEditingAvailableShowOnUI: false,
      isEditingUnavailableModalVisible: false,
      userThatCurrentlyEditing: {
        userId: null,
        name: null,
        email: null,
      },
      requestedAt: null,
      projectId: null,
    });
    navigate(`/d/client/${clientId ?? selectedClientId}/project/${storedProjectEditAccess?.projectId}`);
  };

  return (
    <div className={styles.wrap}>
      <div
        className={classNames(styles.projects, {
          [styles.projects__clientView]: !adminView,
        })}
      >
        {typeof data === 'undefined' || !userData?.user ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        ) : data?.length === 0 && !filterSettings ? (
          <ListPlaceholder
            header={(
              <Heading
                {...{
                  adminView,
                  t,
                  isArchiveView,
                  id,
                }}
                noProjects
              />
            )}
            headerClassName={styles.panel}
            placeholderText={isArchiveView ? t('You do not have any projects in archive') : t('You do not have any projects added yet')}
          />
        ) : (
          <Table
            rows={data}
            isLoading={isLoading || isValidating}
            onPageChange={setPage}
            totalPages={totalPages}
            columns={getColumns(
              t,
              onActionProjectClick,
              onEditClick,
              onViewClick,
              onShowOverviewClick,
              onManageTeamClick,
              onHistoryClick,
              dateFormat,
              numberFormat,
              hasTrackingOverviewPermission,
              isMobileDevice,
            )}
            ref={tableRef}
            page={page}
            slots={{
              toolbar: isArchiveView ? null : BindedCustomPanel,
            }}
            onSortModelChange={onSort}
            onFilterModelChange={setFiltertSettings}
            initSortModel={sortSettings?.[pageType]}
          />
        )}
      </div>
      {projectModal.isVisible && (
        <EntityBaseModal
          onClose={() => setProjectModal(PROJECT_MODAL_DEFAULT_VALUE)}
          onConfirmClick={() => updateProjectHandler(id, projectModal.selectedProject?.id, projectModal.type)}
          heading={getModalContent(projectModal.type, projectModal.selectedProject?.name).title}
          confirmButtonText={getModalContent(projectModal.type, projectModal.selectedProject?.name).confirmButtonText}
          isShowDeleteSVG
        />
      )}
      <ProjectHistory
        project={showHistoryForProject}
        close={() => setShowHistoryForProject(null)}
        updateProjects={mutate}
      />
      {storedProjectEditAccess?.isEditingUnavailableModalVisible && (
      <EntityBaseModal
        onClose={() => setStoredProjectEditAccess({
          isViewMode: false,
          isEditingAvailable: false,
          isEditingAvailableShowOnUI: false,
          isEditingUnavailableModalVisible: false,
          userThatCurrentlyEditing: {
            userId: null,
            name: null,
            email: null,
          },
          requestedAt: null,
          projectId: null,
        })}
        onConfirmClick={onConfirmHandler}
        heading={t('Editing unavailable')}
        confirmButtonText={t('View project')}
        modalClassName={styles.editingUnavailableModal}
        titlePosition={TitlePosition.LEFT}
      >
        <div className={styles.userBlock}>
          <svg className={styles.userBlock__icon}>
            <use
              xlinkHref={`${AvatarPlaceholderSVG}#avatarPlaceholderSVG`}
              href={`${AvatarPlaceholderSVG}#avatarPlaceholderSVG`}
            />
          </svg>
          <div className={styles.userBlock__info}>
            <p className={styles.userBlock__info__name}>{storedProjectEditAccess?.userThatCurrentlyEditing?.name}</p>
            <p className={styles.userBlock__info__email}>{storedProjectEditAccess?.userThatCurrentlyEditing?.email}</p>
          </div>
        </div>
        <p className={styles.editingUnavailableModal__message}>
          {t('This user is currently editing this project. Do you want to view the project instead?')}
        </p>
      </EntityBaseModal>
      )}
    </div>
  );
};

export default Projects;
