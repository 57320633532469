import { TFunction } from 'i18next';
import apiClient from '../../apiClient.ts';
import { NotificationStatus, notify } from '../../store/notifications.ts';

type AddLocaleProps = {
  t: TFunction<string, undefined>,
  localeId: number,
};
export const addLocale = async ({ t, localeId }: AddLocaleProps) => {
  try {
    const { response, statusCode } = await apiClient.post<{ message: string }>(`maintain-translations/translation-locales/${localeId}`);

    if (statusCode === 204) {
      notify({ status: NotificationStatus.SUCCESS, text: { title: t('Success'), body: t('Locale successfully added') } });
    } else {
      throw new Error(response.message);
    }
  } catch (e) {
    notify(e.message);
    console.error(e.message);
  }
};

type RemoveLocaleProps = {
  t: TFunction<string, undefined>,
  localeId: number,
};
export const removeLocale = async ({
  t, localeId,
}: RemoveLocaleProps) => {
  try {
    const { response, statusCode } = await apiClient.delete<{ message: string }>(`maintain-translations/translation-locales/${localeId}`);
    if (statusCode === 204) {
      notify({ status: NotificationStatus.SUCCESS, text: { title: t('Success'), body: t('Locale successfully removed') } });
    } else {
      notify({ text: { title: response.message } });
      throw new Error(response.message);
    }
  } catch (e) {
    console.error(e.message);
  }
};

type UpdateTranslationParams = {
  id: number,
  value: {
    caption?: {
      [key: string]: string,
    },
    is_active?: boolean,
    is_publish?: boolean,
  },
  t: TFunction<string, undefined>,
};
export const updateTranslation = async ({
  id, value, t,
}: UpdateTranslationParams) => {
  try {
    const { response, statusCode } = await apiClient.put<{ message: string }>(`maintain-translations/${id}`, {
      body: JSON.stringify(value),
    });
    if (statusCode !== 204) {
      notify({ text: { body: response.message } });
      throw new Error(response.message);
    } else {
      notify({ status: NotificationStatus.SUCCESS, text: { title: t('Translation updated'), body: '' } });
    }
  } catch (e) {
    console.error(e.message);
  }
};

type PublishLocaleProps = {
  id: number,
  t: TFunction<string, undefined>,
  publish: boolean,
};
export const changeLocalePublishStatus = async ({
  id, t, publish,
}: PublishLocaleProps) => {
  try {
    const { response, statusCode } = await apiClient
      .put<{ message: string }>(`maintain-translations/translation-locales/${id}/${publish ? 'publish' : 'unpublish'}`);
    if (statusCode === 204) {
      notify({ status: NotificationStatus.SUCCESS, text: { title: t(publish ? 'Locale published' : 'Locale unpublished'), body: '' } });
    } else {
      notify({ text: { body: response.message } });
      throw new Error(response.message);
    }
  } catch (e) {
    console.error(e.message);
  }
};

export const autoTranslateLocale = async ({ localeId, t }: { localeId: number, t: TFunction<string, undefined> }) => {
  try {
    const { statusCode } = await apiClient.post(`maintain-translations/translation-locales/${localeId}/ai-generate`);
    if (statusCode === 200) {
      notify({
        status: NotificationStatus.SUCCESS,
        text: {
          title: t('Translations successfully requested'),
          body: t('Check result after couple of minutes'),
        },
      });
    } else {
      throw new Error();
    }
  } catch (e) {
    notify({ text: { title: e.message } });
    console.error(e.message);
  }
};
