import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Redirect } from '../components/pages/NewProject/types';
import { Permissions } from '../components/pages/Login/user.props';
import { eventEmitter } from '../eventEmitter.ts';
import { NotificationOptionView, NotificationPlacement, notify } from '../store/notifications';
import apiClient from '../apiClient.ts';
import useTimer from '../useTimer';
import { usePermissions } from '../usePermission';
import { UserThatCurrentlyEditingInfo } from '../components/pages/Projects/Projects';
import { useCustomTranslation } from '../useAppTranslate.tsx';

type StoredProjectEditAccess = {
  isViewMode: boolean;
  isEditingAvailable: boolean;
  isEditingAvailableShowOnUI: boolean;
  isEditingUnavailableModalVisible: boolean;
  userThatCurrentlyEditing: {
    userId: number | null;
    name: string | null;
    email: string | null;
  };
  requestedAt: string | null;
  projectId: number | null;
};

const ARE_YOU_STILL_HERE_MODAL_DEFAULT_VALUE = { isVisible: false };

const useEditAccessHandler = (
  clientId: string | undefined,
  projectId: string | undefined,
  storedProjectEditAccess: StoredProjectEditAccess,
  setStoredProjectEditAccess: React.Dispatch<React.SetStateAction<StoredProjectEditAccess>>,
  // Some pages can be accessed without edit permissions,
  // so we need to check if requesting edit access is necessary.
  userHasEditPermission: boolean = true,
) => {
  const { t } = useCustomTranslation('global');
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { hasPermission } = usePermissions();
  const prefix = hasPermission(Permissions.ADMIN) ? Redirect.MANAGE : Redirect.DASHBOARD;

  const [areYouStillHereModal, setAreYouStillHereModal] = useState(ARE_YOU_STILL_HERE_MODAL_DEFAULT_VALUE);

  const longDuration = 285000; // 4 min 45 sec
  const accessCheckInterval = 5000;

  const getEditAccess = async () => {
    try {
      if (!projectId) return;

      const { statusCode, response } = await apiClient
        .get<{ data: UserThatCurrentlyEditingInfo }>(`projects/${projectId}/edit-mode`);

      if (statusCode === 200) {
        setStoredProjectEditAccess({
          isViewMode: false,
          isEditingAvailable: true,
          isEditingAvailableShowOnUI: true,
          isEditingUnavailableModalVisible: false,
          userThatCurrentlyEditing: {
            userId: response?.data?.user_id,
            name: response?.data?.full_name,
            email: response?.data?.email,
          },
          requestedAt: response?.data?.requested_at,
          projectId: Number(projectId),
        });
      } else if (statusCode === 409) {
        setStoredProjectEditAccess({
          isViewMode: false,
          isEditingAvailable: false,
          isEditingAvailableShowOnUI: false,
          isEditingUnavailableModalVisible: false,
          userThatCurrentlyEditing: {
            userId: response?.data?.user_id,
            name: response?.data?.full_name,
            email: response?.data?.email,
          },
          requestedAt: response?.data?.requested_at,
          projectId: Number(projectId),
        });
      } else {
        throw new Error();
      }
    } catch (e) {
      notify({ text: e.message ? { body: e.message } : {} });
      console.error(e);
    }
  };

  const checkEditAccess = async () => {
    try {
      if (!projectId) return;

      const { statusCode, response } = await apiClient
        .get<{ data: UserThatCurrentlyEditingInfo }>(`projects/${projectId}/edit-mode/check`);

      if (statusCode === 200) {
        setStoredProjectEditAccess({
          isViewMode: false,
          isEditingAvailable: true,
          isEditingAvailableShowOnUI: false,
          isEditingUnavailableModalVisible: false,
          userThatCurrentlyEditing: {
            userId: null,
            name: null,
            email: null,
          },
          requestedAt: null,
          projectId: Number(projectId),
        });
      } else if (statusCode === 409) {
        setStoredProjectEditAccess({
          isViewMode: false,
          isEditingAvailable: false,
          isEditingAvailableShowOnUI: false,
          isEditingUnavailableModalVisible: false,
          userThatCurrentlyEditing: {
            userId: response?.data?.user_id,
            name: response?.data?.full_name,
            email: response?.data?.email,
          },
          requestedAt: response?.data?.requested_at,
          projectId: Number(projectId),
        });
      } else {
        throw new Error();
      }
    } catch (e) {
      notify({ text: e.message ? { body: e.message } : {} });
      console.error(e);
    }
  };

  const getOutHandler = async ({
    shouldNavigateToProjects = false,
    isAutomaticRedirect = false,
  }: {
    shouldNavigateToProjects?: boolean;
    isAutomaticRedirect?: boolean;
  }) => {
    try {
      if (!storedProjectEditAccess?.isEditingAvailableShowOnUI || !projectId) return;

      const { statusCode } = await apiClient
        .get<{ data: UserThatCurrentlyEditingInfo }>(`projects/${projectId}/edit-mode/clear`);

      if (statusCode === 200) {
        setStoredProjectEditAccess({
          isViewMode: false,
          isEditingAvailable: false,
          isEditingAvailableShowOnUI: false,
          isEditingUnavailableModalVisible: false,
          userThatCurrentlyEditing: {
            userId: null,
            name: null,
            email: null,
          },
          requestedAt: null,
          projectId: null,
        });
        setAreYouStillHereModal(ARE_YOU_STILL_HERE_MODAL_DEFAULT_VALUE);

        if (shouldNavigateToProjects && isAutomaticRedirect) {
          navigate(`${prefix}/client/${clientId}/projects`);
          notify({
            text: {
              title: undefined,
              body: t('You have been redirected to this page because your project editing session has expired'),
            },
            duration: undefined,
            placement: NotificationPlacement.BOTTOM,
            type: NotificationOptionView.INFO_POPUP,
          });
        } else if (shouldNavigateToProjects) {
          navigate(`${prefix}/client/${clientId}/projects`);
        }
      } else {
        throw new Error();
      }
    } catch (e) {
      notify({ text: e.message ? { body: e.message } : {} });
      console.error(e);
    }
  };

  const handleShortTimePassed = () => {
    getOutHandler({ shouldNavigateToProjects: true });
  };

  const handleLongTimePassed = () => {
    setAreYouStillHereModal({ isVisible: true });
  };

  const handleCheckEditAccess = () => {
    if (!storedProjectEditAccess.isEditingAvailableShowOnUI) {
      checkEditAccess();
    }
  };

  const {
    valueOfTimer,
    numberOfTimer,
    startLongTimer,
    startCheckAccessTimer,
    resetTimers,
  } = useTimer(
    handleShortTimePassed,
    handleLongTimePassed,
    handleCheckEditAccess,
    longDuration,
  );

  const confirmPresenceHandler = () => {
    setAreYouStillHereModal(ARE_YOU_STILL_HERE_MODAL_DEFAULT_VALUE);
    getEditAccess();
    resetTimers();
  };

  const handleBeforeUnload = (event: { returnValue: string }) => {
    if (!storedProjectEditAccess?.isEditingAvailableShowOnUI) return;

    eventEmitter.emit('tabClosed');
    const warningText = t('Are you sure you want to leave?');
    event.returnValue = warningText;
    return warningText;
  };

  useEffect(() => {
    if (projectId) {
      !storedProjectEditAccess?.isViewMode && userHasEditPermission && getEditAccess();
    } else {
      setStoredProjectEditAccess({
        isViewMode: false,
        isEditingAvailable: false,
        isEditingAvailableShowOnUI: false,
        isEditingUnavailableModalVisible: false,
        userThatCurrentlyEditing: {
          userId: null,
          name: null,
          email: null,
        },
        requestedAt: null,
        projectId: null,
      });
    }
  }, [pathname]);

  useEffect(() => {
    if (areYouStillHereModal?.isVisible && numberOfTimer === 0) {
      getOutHandler({ shouldNavigateToProjects: true, isAutomaticRedirect: true });
    }
  }, [numberOfTimer]);

  useEffect(() => {
    if (!storedProjectEditAccess.isEditingAvailableShowOnUI && projectId && !storedProjectEditAccess?.isViewMode) {
      startCheckAccessTimer(accessCheckInterval);
    } else {
      resetTimers();
    }
  }, [storedProjectEditAccess]);

  useEffect(() => {
    if (
      storedProjectEditAccess.isEditingAvailableShowOnUI
      && projectId
      && !storedProjectEditAccess?.isViewMode
      && storedProjectEditAccess.requestedAt
    ) {
      const requestedAtDate = new Date(storedProjectEditAccess.requestedAt);
      const now = new Date();
      const timeDifference = now.getTime() - requestedAtDate.getTime();
      const remainingTime = Math.max(longDuration - timeDifference, 0);

      startLongTimer(remainingTime);
    }
  }, [storedProjectEditAccess]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    eventEmitter.once('tabClosed', () => {
      getOutHandler({ shouldNavigateToProjects: false });
    });

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [storedProjectEditAccess?.isEditingAvailableShowOnUI]);

  useEffect(() => () => {
    getOutHandler({});
  }, []);

  return {
    areYouStillHereModal,
    confirmPresenceHandler,
    valueOfTimer,
    numberOfTimer,
    getOutHandler,
    getEditAccess,
    accessCheckInterval,
  };
};

export default useEditAccessHandler;
