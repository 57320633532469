import classNames from 'classnames';
import styles from './StatusLabel.module.scss';
import { Status, StatusState } from './types';

type StatusLabelProps = {
  status: Status;
  size?: 'large';
  className?: string;
};

const StatusLabel = ({ status, size, className }: StatusLabelProps) => (
  <p className={classNames(styles.statusLabel, className, {
    [styles.statusLabel_active]: status.state === StatusState.ACTIVE,
    [styles.statusLabel_inactive]: status.state === StatusState.INACTIVE,
    [styles.statusLabel_warning]: status.state === StatusState.WARNING,
    [styles.statusLabel_pending]: status.state === StatusState.PENDING,
    [styles.statusLabel_error]: status.state === StatusState.ERROR,
    [styles.statusLabel_large]: size === 'large',
  })}
  >
    {status.caption}
  </p>
);

export default StatusLabel;
