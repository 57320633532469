import { useEffect } from 'react';

export const useShortPolling = (
  asyncCallback: () => Promise<void>,
  {
    dependencies = [],
    interval = 1_000,
    onCleanUp = () => {},
    isEnabled = false,
  }: { dependencies?: any, interval?: number, onCleanUp?: () => void, isEnabled?: boolean },
) => {
  let timeout: NodeJS.Timeout | null = null;
  useEffect(() => {
    let stopped = false;

    if (!isEnabled) return;

    (async function pollingCallback() {
      try {
        await asyncCallback();
      } finally {
        timeout = !stopped && setTimeout(
          pollingCallback,
          interval,
        );
      }
    }());

    return () => {
      stopped = true;
      timeout && clearTimeout(timeout);
      onCleanUp();
    };
  }, [isEnabled, ...dependencies, interval]);
};
